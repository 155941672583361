import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SHIFT_STATUS_LIST,
  SHIFT_STATUS_LIST_IN_REPORTING,
  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_REPORTING_DETAILED_INVOICING_REPORTS,
  URL_REPORTING_GET_INVOICING_SUPPORT_ITEM,
  URL_REPORTING_GET_SP_USERS_LIST,
  URL_REPORTING_ROC_REPORTS,
} from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";

import { addMonths, exportTOExcel } from "Utils/utils";
import DetailedInvoicingSubTable from "./DetailedInvoicingSubTable";

const DetailedInvoiceReport = ({
  getTeamManagmentListCall,
  getParticipantList,
  teamManagementList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  const [workerList, setWorkerList] = useState([]);
  const [participantList, setParticipant] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [userNameList, setUserNameList] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [itemName, setItemNameList] = useState([]);
  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);
  const handleSearch = () => {
    setPage(1);

    const updatedPaginationObj = { ...paginationObj, page: 1 };
    setPaginationObj(updatedPaginationObj);

    fetchListReports(false, null, {
      page: 1,
      sort_field: sortBy,
      sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  useEffect(() => {
    apiGetRequest(`${URL_REPORTING_GET_SP_USERS_LIST}?for=only_manager`)
      .then((res) => {
        if (res.status === 200) {
          const userNames = res.data?.data || [];
          const users =
            userNames.length > 0
              ? userNames.map((user) => ({
                  label: user?.name,
                  value: user?.id,
                }))
              : [];
          const sortedData = users?.sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          );
          setUserNameList(sortedData);

          setUserNameList((prevParticipantList) => [
            { label: "All", value: "all" },
            ...prevParticipantList,
          ]);
          // setUserName(users);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  useEffect(() => {
    apiGetRequest(URL_REPORTING_GET_INVOICING_SUPPORT_ITEM)
      .then((res) => {
        if (res.status === 200) {
          const supportItemNames = res.data?.data || [];
          const Items =
            supportItemNames?.length > 0
              ? supportItemNames?.map((item) => ({
                  label: item?.support_name,
                  value: item?.item_number,
                }))
              : [];
          const sortedData = Items?.sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          );
          setItemNameList([{ label: "All", value: "all" }, ...sortedData]);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (!isInitialRender) {
      fetchListReports(false, null, {
        page,
        sort_field: sortBy,
        sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
    }
  }, [page]);
  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = {}
  ) => {
    if (!fromDate && !toDate) {
      toast("Select  date range to get data");
      return;
    }
    const currentPage = filters?.page || page;
    setLoader(true);
    let url = `${URL_REPORTING_DETAILED_INVOICING_REPORTS}?per_page=25&page=${currentPage}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_DETAILED_INVOICING_REPORTS}?per_page=25&page=${currentPage}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",

      service_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      created_by: isClearAll
        ? "all"
        : selectedUserName
        ? selectedUserName?.value
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;

              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.invoices?.total,
              totalPages: Math.ceil(
                res?.data?.data?.invoices?.total /
                  res?.data?.data?.invoices?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    // const transformedData =
    //   reportData &&
    //   reportData.invoices &&
    //   reportData.invoices?.flatMap((item) => {
    //     if (item.invoice_detail && item.invoice_detail?.length > 0) {
    //       return item.invoice_detail?.map((el) => {
    //         const participants = el?.participants
    //           ? JSON.parse(el.participants)
    //           : null;
    //         const workers = el?.workers ? JSON.parse(el.workers) : null;
    //         const participantName =
    //           participants && Object.keys(participants).length > 0
    //             ? participants[Object.keys(participants)[0]]
    //             : "N/A";
    //         const workerName =
    //           workers && Object.keys(workers).length > 0
    //             ? workers[Object.keys(workers)[0]]
    //             : "N/A";

    //         return {
    //           Invoice_Number: item?.invoice_number || "N/A",
    //           Created_By: item?.created_by || "N/A",
    //           Service_Date: item?.created_at
    //             ? moment(item?.created_at).format("DD/MM/YYYY")
    //             : "N/A",
    //           KMs: item?.total_km || "N/A",
    //           Total_Hours: item?.total_hours || "N/A",
    //           Total_Amount: `$${item?.total_amount}` || "N/A",
    //           Participant: participantName || "N/A",
    //           Worker: workerName || "N/A",
    //           Worker_Start_Data: el?.worker_start_time
    //             ? moment(el?.worker_start_time).format("DD/MM/YYYY")
    //             : "N/A",
    //           Worker_End_Date: el?.worker_end_time
    //             ? moment(el?.worker_end_time).format("DD/MM/YYYY")
    //             : "N/A",
    //           Support_Item_Name: el?.support_name || "N/A",
    //           Support_Item_Code: el?.item_number || "N/A",
    //         };
    //       });
    //     } else {
    //       return [];
    //     }
    //   });

    const transformedData =
      reportData &&
      reportData.invoices &&
      reportData.invoices?.flatMap((item) => {
        if (item.invoice_detail && item.invoice_detail.length > 0) {
          return item.invoice_detail?.map((el) => {
            const participants = el?.participants
              ? JSON.parse(el.participants)
              : null;
            const workers = el?.workers ? JSON.parse(el.workers) : null;
            const participantName =
              participants && Object.keys(participants).length > 0
                ? participants[Object.keys(participants)[0]]
                : "N/A";
            const workerName =
              workers && Object.keys(workers).length > 0
                ? workers[Object.keys(workers)[0]]
                : "N/A";

            return {
              Invoice_Number: item?.invoice_number || "N/A",
              Created_By: item?.created_by || "N/A",
              Created_Date: item?.created_at
                ? moment(item?.created_at, "DD-MM-YYYY").format("DD/MM/YYYY")
                : "N/A",

              Participant: participantName || "N/A",
              Worker: workerName || "N/A",

              Worker_Start_Date: el?.worker_start_time
                ? moment(el?.worker_start_time).format("DD/MM/YYYY")
                : "N/A",
              Worker_End_Date: el?.worker_end_time
                ? moment(el?.worker_end_time).format("DD/MM/YYYY")
                : "N/A",
              Support_Item_Name: el?.support_name || "N/A",
              Support_Item_Code: el?.item_number || "N/A",
              KM: el?.km || "N/A",
              Hours: el?.duration || "N/A",
              Amount: `$${el?.total_amount}` || "N/A",
            };
          });
        } else {
          return [
            {
              Invoice_Number: item?.invoice_number || "N/A",
              Created_By: item?.created_by || "N/A",
              Created_Date: item?.created_at
                ? moment(item?.created_at, "DD-MM-YYYY").format("DD/MM/YYYY")
                : "N/A",
              KM: item?.total_km || "N/A",
              Hours: item?.total_hours || "N/A",
              Amount: `$${item?.total_amount}` || "N/A",
            },
          ];
        }
      });

    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Detailed Invoicing Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.DETAILED_INVOICING_REPORTS
                )}
              />
            }
          />
          <div className='max-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Created By
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={userNameList || []}
                          handleChange={(e) => {
                            setSelectedUserName(e);
                          }}
                          selectedOption={selectedUserName}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Created Date Range(Max 3 Months)
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Support Item Name
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={itemName || []}
                          handleChange={(e) => {
                            setSelectedItemName(e);
                          }}
                          selectedOption={selectedItemName}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'></div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'></div>
                    </div>

                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        // style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={handleSearch}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSelectedItemName(null);
                            setSelectedWorker(null);
                            setSelectedUserName(null);
                            setFromDate(null);
                            setToDate(null);
                            setSearch(null);
                            setReports([]);
                            setSelectedFromDate(null);
                            setSelectedParticipant(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={""} />

                      <TableHeader isSort={false} label={"Invoice Number"} />

                      <TableHeader
                        isSort={false}
                        // sortName='shift_date'
                        // flipSort={flipSort}
                        // sortBy={sortBy}
                        // sortDescending={sortDescending}
                        label={"Created By"}
                      />
                      <TableHeader
                        sortName='created_at'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Created Date"}
                      />

                      <TableHeader isSort={false} label={"Total KMs"} />

                      <TableHeader isSort={false} label={"Total Hours"} />
                      <TableHeader
                        sortName='total_amount'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Amount"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={7} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.invoices?.data?.length > 0 ? (
                      reportList?.invoices?.data?.map((item, index) => (
                        <DetailedInvoicingSubTable item={item} key={index} />
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={13}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.invoices?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.invoices?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                      fetchListReports(false, null, {
                        page: e,
                        sort_field: sortBy,
                        sort_order: sortDescending
                          ? SORT_TYPE.DESC
                          : SORT_TYPE.ASC,
                      });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DetailedInvoiceReport;
