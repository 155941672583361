import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SHIFT_STATUS_LIST,
  SHIFT_STATUS_LIST_IN_REPORTING,
  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_ROC_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";

import { addMonths, exportTOExcel } from "Utils/utils";

const RocReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([]);
  const [participantList, setParticipant] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [isOverTime, setIsOverTime] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);

  const handleSearch = () => {
    setPage(1);

    const updatedPaginationObj = { ...paginationObj, page: 1 };
    setPaginationObj(updatedPaginationObj);

    fetchListReports(false, null, {
      page: 1,
      sort_field: sortBy,
      sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (!isInitialRender) {
      fetchListReports(false, null, {
        page,
        sort_field: sortBy,
        sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
    }
  }, [page]);
  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = {}
  ) => {
    if (!fromDate && !toDate) {
      toast("Select schedule shift date range to get data");
      return;
    }

    setLoader(true);

    const currentPage = filters.page || page;

    let url = `${URL_REPORTING_ROC_REPORTS}?per_page=25&page=${currentPage}`;
    if (filters.sort_field && filters.sort_order) {
      url = `${URL_REPORTING_ROC_REPORTS}?per_page=25&page=${currentPage}&sort_field=${filters.sort_field}&sort_order=${filters.sort_order}`;
    }

    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      schedule_shift_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      over_time: isClearAll ? "all" : isOverTime ? isOverTime.value : "all",
    };

    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }

    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.shifts?.total,
              totalPages: Math.ceil(
                res?.data?.data?.shifts?.total /
                  res?.data?.data?.shifts?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        setLoader(false);
      });
  };

  // const fetchListReports = async (
  //   isClearAll = false,
  //   report = null,
  //   filters = null
  // ) => {
  //   if (!fromDate && !toDate) {
  //     toast("Select schedule shift date range to get data");
  //     return;
  //   }

  //   setLoader(true);
  //   let url = `${URL_REPORTING_ROC_REPORTS}?per_page=25&page=${page}`;
  //   if (filters?.sort_field && filters?.sort_order) {
  //     url = `${URL_REPORTING_ROC_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
  //   }
  //   const payload = {
  //     search: search ? search : "",
  //     participant: isClearAll
  //       ? "all"
  //       : selectedParticipant
  //       ? selectedParticipant?.value
  //       : "all",
  //     worker: isClearAll
  //       ? "all"
  //       : selectedWorker
  //       ? selectedWorker?.value
  //       : "all",
  //     status: isClearAll ? "all" : status ? status.value : "all",

  //     schedule_shift_date: isClearAll
  //       ? "all"
  //       : toDate && fromDate
  //       ? [fromDate, toDate]
  //       : "all",
  //     over_time: isClearAll ? "all" : isOverTime ? isOverTime.value : "all",
  //   };
  //   if (report) {
  //     payload.report = report === "xlsx" ? "excel" : report;
  //   }
  //   await apiPostRequest(url, payload)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setLoader(false);
  //         if (payload.hasOwnProperty("report")) {
  //           if (report === "xlsx") {
  //             const data = res?.data?.data;
  //             console.log({ data });
  //             exportTOExcelFile(data);
  //           } else {
  //             window.open(`${res?.data?.data}`, "_blank");
  //           }
  //         } else {
  //           setReports(res?.data?.data);
  //           setPaginationObj((prev) => ({
  //             ...prev,
  //             total: res?.data?.data?.shifts?.total,
  //             totalPages: Math.ceil(
  //               res?.data?.data?.shifts?.total /
  //                 res?.data?.data?.shifts?.per_page
  //             ),
  //           }));
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(`${error}`);
  //       console.log({ error });
  //       setLoader(false);
  //     });
  // };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.shifts &&
      reportData?.shifts?.map((item) => {
        const participantsString =
          item.participants && item?.participants?.length > 0
            ? item?.participants
                .map(
                  (participant) =>
                    `${participant?.first_name || ""} ${
                      participant?.last_name || ""
                    }`
                )
                .join(", ")
            : "";
        const workers = `${item.worker?.first_name} ${item.worker?.last_name}`;
        return {
          Participants: participantsString,
          Workers: workers,
          Shift_Date: item?.shift_date
            ? moment(item.shift_date).format("DD/MM/YYYY")
            : "",
          Shift_Start_Time: item?.start_time
            ? moment(item?.start_time).format("DD/MM/YYYY HH:mm:ss")
            : "",
          Shift_End_Time: item?.end_time
            ? moment(item?.end_time).format("DD/MM/YYYY HH:mm:ss")
            : "",
          Status: item.status,
          Overtime: item.overtime,
          Shift_Length: item?.shift_length,
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const OVERTIME_10H = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='ROC Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.ROC_REPORTS
                )}
              />
            }
          />
          <div className='max-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={SHIFT_STATUS_LIST_IN_REPORTING || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Overtime
                        </label>
                        <SelectOption
                          name='overtime'
                          isSearch={true}
                          data={OVERTIME_10H || []}
                          selectedOption={isOverTime}
                          handleChange={(e) => {
                            setIsOverTime(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Scheduled Shift Date Range (Max 3 Months)
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'></div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'></div>
                    </div>

                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={handleSearch}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setIsOverTime(null);
                            setStatus(null);
                            setSelectedWorker(null);
                            setFromDate(null);
                            setToDate(null);
                            setSearch(null);
                            setReports([]);
                            setSelectedFromDate(null);
                            setSelectedParticipant(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={"Participant"} />

                      <TableHeader isSort={false} label={"Worker"} />

                      <TableHeader
                        sortName='shift_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Scheduled Shift Date"}
                      />
                      <TableHeader
                        sortName='start_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift Start Time"}
                      />

                      <TableHeader
                        sortName='end_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift End Time"}
                      />
                      <TableHeader
                        sortName='shift_length'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift length"}
                      />
                      <TableHeader
                        // sortName='overtime'
                        isSort={false}
                        label={"Overtime"}
                      />
                      <TableHeader isSort={false} label={"Status"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={8} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.shifts?.data?.length > 0 ? (
                      reportList?.shifts?.data?.map((shift, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            <div>
                              {(shift?.participants &&
                                _.isArray(shift?.participants) && (
                                  <AvatarGroup
                                    type='participant'
                                    arrayData={shift?.participants || []}
                                    cssClass='mt-0'
                                    maxLength={5}
                                  />
                                )) ||
                                "-"}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            <div className='flex items-center'>
                              <div className='capitalize pl-1'>
                                <span
                                  title={`${shift?.worker?.first_name} ${shift?.worker?.last_name}`}
                                >
                                  <p
                                    className={`w-40 truncate`}
                                  >{`${shift?.worker?.first_name} ${shift?.worker?.last_name}`}</p>
                                </span>
                              </div>
                            </div>
                            {/* {`${shift?.worker?.first_name} ${shift?.worker?.last_name}`} */}
                          </td>
                          <td className='py-[11px] px-3 text-left  text-sm text-gray-500'>
                            {shift?.shift_date
                              ? moment(shift.shift_date).format("DD/MM/YYYY")
                              : "-"}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {moment(shift?.start_time).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                            {/* {shift?.start_time || "-"} */}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {moment(shift?.end_time).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                            {/* {shift?.end_time || "-"} */}
                          </td>

                          <td className='px-3 text-left py-[11px] text-sm text-gray-500'>
                            {shift.shift_length || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm capitalize'>
                            {shift?.overtime || "-"}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500 capitalize'>
                            {shift.status === "accepted" &&
                            shift?.progress === "submitted"
                              ? "Completed"
                              : shift.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={8}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.shifts?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.shifts?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });

                      fetchListReports(false, null, {
                        page: e,
                        sort_field: sortBy,
                        sort_order: sortDescending
                          ? SORT_TYPE.DESC
                          : SORT_TYPE.ASC,
                      });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RocReports;
