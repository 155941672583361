import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Search from "components/common/Search/Search";
import SelectOption from "components/common/Select/Select";
import TableHeader from "components/common/Table/TableHeader";
import { StatusOptions } from "Constants/constant";
import {
  formatDateIntoDMYToDisplay,
  getPlanManagedBy,
  textTruncate,
} from "Utils/utils";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { HiOutlineEye } from "react-icons/hi";
import ParticipantImageToolTip from "components/common/Tooltip/ParticipantImageToolTip";
import _ from "lodash";
import QuickView from "pages/ServiceProvider/ParticipantsList/ParticipantQuickView";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const ParticipantTable = (props) => {
  const {
    sortBy,
    supportCoordinator,
    handleFetch,
    cosParticipant,
    cosParticipantLoader,
    search,
    setSearch,
    supportCoordinatorLoader,
    sortDescending,
    flipSort,
  } = props;

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});

  const [statusFilter, setStatusFilter] = useState(StatusOptions[0]);

  const [selectedSupportCoorinator, setSelectedSupportCoordinator] = useState({
    value: "all",
    label: "All",
  });
  const [supportCoordinatorsDropdown, setSupportCoordinatorsDropDown] =
    useState([]);

  useEffect(() => {
    if (supportCoordinator && supportCoordinator.length > 0) {
      const data = _.cloneDeep(supportCoordinator);
      data.unshift({
        value: "all",
        label: "All",
      });
      setSupportCoordinatorsDropDown([...data]);
    }
  }, [supportCoordinator]);

  return (
    <main>
      <Header
        title='Participants List'
        breadcrumb={
          <Breadcrumb
            pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.PARTICIPANT_LIST)}
          />
        }
      />

      <div className='px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto pb-10'>
        <div
          style={{ marginTop: "0px", paddingTop: "15px", top: "70px" }}
          className='flex justify-between items-center sticky py-2 bg-white z-[6]'
        >
          <div className='w-1/3 md:w-2/12 lg:w-2/12'>
            <SelectOption
              isDisabled={cosParticipantLoader}
              isSearch={false}
              selectedOption={statusFilter}
              handleChange={(e) => {
                setStatusFilter(e);
                const obj = { page: 1 };
                if (
                  selectedSupportCoorinator &&
                  selectedSupportCoorinator.value !== "all"
                ) {
                  Object.assign(obj, {
                    sc_id: selectedSupportCoorinator.value,
                  });
                }
                if (e.value === "both") {
                  handleFetch({ ...obj }, sortBy);
                } else {
                  Object.assign(obj, {
                    status: e.value,
                  });
                  handleFetch({ ...obj }, sortBy);
                }
              }}
              data={StatusOptions}
              placeHolder='Filter Status'
              cssClass='z-20'
            />
          </div>
          <div className='w-1/3 md:w-3/12 lg:w-4/12/12'>
            <SelectOption
              isDisabled={supportCoordinatorLoader}
              showIcon={true}
              isSearch={false}
              selectedOption={selectedSupportCoorinator}
              handleChange={(e) => {
                const obj = { page: 1 };
                if (statusFilter && statusFilter.value !== "both") {
                  Object.assign(obj, {
                    status: statusFilter.value,
                  });
                }
                setSelectedSupportCoordinator(e);
                if (e.value === "all") {
                  handleFetch({ ...obj }, sortBy);
                } else {
                  Object.assign(obj, {
                    sc_id: e.value,
                  });
                  handleFetch({ ...obj }, sortBy);
                }
              }}
              data={supportCoordinatorsDropdown}
              placeHolder='Support Coordinators'
              cssClass='z-20'
            />
          </div>
          <Search setSearch={setSearch} search={search} />
        </div>

        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='min-w-full py-2 align-middle'>
                <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border'>
                  {cosParticipantLoader ? (
                    <div
                      className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                    >
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </div>
                  ) : (
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-blue-50 capitalize'>
                        <tr>
                          <TableHeader
                            sortName='first_name'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Participant Name"}
                          />
                          <TableHeader
                            isSort={false}
                            sortName='status'
                            label={"Management Style"}
                          />
                          <TableHeader
                            isSort={false}
                            sortName='workers'
                            label={"Service Region"}
                          />
                          {roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
                          ) && (
                            <TableHeader
                              isSort={false}
                              sortName='workers'
                              label={"NDIS No."}
                            />
                          )}
                          <TableHeader
                            isSort={false}
                            sortName='workers'
                            label={"Plan Start Date"}
                          />
                          <TableHeader
                            isSort={false}
                            sortName='workers'
                            label={"Plan End Date"}
                          />
                          {/* <TableHeader
                            isSort={false}
                            label={"Quick Snapshot"}
                            cssClass='w-[10%]'
                          /> */}
                          <th
                            scope='col'
                            className='text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold'
                          >
                            {" "}
                            Quick Snapshot <span className='sr-only'></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                        {cosParticipant !== null
                          ? cosParticipant?.map((participant, index) => (
                              <tr
                                key={index}
                                className='even:bg-white odd:bg-gray-50'
                              >
                                <td className='whitespace-nowrap py-4 px-3 text-sm'>
                                  <div className='flex items-center'>
                                    <div className='h-10 w-10 flex-shrink-0 group relative'>
                                      <ParticipantImageToolTip
                                        cssClass={
                                          "bg-black py-1 px-1 rounded text-white z-[9]"
                                        }
                                        participant={participant}
                                        imgCssClass={
                                          "capitalize object-cover h-10 w-10 rounded-full"
                                        }
                                      />
                                    </div>
                                    <div className='ml-4 capitalize'>
                                      <span
                                        onClick={() => {
                                          navigate(
                                            `/cos/participant-profile/${participant.uuid}`
                                          );
                                        }}
                                      >
                                        <p className='w-50 truncate cursor-pointer'>{`${participant.first_name} ${participant.last_name}`}</p>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className=' whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {getPlanManagedBy(
                                    participant?.plan_managed_by
                                  )}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {participant?.state_object?.name.toUpperCase()}
                                </td>
                                {roleAccess(
                                  [],
                                  PERMISSION_KEYS.PARTICIPANT,
                                  PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                    .NDIS_NUMBER
                                ) && (
                                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                    {participant.ndis_no}
                                  </td>
                                )}
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                  {formatDateIntoDMYToDisplay(
                                    participant?.plan_start_date
                                  )}
                                </td>
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                  {formatDateIntoDMYToDisplay(
                                    participant?.plan_end_date
                                  )}
                                </td>
                                {/* <td
                                  title={participant.quick_support}
                                  className='truncate px-3 py-4 text-sm text-gray-500'
                                >
                                  {textTruncate(
                                    participant.quick_support,
                                    30,
                                    29
                                  )}
                                </td> */}
                                <td className='relative-nowrap py-4 px-3 text-sm'>
                                  <div className='flex items-center justify-start gap-x-3'>
                                    <div
                                      onClick={() => {
                                        setIsOpen(true);
                                        setSelectedParticipant(participant);
                                      }}
                                      tabIndex={0}
                                      role='button'
                                      className='icon-eye-wrapper'
                                      title='View'
                                    >
                                      {" "}
                                      <HiOutlineEye className='icon-size' />
                                      <span className='sr-only'>
                                        {participant.uuid}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                        {cosParticipant?.length === 0 && (
                          <tr>
                            <td
                              colSpan={14}
                              className='py-6 text-center text-red-600'
                            >
                              No data found !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <QuickView
            paddingLeft='px-0'
            selectedParticipant={selectedParticipant}
            isOpen={isOpen}
            setIsOpen={() => {
              setIsOpen(false);
              setSelectedParticipant({});
            }}
          />
        )}
      </div>
    </main>
  );
};

export default ParticipantTable;
