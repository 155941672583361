import React, { useState, useContext } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import { LoginContext } from "../../../../helpers/LoginContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiPostRequest } from "../../../../helpers/Requests";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import moment from "moment";
import CustomButton from "../../../../components/common/Button/CustomButton";
import Header from "../../../../components/common/Header/Header";

const Complaints = () => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const onSubmit = async (data) => {
    if (isUserGracePeriodOver) return;
    setRequest({ ...request, isRequested: true });
    Object.assign(data, { type: "complaint" });
    try {
      let res = await apiPostRequest("/hrm/complaint/store", data);
      if (res.status === 200) {
        reset();
        toast.success("Complaint added successfuly");
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
      setRequest({ ...request, isRequested: false });
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.success("Something went wrong");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <>
      <div className='min-h-full bg-white'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          <main className='flex-1'>
            <Header
              title='Complaint'
              breadcrumb={
                <Breadcrumb
                  pages={EMPLOYEE_BREADCRUMB(
                    EMPLOYEE_BREADCRUMB_PAGES.COMPLAINT
                  )}
                />
              }
            />

            <main className='max-w-7xl mx-auto'>
              <div className='px-6 bg-white flex flex-col'>
                <form onSubmit={handleSubmit(onSubmit)} className=''>
                  <p className='text-3xl font-medium text-gray-700 py-4'>
                    Please add your Complaint here
                  </p>
                  <div className='grid grid-cols-2 gap-x-4'>
                    <div className='mt-1 col-span-2 sm:col-span-1'>
                      <label className='did-floating-label'>Name</label>
                      <input
                        {...register("name", {
                          required: true,
                          maxLength: 254,
                        })}
                        className='did-floating-input'
                        type='text'
                      />

                      {errors?.name?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                    <div className='mt-1 col-span-2 sm:col-span-1'>
                      <label className='did-floating-label'>Date</label>
                      <input
                        {...register("date", {
                          required: true,
                          pattern:
                            /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
                        })}
                        className='did-floating-input'
                        type='date'
                        min={moment().format("DD-MM-YYYY")}
                        name='date'
                      />

                      {errors?.date?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {errors?.date?.type === "pattern" && (
                        <span className='text-sm text-red-500'>
                          date format should be DD/MM/YYYY
                        </span>
                      )}
                    </div>

                    <div className='mt-1 col-span-2 sm:col-span-1'>
                      <label className='did-floating-label'>Subject</label>
                      <input
                        {...register("subject", {
                          required: true,
                          maxLength: 254,
                        })}
                        className='did-floating-input'
                        type='text'
                      />

                      {errors?.subject?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                    <div className='mt-1 col-span-2'>
                      <label className='did-floating-label'>Details</label>
                      <textarea
                        {...register("details", {
                          required: true,
                        })}
                        name='details'
                        id=''
                        rows={3}
                        className='focus:bg-[#4b6bb710] w-full rounded-md shadow border overflow-y-auto border-gray-300'
                        style={{ paddingTop: ".5rem" }}
                      ></textarea>

                      {errors?.details?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='mt-8 flex justify-end'>
                    <div className='w-full text-right'>
                      <CustomButton
                        showLoading={request.isRequested}
                        isDisabled={
                          request.isRequested || isUserGracePeriodOver
                        }
                        type='submit'
                        label='Submit'
                        variant='btn-purple'
                      />
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </main>
        </div>
      </div>
    </>
  );
};

export default Complaints;
