import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { apiGetRequest, apiPostRequest } from "../../../helpers/Requests";
import moment from "moment";
import { toast } from "react-toastify";
import { AiOutlinePlus } from "react-icons/ai";
import { LoginContext } from "../../../helpers/LoginContext";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import PaginationComponent from "../../../components/common/Pagination/index";
import Search from "../../../components/common/Search/Search";
import {
  emptyPaginationObj,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant";
import { getPaginationObj, getUserRoleTitle, textTruncate } from "Utils/utils";
import TableHeader from "../../../components/common/Table/TableHeader";
import { HiOutlineEye } from "react-icons/hi";
import CustomButton from "../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import Header from "../../../components/common/Header/Header";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { CategoryOptions } from "../../../Constants/constant";
import SelectOption from "components/common/Select/Select";

const AskQuestion = (props) => {
  //const { getFeedbackList,feedbackObj, loading } = props;
  const { getQuestionList, questionObj, loading } = props;
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [name, setname] = useState(null);
  const [date, setdate] = useState(null);
  const [category, setcategory] = useState(null);
  const [subject, setsubject] = useState(null);
  const [tableData, settableData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  //const [questions, setComplaints] = useState([]);
  const [questions, setquestions] = useState([]);
  const [isopens, setIsopens] = useState(false);
  const [shouldTruncate, setShouldTruncate] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [error, setErrors] = useState(false);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (selectedCategory) {
      setErrors(false);
    }
  }, [selectedCategory]);

  const getQuestions = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }

    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });
    Object.assign(filters, { type: "complaint" });
    getQuestionList(filters);
  };
  const dataSubmitHandler = async (data) => {
    if (!error && selectedCategory) {
      Object.assign(data, { type: "complaint" });
      Object.assign(data, { category: selectedCategory?.value });
      Object.assign(data, { date: null });
      setRequest({ ...request, isRequested: true });
      try {
        const res = await apiPostRequest("/ask-question", data);
        if (res.status === 200) {
          const payload = {
            sort_field: sortBy,
            sort_order: SORT_TYPE.DESC,
          };
          if (search !== null) {
            Object.assign(payload, {
              search,
              page: 1,
            });
          }
          await getQuestions(payload);
          reset();
          setRequest({ ...request, isRequested: false, isSuccess: true });
          toast.success("Query submitted successfully!");
          closeModal();
        }
        // if (res.status === 500) {
        //   throw new Error();
        // }
        setRequest({ ...request, isRequested: false });
      } catch (err) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        toast.error("Something went wrong!");
      }
    } else if (!selectedCategory) {
      setErrors(true);
    }
  };
  useEffect(() => {
    if (questionObj && "queries" in questionObj) {
      setquestions(questionObj?.queries);
      setPaginationObj(
        getPaginationObj(questionObj?.queries, questionObj.query_params)
      );
    } else if (questionObj && "submitted_forms" in questionObj) {
      setquestions(questionObj.submitted_forms);
      setPaginationObj(
        getPaginationObj(questionObj.submitted_forms, questionObj.query_params)
      );
    }
  }, [questionObj]);
  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);
  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (
      !search &&
      parseInt(questionObj?.query_params?.page) !== paginationObj.page
    )
      handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    getQuestions({ ...obj });
  };

  useEffect(() => {
    handleFetch({
      search: search,
    });
  }, []);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  useEffect(() => {
    const fetchCategoryOptions = async () => {
      try {
        const response = await apiGetRequest("/ask-question/categories");
        if (response.status === 200) {
          const modifiedResponse = response?.data?.data;
          const values =
            modifiedResponse &&
            modifiedResponse.length > 0 &&
            modifiedResponse?.map((item) => ({
              email: item?.email,
              label: item?.label,
              value: item?.key,
            }));
          setCategoryOptions(values);
        }
      } catch (error) {
        console.error("Error fetching category options:", error);
      }
    };

    fetchCategoryOptions();
  }, []);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    dataSubmitHandler(data);
  };

  const closeModal = () => {
    setisOpen(false);
    reset();
  };
  const openModal = () => {
    setisOpen(true);
    setSelectedCategory(null);
  };

  const closeModalTable = () => {
    setShowButton(true);
    setShouldTruncate(true);
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  const modalFooterButton = () => (
    <CustomButton
      clickHandler={closeModalTable}
      type='button'
      label='Close'
      variant='danger'
    />
  );

  const modalFooterQuestions = () => (
    <CustomButton
      type='submit'
      label='Submit'
      showLoading={request.isRequested}
      isDisabled={request.isRequested || isUserGracePeriodOver}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("allQuestions");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );
  const handleClicks = () => {
    setIsopens(!isopens);
    setShouldTruncate(!shouldTruncate);
    setShowButton(false);
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <main>
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300 flex flex-col"
              : "lg:pl-64 duration-300 flex flex-col"
          }`}
        >
          <Header
            title='Asked Questions'
            desc='Directory of all questions'
            {...(roleAccess(
              [],
              PERMISSION_KEYS.ASK_QUESTION,
              PERMISSION_KEYS_CHILDREN.ASK_QUESTION.CREATE
            ) && {
              handleClick: openModal,
              btnLabel: "Ask a Question",
              icon: <AiOutlinePlus />,
              isButton: true,
            })}
          />
          <div>
            <div className='w-full absolute md:static'>
              <div className='flex sm:px-6 lg:px-8 justify-between items-center py-2 bg-white sticky top-20 '>
                <div className='w-1/3 md:w-2/12 lg:w-2/12' />
                <Search setSearch={setSearch} search={search} />
              </div>
              <div className='bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-10'>
                <div className=' flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto1 scrollbar-hide1 sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      <div className='table-wrp shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-gray-50 capitalize'>
                            <tr className='pt-2 sticky top-[133px]'>
                              <TableHeader
                                sortName='created_at'
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                label={"Category"}
                              />
                              {/* <th
                                scope="col"
                                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date
                              </th> */}
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Subject
                              </th>
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Submitted By
                              </th>
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                User Role
                              </th>
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Details
                              </th>
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-center text-sm font-semibold text-gray-900'
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                            {loading ? (
                              <tr>
                                <td colSpan={7} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : questionObj &&
                              questionObj?.queries?.data &&
                              questionObj?.queries?.data.length > 0 ? (
                              questionObj.queries.data.map((item, index) => (
                                <tr
                                  key={index}
                                  className='even:bg-white odd:bg-gray-50'
                                >
                                  <td
                                    title={item.category}
                                    className='capitalize whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'
                                  >
                                    {textTruncate(
                                      categoryOptions.find(
                                        (option) =>
                                          option.value === item.category
                                      )?.label,
                                      25,
                                      24
                                    )}
                                  </td>

                                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {textTruncate(
                                      moment(item.date).format("DD-MM-YYYY"),
                                      25,
                                      24
                                    )}
                                  </td> */}
                                  <td
                                    title={item.subject}
                                    className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                  >
                                    {textTruncate(item.subject, 25, 24)}
                                  </td>
                                  <td className='capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {textTruncate(
                                      item?.created_by?.name,
                                      25,
                                      24
                                    )}
                                  </td>
                                  <td className='capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {getUserRoleTitle(
                                      item?.created_by?.user_type
                                    )}
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    <div
                                      title={item.details}
                                      className='truncate flex space-x-4 items-center'
                                    >
                                      {textTruncate(item.details, 25, 24)}
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                    <button
                                      className=''
                                      onClick={() => {
                                        setOpen(true);
                                        settableData(item.details);
                                        setname(item.name);
                                        setdate(item.date);
                                        setcategory(item.category);
                                        setsubject(item.subject);
                                      }}
                                    >
                                      <div
                                        className='icon-eye-wrapper'
                                        title='View'
                                      >
                                        <HiOutlineEye className='icon-size' />
                                      </div>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              (questionObj?.queries?.data?.length === 0 ||
                                questionObj?.queries?.length === 0) && (
                                <tr>
                                  <td
                                    colSpan={7}
                                    className='py-6 text-center text-red-600'
                                  >
                                    Ask Vertex360 SME your first question!
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!(
              questionObj?.queries?.data?.length === 0 ||
              questionObj?.queries?.length === 0
            ) && (
              <div className='bg-white sm:px-6 lg:px-8 max-w-7xl mx-auto py-4'>
                <PaginationComponent
                  page={page}
                  activeListLength={questionObj?.queries?.data || []}
                  limit={POLICIES_LIST_LIMIT}
                  totalList={questionObj?.queries?.total || 0}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={questionObj?.queries?.total_pages}
                />
              </div>
            )}

            {/* Detail Modal */}
            {open && (
              <ModalSkeleton
                isOpen={open}
                closeModal={closeModalTable}
                modalFooter={modalFooterButton()}
                cssClass='w-[50%]'
                title='Question Information'
              >
                <>
                  <p className='mb-3 text-sm text-gray-500'>
                    {`Detailed question from ${name}`}
                  </p>
                  <div className='md:grid '>
                    <div>
                      <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Name
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                          {name}
                        </dd>
                      </div>
                      {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {moment(date).format("DD-MM-YYYY")}
                        </dd>
                      </div> */}
                      <div className='bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Subject of Inquiry
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                          {subject}
                        </dd>
                      </div>
                      <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Category
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                          {
                            categoryOptions.find(
                              (option) => option.value === category
                            )?.label
                          }
                        </dd>
                      </div>
                      <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Detail of Inquiry
                        </dt>
                        <div className='flex sm:col-span-2'>
                          <dd
                            className={`mt-1 text-sm text-gray-900 sm:mt-0 ${
                              shouldTruncate ? "truncate" : ""
                            }`}
                          >
                            {shouldTruncate
                              ? tableData.slice(0, 40) +
                                (tableData.length > 40 ? "..." : "")
                              : tableData}
                          </dd>
                          {tableData.length > 40 && showButton && (
                            <p
                              className='text-blue-900 cursor-pointer whitespace-nowrap text-sm'
                              onClick={handleClicks}
                            >
                              more
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </ModalSkeleton>
            )}
            {/* Create Modal */}
            {isOpen && (
              <ModalSkeleton
                isOpen={isOpen}
                cssClass='w-[50%]'
                title='Ask a Question'
                closeModal={closeModal}
                modalFooter={modalFooterQuestions()}
              >
                <div>
                  <div className='flex flex-col'>
                    <p className='text-sm text-gray-500 mb-3'>
                      Please ask your question here
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-x-4'>
                      <div className='mt-1 col-span-2 sm:col-span-1'>
                        <label className='did-floating-label'>
                          Enter your Name{" "}
                          <span className='text-red-500'>*</span>
                        </label>
                        <input
                          {...register("name", {
                            required: true,
                          })}
                          className='did-floating-input'
                          type='text'
                        />
                        {errors?.name?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      {/* <div className="mt-1 col-span-2 sm:col-span-1">
                        <label className="did-floating-label">Date</label>
                        <input
                          {...register("date", {
                            required: true,
                            // max: moment().format("DD-MM-YYYY"),
                            // value: moment().format("DD-MM-YYYY"),
                            pattern: "d{4}-d{2}-d{2}",
                          })}
                          className="did-floating-input"
                          type="date"
                          name="date"
                        />

                        {errors?.date?.type === "required" && (
                          <span className="text-sm text-red-500">
                            This field is required!
                          </span>
                        )}
                      </div> */}
                      <div className='mt-1 col-span-2 sm:col-span-1'>
                        <label className='did-floating-label'>
                          Subject of Inquiry{" "}
                          <span className='text-red-500'>*</span>
                        </label>
                        <input
                          {...register("subject", {
                            required: true,
                          })}
                          className='did-floating-input'
                          type='text'
                        />

                        {errors?.subject?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div className='mt-4 col-span-2 sm:col-span-1'>
                        <label htmlFor={"Role"} className='did-floating-label'>
                          Relevant Category{" "}
                          <span className='text-red-500'>*</span>
                        </label>
                        <SelectOption
                          // isMulti={true}
                          placeHolder='Please select the category'
                          data={categoryOptions}
                          selectedOption={selectedCategory}
                          handleChange={setSelectedCategory}
                        />
                        {error && (
                          <span className='text-red-500 text-sm'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      {/* <div className="mt-1 col-span-2 sm:col-span-1">
                        <label className="did-floating-label">
                          Relevant Category
                        </label>
                        <select
                          className="did-floating-select"
                          {...register("category", {
                            // required: true,
                            //label: "Legal Query",
                          })}
                        >
                          {categoryOptions &&
                            categoryOptions.length > 0 &&
                            categoryOptions
                              .sort((a, b) => a.label.localeCompare(b.label))
                              .map((x, index) => (
                                <option key={index} value={x.key}>
                                  {x.label}
                                </option>
                              ))}
                        </select>
                      </div> */}

                      <div className='mt-4 col-span-2'>
                        <label className='did-floating-label'>
                          Details of Inquiry
                        </label>
                        <textarea
                          {...register("details", {
                            required: true,
                            // pattern: /^[^\s]+(?:$|.*[^\s]+$)/,
                          })}
                          name='details'
                          id=''
                          rows={3}
                          className='overflow-y-auto resize-none focus:bg-[#4b6bb710] w-full rounded-md shadow border border-gray-300'
                          style={{ paddingTop: ".5rem" }}
                        ></textarea>

                        {errors?.details?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                        {/* {errors?.details?.type === "pattern" && (
                          <span className="text-sm text-red-500">
                            Please enter valid details
                          </span>
                        )} */}
                      </div>
                    </div>
                    <button
                      type='submit'
                      className='hidden sr-only'
                      id='allQuestions'
                    />
                  </form>
                </div>
              </ModalSkeleton>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AskQuestion;
