import React from "react";
import moment from "moment";
import { Controller } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import {
  convertLabelIntoName,
  dynamicFormDateValidation,
  getError,
  getExtensionName,
  parseBooleanVal,
} from "Utils/utils";
import { FiPlus } from "react-icons/fi";
import CustomButton from "components/common/Button/CustomButton";
import {
  REGEX_PHONE_NUMBER,
  STATE_FULL_LABEL_OPTIONS,
} from "Constants/constant";

const SubSectionInputFields = (props) => {
  const {
    isViewable,
    field,
    i,
    register,
    index,
    errors,
    fileUploadHandler,
    fileUploadChecker,
    fileLoader,
    signatures,
    control,
    clearSignatureManager,
    sigCanvasManager,
    formatIntoPngManager,
    watchFormData,
    setSelectedLegal,
    addRowHandler,
    delteRowHandler,
    handleAddOther,
    handleRemoveOther,
    checkIsDependent,
  } = props;

  return (
    <>
      <div className='col-span-12 mt-3'>
        <div
          className='flex'
          style={{
            justifyContent: "space-between",
          }}
        >
          <p className='mt-0'>{field.section_title}</p>
          {handleAddOther && parseBooleanVal(field.isAddOther) && (
            <div>
              <CustomButton
                label='Add Other'
                type='button'
                // cssClass='mr-3'
                clickHandler={() => handleAddOther(index, i)}
              />
            </div>
          )}
          {handleRemoveOther &&
            field.hasOwnProperty("isAddOtherDelete") &&
            parseBooleanVal(field.isAddOtherDelete) && (
              <div>
                <CustomButton
                  variant='danger'
                  label='Delete Other'
                  type='button'
                  cssClass='mr-3'
                  clickHandler={() => handleRemoveOther(index, i)}
                />
              </div>
            )}
        </div>
      </div>
      <div
        key={i}
        className='col-span-12 grid grid-cols-12 gap-x-4 p-4 bg-gray-50 rounded-lg border my-4'
      >
        {field?.sub_fields.length > 0 &&
          field?.sub_fields.map((item, sf) => {
            // if (item.type === "content" && checkIsDependent(item)) {
            //   return (
            //     <div
            //       key={`${convertLabelIntoName(item.type)}-${sf}`}
            //       className={`col-span-12 flex flex-col`}
            //     >
            //       {item.hasOwnProperty("content") && item.content && (
            //         <div
            //           // className="text-xs text-gray-600 rounded-lg px-2 py-2"
            //           className='py-2 border p-4 rounded-lg'
            //           dangerouslySetInnerHTML={{
            //             __html: item.content,
            //           }}
            //         />
            //       )}
            //     </div>
            //   );
            // }
            if (item.type === "content" && checkIsDependent(item)) {
              return (
                <React.Fragment
                  key={`${convertLabelIntoName(item.type)}-${sf}`}
                >
                  {item.hasOwnProperty("content") && item.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    />
                  )}
                </React.Fragment>
              );
            } else if (item.type === "checkbox" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth)
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } mb-4`}
                >
                  <div className=''>
                    <input
                      {...(register
                        ? register(
                            `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                              item.lable
                            )}`,
                            {
                              required: parseBooleanVal(item.required),
                              value: item.value,
                            }
                          )
                        : {
                            checked: item.value,
                            readOnly: true,
                            disabled: true,
                          })}
                      type='checkbox'
                      id={`template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                        item.lable
                      )}`}
                      className='h-4 w-4 mr-3 ml-2'
                    />
                    <label
                      htmlFor={`template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                        item.lable
                      )}`}
                      className='did-floating-label text-sm'
                    >
                      {item.lable}
                    </label>
                    {item.hasOwnProperty("hint") &&
                      item.hint !== "" &&
                      item.hint && (
                        <div
                          className=' text-sm text-gray-600 rounded-lg px-2 py-2'
                          dangerouslySetInnerHTML={{
                            __html: item.hint,
                          }}
                        />
                      )}
                  </div>
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                </div>
              );
            } else if (item.type === "textarea" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth)
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } mb-4`}
                >
                  <label className='did-floating-label text-sm'>
                    {item.lable}
                  </label>
                  <textarea
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                            item.lable
                          )}`,
                          {
                            required: parseBooleanVal(item.required),
                            value: item.value,
                          }
                        )
                      : {
                          value: item.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    className='resize-none overflow-y-auto focus:bg-[#4b6bb710] border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                    rows={3}
                    style={{
                      paddingTop: ".5rem",
                    }}
                  />
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                </div>
              );
            } else if (item.type === "file" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className='col-span-12 md:col-span-6 did-floating-label-content'
                >
                  <label className='did-floating-label text-sm'>
                    {item.lable}
                  </label>
                  {register === null && isViewable && (
                    <div className='mt-1'>
                      <p className='text-sm text-gray-500 truncate'>
                        {item.value}
                      </p>
                      <a
                        href={item.path}
                        download
                        target='_blank'
                        className='inline-block mt-1 cursor-pointer bg-gradient text-white font-bold py-1 px-4 rounded print:hidden'
                        rel='noreferrer'
                      >
                        {`${getExtensionName(item.path)}`}
                      </a>
                    </div>
                  )}
                  {register !== null && (
                    <>
                      <input
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                                item.lable
                              )}`,
                              {
                                required:
                                  parseBooleanVal(item.required) && !item.value
                                    ? true
                                    : false,
                                onChange: (e) => {
                                  fileUploadHandler(e, index, i, sf);
                                },
                                value: item.value
                                  ? [new File([Blob], "file")]
                                  : null,
                              }
                            )
                          : {
                              readOnly: true,
                              disabled: true,
                            })}
                        type='file'
                        id={`template[${index}].input_fields[${i}].sub_section[${sf}].${item.name}`}
                        style={{
                          display: "none",
                        }}
                      />
                      <div className='did-floating-input'>
                        <div className='flex justify-between items-center h-full'>
                          <label
                            htmlFor={`template[${index}].input_fields[${i}].sub_section[${sf}].${item.name}`}
                            className='cursor-pointer bg-gradient p-1 rounded text-white p-0.5 whitespace-nowrap'
                          >
                            {item.value
                              ? "Change file"
                              : "Click me to upload file"}
                          </label>
                          {item.value && (
                            <p
                              style={{ whiteSpace: "pre" }}
                              className='pl-2 truncate'
                              title={item.value}
                            >
                              {item.value}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                  {!fileUploadChecker &&
                    fileLoader === `${index}-${i}-${sf}` && <h1>Loading</h1>}
                </div>
              );
            } else if (item.type === "signature" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className='bg-white border rounded-lg shadow px-8 col-span-12 md:col-span-6 flex flex-col mb-6'
                >
                  <div className='flex flex-col py-2'>
                    <p className='font-bold text-green-700'>Signatures</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between items-center'>
                    <div className=' flex flex-col text-gray-500 py-2 '>
                      <p className='did-floating-label text-sm pb-2'>
                        {item.lable}
                      </p>
                      {signatures.find(
                        (x) => x.name === convertLabelIntoName(item.lable)
                      ) ? (
                        <img
                          src={`${
                            signatures.find(
                              (signature) =>
                                signature.name ===
                                convertLabelIntoName(item.lable)
                            ).image
                          }`}
                          alt='signature'
                          // height={200}
                          // width={350}
                          className='h-auto w-auto sigCanvas border rounded-lg bg-gray-100'
                        />
                      ) : (
                        register && (
                          <>
                            <Controller
                              name={item.name}
                              control={control}
                              render={({ field }) => (
                                <SignatureCanvas
                                  ref={(ef) =>
                                    (sigCanvasManager.current[
                                      `${item.name}`
                                    ].current = ef)
                                  }
                                  onEnd={() =>
                                    field.onChange(
                                      formatIntoPngManager(
                                        item.name,
                                        index,
                                        i,
                                        sf
                                      )
                                    )
                                  }
                                  penColor='green'
                                  canvasProps={{
                                    width: "auto",
                                    height: "auto",
                                    className:
                                      "sigCanvas border rounded-lg bg-gray-100 h-auto w-auto",
                                  }}
                                />
                              )}
                            />
                            <input
                              className='hidden sr-only'
                              {...register(
                                `${convertLabelIntoName(item.lable)}`,
                                {
                                  required: parseBooleanVal(item.required),
                                  value: signatures.find(
                                    (x) =>
                                      x.name ===
                                      convertLabelIntoName(item.lable)
                                  )
                                    ? "signature-added"
                                    : null,
                                }
                              )}
                            />
                            {errors?.[
                              `${convertLabelIntoName(item.lable)}`
                            ] && (
                              <span className='text-sm text-red-500'>
                                This field is required!
                              </span>
                            )}
                          </>
                        )
                      )}
                      {register && (
                        <div onClick={() => clearSignatureManager(item.name)}>
                          <p className='flex items-center mt-1 cursor-pointer'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='h-6 w-6'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                              />
                            </svg>
                            {signatures.find((x) => x.name === item.name)
                              ? "update"
                              : "clear"}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else if (item.type === "date" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth) === "true"
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } did-floating-label-content`}
                >
                  <label htmlFor='' className='did-floating-label text-sm'>
                    {item.lable}
                  </label>
                  <input
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                            item.lable
                          )}`,
                          {
                            required: parseBooleanVal(item.required),
                            value: item.value,
                            pattern: dynamicFormDateValidation(
                              item,
                              watchFormData,
                              index,
                              i,
                              sf
                            ),
                          }
                        )
                      : {
                          value: item.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    type='date'
                    className='did-floating-input'
                    min={moment().format("DD-MM-YYYY")}
                  />
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                  {errors?.template?.[index]?.input_fields?.[i]?.sub_section[
                    sf
                  ]?.[`${convertLabelIntoName(item.lable)}`]?.type ===
                    "pattern" && (
                    <span className='text-sm text-red-500'>
                      {dynamicFormDateValidation(
                        item,
                        watchFormData,
                        index,
                        i,
                        sf,
                        false
                      )}
                    </span>
                  )}
                </div>
              );
            } else if (item.type === "text" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth) === "true"
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } did-floating-label-content print:mt-3`}
                >
                  <div className='relative'>
                    <label className='did-floating-label truncate-text text-sm'>
                      {item.lable}
                    </label>
                    <div className='print:hidden'>
                      <input
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                                item.lable
                              )}`,
                              {
                                required: parseBooleanVal(item.required),
                                value: item.value,
                              }
                            )
                          : {
                              value: item.value,
                              readOnly: true,
                              disabled: true,
                            })}
                        type='text'
                        className='did-floating-input'
                      />
                    </div>
                    <div className={`did-floating-input sectionInputViewPrint`}>
                      {item.value}
                    </div>
                    {item?.legalRef && (
                      <div
                        style={{
                          top: "22px",
                        }}
                        className='absolute inset-y-0 right-0 pr-3 flex items-center '
                      >
                        <span
                          onClick={() => setSelectedLegal(item.legalRef)}
                          role='button'
                          tabIndex='0'
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <QuestionMarkCircleIcon
                            className='h-5 w-5 text-gray-400'
                            aria-hidden='true'
                          />
                        </span>
                      </div>
                    )}
                  </div>

                  {item.hasOwnProperty("hint") && item.hint && (
                    <div
                      className=' text-sm text-gray-600 rounded-lg px-2 py-2'
                      dangerouslySetInnerHTML={{
                        __html: item.hint,
                      }}
                    />
                  )}
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                </div>
              );
            } else if (item.type === "radio" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth)
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } mb-4`}
                >
                  <label className='did-floating-label truncate-text text-sm'>
                    {item.lable}
                  </label>
                  <div
                    className={`${
                      item.options.length > 2
                        ? "flex flex-wrap"
                        : "flex flex-col"
                    }`}
                  >
                    {item.options.map((option, oi) => {
                      return (
                        <div
                          key={oi}
                          className='flex items-center space-x-4 mr-4'
                        >
                          <input
                            {...(register
                              ? register(
                                  `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                                    item.lable
                                  )}`,
                                  {
                                    required: parseBooleanVal(item.required),
                                    value: item.value
                                      ? item.value
                                      : option.lable,
                                  }
                                )
                              : {
                                  checked: option.lable === item.value,
                                  readOnly: true,
                                  disabled: true,
                                })}
                            id={`template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                              item.lable
                            )}.${option.lable}`}
                            value={option.lable}
                            type={"radio"}
                            className='h-4 w-4'
                          />
                          <label
                            htmlFor={`template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                              item.lable
                            )}.${option.lable}`}
                            className='truncate-text text-sm'
                          >
                            {option.lable}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                </div>
              );
            } else if (item.type === "select" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth)
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } flex flex-col did-floating-label-content`}
                >
                  <label className='did-floating-label truncate-text text-sm'>
                    {item.lable}
                  </label>
                  <div className='print:hidden'>
                    <select
                      {...(register
                        ? register(
                            `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                              item.lable
                            )}`,
                            {
                              required: parseBooleanVal(item.required),
                              value: item.value,
                            }
                          )
                        : {
                            value: item.value,
                            readOnly: true,
                            disabled: true,
                          })}
                      id=''
                      className='did-floating-select'
                    >
                      {item.options.map((option, oi) => {
                        return (
                          <option value={option.lable} key={oi}>
                            {option.lable}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={`did-floating-input sectionInputViewPrint`}>
                    {item.value}
                  </div>
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                </div>
              );
            } else if (item.type === "table" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className='col-span-12 flex flex-col'
                >
                  <label
                    htmlFor=''
                    className='did-floating-label truncate-text text-sm'
                  >
                    {item.lable}
                  </label>
                  <div className='text-center flex flex-col justify-center'>
                    <div className='mt-8 shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg w-full min-h-[48.4px] max-h-[300px] overflow-auto custom-scroll'>
                      <table className='divide-y divide-gray-300 w-full print:table-fixed'>
                        <thead className='bg-blue-50 capitalize'>
                          <tr className=''>
                            {item?.columns.map((citem, a) => (
                              <th
                                key={`${convertLabelIntoName(
                                  citem.lable
                                )}-${a}`}
                                scope='col'
                                className='py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 table-col-min-width'
                              >
                                {citem.lable}
                              </th>
                            ))}
                            {!isViewable && (
                              <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 max-w-1/5 print:hidden' />
                            )}
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white '>
                          {item.hasOwnProperty("rows") &&
                            item.rows.length > 0 &&
                            item?.rows.map((row, ri) => {
                              return (
                                <tr className='text-sm text-gray-500' key={ri}>
                                  {row?.map((col, ci) => {
                                    if (col.type === "select") {
                                      return (
                                        <td
                                          key={`${convertLabelIntoName(
                                            col.lable
                                          )}-${ci}`}
                                          className='text-sm font-medium text-gray-900 table-col-min-width'
                                        >
                                          <select
                                            {...(register
                                              ? register(
                                                  `template[${index}].input_fields[${i}].sub_fields[${sf}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                    col.lable
                                                  )}`,
                                                  {
                                                    required: parseBooleanVal(
                                                      col.required
                                                    ),
                                                    value: col.value,
                                                  }
                                                )
                                              : {
                                                  value: col.value,
                                                  readOnly: true,
                                                  disabled: true,
                                                })}
                                            key={ci}
                                            id=''
                                            className='w-full px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                            style={{
                                              border: "1px solid #ebe8e8",
                                            }}
                                          >
                                            {col.options.map((option, co) => {
                                              return (
                                                <option
                                                  key={co}
                                                  value={option.lable}
                                                >
                                                  {option.lable}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </td>
                                      );
                                    } else if (col.type === "checkbox") {
                                      return (
                                        <td
                                          key={`${convertLabelIntoName(
                                            col.lable
                                          )}-${ci}`}
                                          className='text-sm font-medium text-gray-900 table-col-min-width print:break-all print:p-2 table-col-min-width print:border print:border-solid print:border-gray-200'
                                        >
                                          <div className='w-8 mx-auto'>
                                            <input
                                              {...(register
                                                ? register(
                                                    `template[${index}].input_fields[${i}].sub_fields[${sf}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                      col.lable
                                                    )}`,
                                                    {
                                                      required: parseBooleanVal(
                                                        col.required
                                                      ),
                                                      value: col.value
                                                        ? col.value
                                                        : "",
                                                    }
                                                  )
                                                : {
                                                    value: col.value
                                                      ? col.value
                                                      : "",
                                                    readOnly: true,
                                                    disabled: true,
                                                  })}
                                              type='checkbox'
                                              checked={col.value}
                                              style={{
                                                border: "1px solid #ebe8e8",
                                              }}
                                              className='block w-full p-2.5 py-3 focus:bg-gray-100 focus:outline-none'
                                            />
                                          </div>
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={`${convertLabelIntoName(
                                            col.lable
                                          )}-${ci}`}
                                          className='text-sm font-medium text-gray-900 table-col-min-width print:break-all print:p-2 table-col-min-width print:border print:border-solid print:border-gray-200'
                                        >
                                          <div className='hidden print:block text-left print:break-all'>
                                            {col.value}
                                          </div>
                                          <input
                                            {...(register
                                              ? register(
                                                  `template[${index}].input_fields[${i}].sub_fields[${sf}].rows[${ri}].columns[${ci}].${convertLabelIntoName(
                                                    col.lable
                                                  )}`,
                                                  {
                                                    required: parseBooleanVal(
                                                      col.required
                                                    ),
                                                    value: col.value
                                                      ? col.value
                                                      : "",
                                                  }
                                                )
                                              : {
                                                  value: col.value
                                                    ? col.value
                                                    : "",
                                                  readOnly: true,
                                                  disabled: true,
                                                })}
                                            type='text'
                                            style={{
                                              border: "1px solid #ebe8e8",
                                            }}
                                            className='block print:hidden w-full px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                          />
                                        </td>
                                      );
                                    }
                                  })}
                                  {/* <div className='pagebreak' /> */}
                                  {!isViewable && (
                                    <td className='text-sm font-medium text-gray-900 max-w-1/5 print:hidden'>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          delteRowHandler(index, i, ri, sf)
                                        }
                                        className='w-full border px-4 py-2 focus:bg-gray-100 focus:outline-none'
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className='pagebreak'></div>
                    {!isViewable && (
                      <div className='flex justify-start items-center space-x-4 my-4'>
                        <span
                          onClick={() => addRowHandler(index, i, sf)}
                          className='cursor-pointer border py-1.5 pl-2 pr-3 bg-blue-800 text-white rounded-lg flex items-center space-x-1'
                        >
                          <FiPlus />
                          <span>Row</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            } else if (item.type === "phone" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`${
                    parseBooleanVal(item.isFullWidth)
                      ? "col-span-12"
                      : "col-span-12 md:col-span-6"
                  } flex flex-col did-floating-label-content`}
                >
                  <label className='did-floating-label text-sm'>
                    {item.lable}
                  </label>
                  <input
                    {...(register
                      ? register(
                          `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                            item.lable
                          )}`,
                          {
                            pattern: REGEX_PHONE_NUMBER,
                            onChange: (e) => {
                              const sanitizedValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              const formattedValue = sanitizedValue.slice(
                                0,
                                10
                              );
                              e.target.value = formattedValue;
                            },
                            value: item.value,
                            required: parseBooleanVal(item.required),
                            // minLength: 10,
                            maxLength: 10,
                          }
                        )
                      : {
                          value: item.value,
                          readOnly: true,
                          disabled: true,
                        })}
                    className='did-floating-input appearance-no'
                    type='number'
                    maxLength='10'
                    // minLength='10'
                  />
                  {!isViewable && getError(errors, item.lable, index, i, sf)}
                  {errors?.template?.[index]?.input_fields?.[i]?.sub_section[
                    sf
                  ][`${item.name}`]?.type === "minLength" && (
                    <span className='text-sm text-red-500'>
                      Phone number must have 10 digits
                    </span>
                  )}
                  {errors?.template?.[index]?.input_fields?.[i]?.sub_section[
                    sf
                  ][`${item.name}`]?.type === "maxLength" && (
                    <span className='text-sm text-red-500'>
                      Phone number must have 10 digits
                    </span>
                  )}
                  {errors?.template?.[index]?.input_fields?.[i]?.sub_section[
                    sf
                  ][`${item.name}`]?.type === "pattern" && (
                    <span className='text-sm text-red-500'>
                      {/* Phone number must start with 04, 1300 or 1800 */}
                      Please enter a valid phone number!
                    </span>
                  )}
                </div>
              );
            } else if (item.type === "address" && checkIsDependent(item)) {
              return (
                <div
                  key={`${convertLabelIntoName(item.lable)}-${sf}`}
                  className={`col-span-12 flex flex-col did-floating-label-content print:mt-3`}
                >
                  <div className='grid grid-cols-4 space-x-4'>
                    <div className='col-span-2 md:col-span-1 did-floating-label-content'>
                      <label className='did-floating-label text-sm'>
                        Street Name
                      </label>
                      <div className='print:hidden'>
                        <input
                          {...(register
                            ? register(
                                `template[${index}].input_fields[${i}].sub_section[${sf}].address.street`,
                                {
                                  required: parseBooleanVal(item.required),
                                  value: item.hasOwnProperty("address")
                                    ? item.address.street
                                    : "",
                                }
                              )
                            : {
                                value: item.hasOwnProperty("address")
                                  ? item.address.street
                                  : "",
                                readOnly: true,
                                disabled: true,
                              })}
                          className='did-floating-input'
                          type='text'
                        />
                        {errors?.template?.[index]?.input_fields?.[i]
                          ?.sub_section?.[sf]?.address?.street?.type ===
                          "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div
                        className={`did-floating-input sectionInputViewPrint`}
                      >
                        {item.hasOwnProperty("address")
                          ? item.address.street
                          : ""}
                      </div>
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                      <label className='did-floating-label text-sm'>
                        Suburb
                      </label>
                      <div className='print:hidden'>
                        <input
                          {...(register
                            ? register(
                                `template[${index}].input_fields[${i}].sub_section[${sf}].address.subrub`,
                                {
                                  required: item.required,
                                  value: item.hasOwnProperty("address")
                                    ? item.address.subrub
                                    : "",
                                }
                              )
                            : {
                                value: item.hasOwnProperty("address")
                                  ? item.address.subrub
                                  : "",
                                readOnly: true,
                                disabled: true,
                              })}
                          className='did-floating-input'
                          type='text'
                        />
                        {errors?.template?.[index]?.input_fields?.[i]
                          ?.sub_section?.[sf]?.address?.subrub?.type ===
                          "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div
                        className={`did-floating-input sectionInputViewPrint`}
                      >
                        {item.hasOwnProperty("address")
                          ? item.address.subrub
                          : ""}
                      </div>
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                      <label className='did-floating-label text-sm'>
                        Postcode
                      </label>
                      <input
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].sub_section[${sf}].address.postcode`,
                              {
                                required: item.required,
                                value: item.hasOwnProperty("address")
                                  ? item.address.postcode
                                  : "",
                                onChange: (e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  e.target.value = value.slice(0, 4);
                                },
                                minLength: 4,
                                maxLength: 4,
                              }
                            )
                          : {
                              value: item.hasOwnProperty("address")
                                ? item.address.postcode
                                : "",
                              readOnly: true,
                              disabled: true,
                            })}
                        className='did-floating-input'
                        type='text'
                      />
                      {errors?.template?.[index]?.input_fields?.[i]
                        ?.sub_section?.[sf]?.address?.postcode?.type ===
                        "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {(errors?.template?.[index]?.input_fields?.[i]
                        ?.sub_section?.[sf]?.address?.postcode?.type ===
                        "maxLength" ||
                        errors?.template?.[index]?.input_fields?.[i]
                          ?.sub_section?.[sf]?.address?.postcode?.type ===
                          "minLength") && (
                        <span className='text-sm text-red-500'>
                          Postcode must be 4 digits!
                        </span>
                      )}
                    </div>

                    <div className='col-span-2 md:col-span-1'>
                      <label className='did-floating-label text-sm'>
                        State
                      </label>
                      <select
                        className='did-floating-select'
                        {...(register
                          ? register(
                              `template[${index}].input_fields[${i}].sub_section[${sf}].address.state`,
                              {
                                required: item.required,
                                value: item.hasOwnProperty("address")
                                  ? item.address.state
                                  : "New south Wales",
                              }
                            )
                          : {
                              value: item.hasOwnProperty("address")
                                ? item.address.state
                                : "",
                              readOnly: true,
                              disabled: true,
                            })}
                      >
                        {STATE_FULL_LABEL_OPTIONS.map((option, state_index) => (
                          <option
                            key={`${state_index}-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors?.template?.[index]?.input_fields?.[i]
                        ?.sub_scetion?.[sf]?.address?.state?.type ===
                        "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  {checkIsDependent(item) && (
                    <div
                      key={`${convertLabelIntoName(item.lable)}-${sf}`}
                      className={`${
                        parseBooleanVal(item.isFullWidth)
                          ? "col-span-12"
                          : "col-span-12 md:col-span-6"
                      } did-floating-label-content print:mt-3`}
                    >
                      <label
                        htmlFor=''
                        className='did-floating-label truncate-text text-sm'
                      >
                        {item.lable}
                      </label>
                      <div className='print:hidden'>
                        <input
                          {...(register
                            ? register(
                                `template[${index}].input_fields[${i}].sub_section[${sf}].${convertLabelIntoName(
                                  item.lable
                                )}`,
                                {
                                  required: parseBooleanVal(item.required),
                                  value: item?.value ?? "",
                                }
                              )
                            : {
                                value: item?.value ?? "",
                                readOnly: true,
                                disabled: true,
                              })}
                          type={item.type}
                          className='did-floating-input'
                        />
                      </div>
                      <div
                        className={` did-floating-input sectionInputViewPrint`}
                      >
                        {item?.value ?? ""}
                      </div>

                      {!isViewable &&
                        getError(errors, item.lable, index, i, sf)}
                    </div>
                  )}
                </>
              );
            }
          })}
      </div>
    </>
  );
};

export default SubSectionInputFields;
