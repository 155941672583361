import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";
import { GET_MONTH } from "store/constants";
import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  PRIMARY_DISABILITY_OPTIONS,
  PRIMARY_DISABILITY_OPTIONS_REPORTING,
  REPORTING_LIMIT,
  SORT_TYPE,
  STATES_LIST,
  STATES_LIST_REPORTING,
  StatusOptions,
  StatusOptionsReporting,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_PARTICIPANT_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";
import { addMonths, exportTOExcel, truncateTextReporting } from "Utils/utils";
import PopoverContainer from "components/common/Popover/PopoverContainer";

const ParticipantListReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);
  const [BirthDate, setBirthDate] = useState(null);
  const [primaryDisability, setPrimaryDisability] = useState(null);
  const [monthOfBorn, setMonthOfBorn] = useState(null);
  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [state, setState] = useState(null);
  const [suburb, setSuburb] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  const handleCancelButton = () => {
    setBirthDate(null);
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchParticipantListReports();
  }, [page]);

  const fetchParticipantListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    // if (!fromDate && !toDate) {
    //   toast("Select create date range to get data");
    //   return;
    // }

    setLoader(true);
    let url = `${URL_REPORTING_PARTICIPANT_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_PARTICIPANT_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      dob: isClearAll ? "all" : BirthDate ? BirthDate : "all",
      primary_disability: isClearAll
        ? "all"
        : primaryDisability
        ? primaryDisability?.value
        : "all",
      date_range_created: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      born_month: isClearAll ? "all" : monthOfBorn ? monthOfBorn?.value : "all",
      suburb: suburb ? suburb : "",
      state: isClearAll ? "all" : state ? state?.value : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            const reportData = res?.data?.data;

            if (report === "xlsx") {
              exportTOExcelFile(reportData);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.participants?.total,
              totalPages: Math.ceil(
                res?.data?.data?.participants?.total /
                  res?.data?.data?.participants?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchParticipantListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.participant &&
      reportData?.participant?.map((item) => {
        const workersString =
          item.workers && item?.workers?.length > 0
            ? item.workers
                .map((worker) => `${worker.first_name} ${worker.last_name}`)
                .join(", ")
            : "";

        return {
          Participant: `${item?.first_name} ${item?.last_name}` || "N/A",
          Workers: workersString || "N/A",
          Primary_Number: item?.primary_no || "N/A",
          Email: item?.email || "N/A",
          Status: item?.status || "N/A",
          Date_of_Birth: item?.dob
            ? moment(item?.dob).format("DD/MM/YYYY")
            : "N/A",
          Primary_Disability: item?.primary_disability || "N/A",
          Address: item?.address || "N/A",
          Suburb: item?.suburb || "N/A",
          State: item?.state || "N/A",
          NDIS_NO: item?.ndis_no || "N/A",
          Emergency_Contact_Name: item?.emergency_contact_name || "N/A",
          Emergency_Phone: item?.emergency_contact || "N/A",
          Created_Date: item?.created_at
            ? moment(item?.created_at).format("DD/MM/YYYY")
            : "N/A",
          Born_Month: item?.born_month || "N/A",
        };
      });

    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchParticipantListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full bg-red-500'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Participant List Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.PARTICIPANT_LIST_REPORTS
                )}
              />
            }
          />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptionsReporting || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Suburb
                        </label>
                        <input
                          value={suburb}
                          onChange={(e) => setSuburb(e.target.value)}
                          className='did-floating-input'
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Date of Birth
                        </label>
                        <DateRangePickerNew
                          isSingleDate
                          isClearable
                          handleCancel={handleCancelButton}
                          handleChange={(obj) => {
                            setBirthDate(obj);
                          }}
                          singleSelectedDate={BirthDate && new Date(BirthDate)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <label
                        htmlFor='from-date-to-to-date'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Month of Birth
                      </label>
                      <SelectOption
                        name='born_month'
                        isSearch={true}
                        data={GET_MONTH}
                        selectedOption={monthOfBorn}
                        handleChange={(e) => {
                          setMonthOfBorn(e);
                        }}
                      />
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Primary Disability
                        </label>
                        <SelectOption
                          name='primary_disability'
                          isSearch={true}
                          data={PRIMARY_DISABILITY_OPTIONS_REPORTING || []}
                          selectedOption={primaryDisability}
                          handleChange={(e) => {
                            setPrimaryDisability(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          State
                        </label>
                        <SelectOption
                          name='state'
                          isSearch={true}
                          data={STATES_LIST_REPORTING || []}
                          selectedOption={state}
                          handleChange={(e) => {
                            setState(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Participant Created Date Range
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          // setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          // maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'></div>
                    <div className='w-full'></div>

                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() =>
                            fetchParticipantListReports(false)
                          }
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setBirthDate(null);
                            setToDate(null);
                            setFromDate(null);
                            setMonthOfBorn(null);
                            setStatus(null);
                            setSelectedWorker(null);
                            setPrimaryDisability(null);
                            setSearch(null);
                            setReports([]);
                            setState(null);
                            setSuburb("");
                            setSelectedParticipant(null);
                            setSelectedFromDate(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                        sortName='first_name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Participant Name"}
                      />

                      <TableHeader
                        sortName='support_worker'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Support Worker"}
                      />

                      <TableHeader
                        sortName='primary_no'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Contact Number"}
                      />

                      <TableHeader
                        sortName='email'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Email"}
                      />

                      <TableHeader
                        sortName='status'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Status"}
                      />

                      <TableHeader
                        sortName='dob'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Date of Birth"}
                      />

                      <TableHeader
                        sortName='primary_disability'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Primary Disability"}
                      />

                      <TableHeader
                        sortName='street_no'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Address"}
                      />

                      <TableHeader
                        sortName='suburb'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Suburb"}
                      />

                      <TableHeader
                        sortName='state'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"State"}
                      />
                      <TableHeader
                        sortName='ndis_no'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"NDIS No"}
                      />

                      <TableHeader
                        sortName='emergency_contact_name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Emergency Contact"}
                      />

                      <TableHeader
                        sortName='emergency_contact'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Emergency Phone"}
                      />

                      <TableHeader
                        sortName='created_at'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Created Date"}
                      />

                      <TableHeader
                        sortName='born_month'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Born Month"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={15} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.participants?.data?.length > 0 ? (
                      reportList?.participants?.data?.map(
                        (participant, index) => (
                          <tr
                            key={index}
                            className='even:bg-white odd:bg-gray-50'
                          >
                            <td className='whitespace-nowrap px-3 py-4 text-sm'>
                              <div className='flex items-center'>
                                <div className='capitalize pl-1'>
                                  <span
                                    title={`${participant.first_name} ${participant.last_name}`}
                                  >
                                    <p
                                      className={`w-40 truncate cursor-pointer `}
                                    >{`${participant.first_name} ${participant.last_name}`}</p>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div>
                                {(participant?.workers &&
                                  _.isArray(participant?.workers) && (
                                    <AvatarGroup
                                      type='worker'
                                      arrayData={participant?.workers || []}
                                      cssClass='mt-0'
                                      maxLength={5}
                                    />
                                  )) ||
                                  "-"}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                              {participant?.primary_no || "-"}
                            </td>
                            <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                              {participant?.email ? participant?.email : "-"}
                            </td>
                            <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                              <span
                                className={`${
                                  participant.status === "active"
                                    ? "bg-green-100 text-green-700"
                                    : "bg-red-100 text-red-700"
                                } inline-flex rounded-full  px-3 text-left text-xs font-semibold leading-5 capitalize `}
                              >
                                {participant.status}
                              </span>
                            </td>

                            <td className='px-3 text-left py-[11px] text-sm text-gray-500'>
                              {participant?.dob
                                ? moment(participant.dob).format("DD/MM/YYYY")
                                : "-"}
                            </td>
                            <td className='whitespace-nowrap py-4  px-3 text-sm'>
                              <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "100px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {participant?.primary_disability ? (
                                  <PopoverContainer
                                    cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                    itemComponent={() => {}}
                                    toolTip={`${participant?.primary_disability}`}
                                  >
                                    <span>
                                      {truncateTextReporting(
                                        participant?.primary_disability
                                      )}
                                    </span>
                                  </PopoverContainer>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </td>
                            <td className=' py-4 text-sm'>
                              <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "100px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {participant?.address ? (
                                  <PopoverContainer
                                    cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                    itemComponent={() => {}}
                                    toolTip={`${participant?.address}`}
                                  >
                                    <span>
                                      {truncateTextReporting(
                                        participant?.address
                                      )}
                                    </span>
                                  </PopoverContainer>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "100px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {participant?.suburb ? (
                                  <PopoverContainer
                                    cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                    itemComponent={() => {}}
                                    toolTip={`${participant?.suburb}`}
                                  >
                                    <span>
                                      {truncateTextReporting(
                                        participant?.suburb
                                      )}
                                    </span>
                                  </PopoverContainer>
                                ) : (
                                  "-"
                                )}
                              </div>
                              {/* {participant?.suburb || "-"} */}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "100px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {participant?.state ? (
                                  <PopoverContainer
                                    cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                    itemComponent={() => {}}
                                    toolTip={`${participant?.state}`}
                                  >
                                    <span>
                                      {truncateTextReporting(
                                        participant?.state
                                      )}
                                    </span>
                                  </PopoverContainer>
                                ) : (
                                  "-"
                                )}
                              </div>
                              {/* {participant?.state || "-"} */}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.ndis_no || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.emergency_contact_name || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.emergency_contact || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.created_at
                                ? moment(participant.created_at).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.born_month || "-"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan={15}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please choose the date range to get
                          data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.participants?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.participants?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ParticipantListReports;
