import InputField from "components/common/Input/Input";
import SelectOption from "components/common/Select/Select";
import React, { useEffect, useState } from "react";

const ActiveHours = ({
  watchFormData,
  index,
  register,
  itemNumber = [],
  setValue,
  clearErrors,
  item,
  selectedTimeSheet,
  disabledAllComponent = false,
  isNonBillable,
  totalBreakdownLength,
  alreadySelectedItemNumber,
}) => {
  const [selectedItemNumber, setSelectedItemNumber] = useState("");
  const [activeHoursError, setActiveHoursError] = useState(false);
  const val = watchFormData[`invoice_item_id_active_hours${index}`];
  useEffect(() => {
    if (item && val) {
      const selectedItem = itemNumber?.find((item) => item.value === val);
      if (selectedItem) {
        setSelectedItemNumber(selectedItem);
      }
    }
  }, [itemNumber, val, index, item, watchFormData]);
  useEffect(() => {
    setValue(
      `invoice_item_name_active_hours${index}`,
      selectedItemNumber.number
    );
  }, [selectedItemNumber.number, index, setValue]);

  const NDIS_RATE = [
    {
      label: "Apply Alternative Rate",
      value: "alternate",
    },
    {
      label: selectedItemNumber ? selectedItemNumber?.price : "",
      value: selectedItemNumber ? selectedItemNumber?.price : "",
    },
  ];

  const getAppliedAmount = () => {
    if (
      watchFormData &&
      watchFormData[`invoice_ndis_rate_active_hours${index}`] &&
      watchFormData[`invoice_ndis_rate_active_hours${index}`] !== "alternate"
    ) {
      const result =
        watchFormData[`invoice_ndis_rate_active_hours${index}`] /
        selectedTimeSheet?.participants?.length;

      return result.toFixed(2);
    } else if (
      watchFormData[`invoice_ndis_rate_active_hours${index}`] === "alternate"
    ) {
      const result2 =
        watchFormData[`rate_amount_active_hours${index}`] /
        selectedTimeSheet?.participants?.length;

      return !isNaN(result2.toFixed(2)) ? result2.toFixed(2) : "";
    }
  };

  useEffect(() => {
    if (
      selectedItemNumber?.price &&
      watchFormData[`invoice_ndis_rate_active_hours${index}`] !== "alternate"
    ) {
      setValue(
        `rate_amount_active_hours${index}`,
        watchFormData[`invoice_ndis_rate_active_hours${index}`]
      );
    }
  }, [watchFormData[`invoice_ndis_rate_active_hours${index}`]]);

  let activeHours = watchFormData[`active_hours_${index}`];
  useEffect(() => {
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    if (activeHours && totalBreakdownLength) {
      const activeHoursInMinutes = timeToMinutes(activeHours);
      const totalBreakdownInMinutes = timeToMinutes(totalBreakdownLength);

      if (activeHoursInMinutes > totalBreakdownInMinutes) {
        setActiveHoursError(true);
        setTimeValidationError(false)
      } else {
        setActiveHoursError(false);
      }
    }
    
  }, [activeHours, totalBreakdownLength]);
 
useEffect(()=>{
  if(activeHours){
    if (!validateTimeFormat(activeHours)) {
      setTimeValidationError(true);
    } else {
      setTimeValidationError(false);
    }
  }
  
},[activeHours])
  function formatTime(totalBreakdownLength) {
  if(!totalBreakdownLength) return;
    const timeRegex = /^(\d{1,2}):([0-5]?\d)$/;
    const match = totalBreakdownLength.match(timeRegex);

    if (!match) {
        return "Invalid time format. Please use HH:MM.";
    }

  
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);


    return `${hours} hours and ${minutes} minutes`;
}
const [timeValidationError,setTimeValidationError]=useState(false)
const validateTimeFormat = (time) => {
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/; 
  return timeRegex.test(time);
};

  return (
    <>
      {watchFormData?.[`sleepover_${index}`] && watchFormData?.sleepover && (
        <div className='border border-gray-100 shadow-md shadow-indigo-100  py-7 px-6 rounded-lg mt-4'>
          <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
            <div className='flex w-full gap-x-4'>
              <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                Active Hours
              </label>
              <div className='w-full'>
                <InputField
                  cssClass='mt-0 h-40-important'
                  inputWidth='w-full'
                  type='text'
                  placeHolder='HH:MM '
                  name={`active_hours_${index}`}
                  register={register}
               
                />
                {activeHoursError && (
                  <p className='text-red-600 text-sm' style={{ textTransform: "none" }}>
                    Active hours cannot exceed the total breakdown time of 
                    {" "}{formatTime(totalBreakdownLength)}.
                  </p>
                )}
                  {timeValidationError && (
          <p className="text-red-600 text-sm" style={{ textTransform: "none" }}>
            Please enter a valid time in HH:MM format.
          </p>
        )}
              </div>
            </div>
          </div>
          {watchFormData[`active_hours_${index}`] && (
            <>
              <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                <div className='flex w-full gap-x-4'>
                  <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                    NDIS Item Name<span className='text-red-600'>*</span>
                  </label>
                  <div className='w-full'>
                    <SelectOption
                      isDisabled={!alreadySelectedItemNumber}
                      name={`invoice_item_id_active_hours${index}`}
                      data={itemNumber?.filter(
                        (item) => item?.value !== alreadySelectedItemNumber
                      )}
                      cssClass='w-full'
                      isRequired={true}
                      isSearch={false}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      selectedOption={
                        (itemNumber &&
                          watchFormData[
                            `invoice_item_id_active_hours${index}`
                          ]) ||
                        (Array.isArray(item?.invoice_item_id_active_hours) &&
                          item?.invoice_item_id_active_hours[index])
                          ? itemNumber?.find(
                              (x) =>
                                x.value ===
                                watchFormData[
                                  `invoice_item_id_active_hours${index}`
                                ]
                            )
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                <div className='flex w-full gap-x-4'>
                  <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                    NDIS Item Number<span className='text-red-600'>*</span>
                  </label>
                  <div className='w-full'>
                    <InputField
                      //   isDisabled={disabledAllComponent}
                      readOnly={true}
                      cssClass='mt-0 h-40-important'
                      inputWidth='w-full'
                      name={`invoice_item_name_active_hours${index}`}
                      // value={selectedItemNumber.number}
                      isRequired={true}
                      register={register}
                    />
                  </div>
                  <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                    Hourly Rate<span className='text-red-600'>*</span>
                  </label>
                  <div className='w-full'>
                    <SelectOption
                      isDisabled={
                        isNonBillable ||
                        disabledAllComponent ||
                        !alreadySelectedItemNumber
                      }
                      data={NDIS_RATE}
                      name={`invoice_ndis_rate_active_hours${index}`}
                      cssClass='w-full'
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      selectedOption={
                        watchFormData[
                          `invoice_ndis_rate_active_hours${index}`
                        ] &&
                        NDIS_RATE?.find(
                          (x) =>
                            x.value ===
                            watchFormData[
                              `invoice_ndis_rate_active_hours${index}`
                            ]
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                <div className='flex w-full gap-x-4'>
                  <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                    Rate Amount <span className='text-red-600'>*</span>
                  </label>
                  <div className='w-full'>
                    {watchFormData?.[
                      `invoice_ndis_rate_active_hours${index}`
                    ] === "alternate" ? (
                      <InputField
                        isDisabled={
                          watchFormData?.non_billable || disabledAllComponent
                        }
                        cssClass='mt-0 h-40-important'
                        inputWidth='w-full'
                        setValue={setValue}
                        // value={watchFormData?.rate_amount_active_hours[`${index}`]}
                        name={`rate_amount_active_hours${index}`}
                        isRequired={true}
                        register={register}
                        type='number'
                        step='0.01'
                      />
                    ) : (
                      <InputField
                        isDisabled={true || disabledAllComponent}
                        cssClass='mt-0 h-40-important'
                        inputWidth='w-full'
                        name={`rate_amount_active_hours${index}`}
                        isRequired={true}
                        register={register}
                        setValue={setValue}
                      />
                    )}
                  </div>
                  <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                    Applied Rate
                  </label>
                  <div className='w-full'>
                    <InputField
                      //   isDisabled={true || disabledAllComponent}
                      cssClass='mt-0 h-40-important'
                      inputWidth='w-full'
                      name={`applied_rate_active_hours${index}`}
                      value={
                        watchFormData[
                          `invoice_ndis_rate_active_hours${index}`
                        ] && getAppliedAmount()
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ActiveHours;
