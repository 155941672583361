import React, { useEffect, useState, useCallback } from "react";
import SpotLightSearch from "../SpotLight/SpotLight";
import NotificationDropDown from "../NotificationDropDown/NotificationDropDown";
import Search from "../Search/Search";
import CustomButton from "../Button/CustomButton";
import { USER_ROLE } from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
import { PERMISSION_KEYS } from "store/constants";
import { classNames } from "Utils/utils";
import SelectOption from "../Select/Select";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_TEAM_LEAD_GET_MANAGERS_REGIONS_LIST,
  URL_TEAM_LEAD_MANAGER_REGION_STATUS_UPDATE,
} from "store/services/URL";
import CustomMultiSelect from "../CustomMulitDropdown";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Header = ({
  title,
  desc = "",
  breadcrumb = null,
  isButton = false,
  handleClick,
  btnLabel = "",
  icon,
  btnCss,
  variants,
  btnComponent = null,
  cssClass,
}) => {
  const [searchBox, setSearchBox] = useState(false);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchRegionsList = async () => {
    setLoading(true);
    await apiGetRequest(URL_TEAM_LEAD_GET_MANAGERS_REGIONS_LIST)
      .then((res) => {
        const regionsList = [
          { label: "All", value: "all" },
          ...res?.data?.data?.map((el) => ({
            label: el?.name,
            value: el?.id,
          })),
        ];

        setRegions(regionsList);
        const isStatusChanged = res?.data?.data
          ?.filter((el) => el?.pivot?.status)
          ?.map((el) => ({ label: el?.name, value: el?.id }));

        localStorage.setItem(
          "selectedRegions",
          JSON.stringify(isStatusChanged)
        );

        setLoading(false);
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
      });
  };

  const handleApiCall = async (values = []) => {
    setLoader(true);
    let payload = {};
    let regionsIds = [];

    if (values?.some((el) => el?.value === "all")) {
      payload = {
        region_id: "all",
      };
    } else {
      regionsIds = values?.map((el) => el.value);
      payload = {
        region_id: regionsIds,
      };
    }

    await apiPostRequest(URL_TEAM_LEAD_MANAGER_REGION_STATUS_UPDATE, payload)
      .then((res) => {
        if (res.data) {
          localStorage.setItem("selectedRegions", JSON.stringify(values));

          window.location.reload();
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoader(false));
  };

  const debouncedApiCall = useCallback(debounce(handleApiCall, 1500), []);

  const handleChange = (e) => {
    setSelectedRegions(e);
    debouncedApiCall(e);
  };

  useEffect(() => {
    const savedRegions = localStorage.getItem("selectedRegions");

    if (savedRegions) {
      setSelectedRegions(JSON.parse(savedRegions));
    }
  }, []);

  return (
    <header
      className={classNames(
        "bg-white flex items-center sticky top-0 z-[50] border-b border-gray-200 px-4 md:px-6 lg:px-8 py-[20px] h-[80px]",
        cssClass && `${cssClass}`
      )}
    >
      <div className='items-center w-full flex sm:flex sm:items-center sm:justify-between'>
        <div className='flex-1 min-w-0'>
          <h1 className='text-xl font-semi bold text-gray-900 capitalize'>
            {title}
          </h1>
          <p className='mt-1 text-sm text-gray-500'>{desc}</p>
          {breadcrumb && <div className='mt-2'>{breadcrumb}</div>}
        </div>
        <div className='mt-4 flex justify-space-between sm:mt-0 space-x-2 print:hidden'>
          <div>
            {roleAccess([USER_ROLE.Sp_manager]) && (
              <div className='flex gap-2 items-center'>
                <label className='block text-medium font-medium text-gray-700'>
                  Selected Regions
                </label>
                <div className='w-[350px]'>
                  <CustomMultiSelect
                    options={regions}
                    selectedRegions={selectedRegions}
                    setSelectedRegions={setSelectedRegions}
                    onFocusHandler={fetchRegionsList}
                    onChange={handleChange}
                    isDisabled={loader}
                    isManagerModule
                    isApiLoading={loading}
                  />
                
                </div>
              </div>
            )}
          </div>
          <div className='md:mt-0 md:ml-auto space-x-2 md:space-x-4 hidden sm:flex'>
            {isButton && (
              <CustomButton
                clickHandler={handleClick}
                label={btnLabel}
                Icon={icon}
                variant={variants}
                cssClass={btnCss}
              />
            )}
            {btnComponent && <>{btnComponent}</>}
          </div>
          {roleAccess([
            USER_ROLE.Sp_manager,
            USER_ROLE.Sp_Admin,
            USER_ROLE.Sp_Standard_User,
          ]) && (
            <div className='flex justify-end items-center space-x-2'>
              {roleAccess([], PERMISSION_KEYS.PARTICIPANT) && (
                <div>
                  <Search
                    cssClass='w-5 h-5'
                    isSearchOnly={true}
                    handleClick={() => setSearchBox(true)}
                  />
                </div>
              )}
              <div className='flex sm:mt-0 sm:ml-4'>
                <NotificationDropDown />
              </div>
            </div>
          )}
        </div>
      </div>
      {roleAccess([], PERMISSION_KEYS.PARTICIPANT) && searchBox && (
        <SpotLightSearch handleClose={() => setSearchBox(false)} />
      )}
    </header>
  );
};

export default Header;
