import React, { useEffect, useRef, useState } from "react";
import Search from "components/common/Search/Search";
import TimeSheetList from "./TimeSheetList";
import { useDispatch } from "react-redux";
import { showToast } from "store/actions/general.actions";
import { resetIsAnyPendingExistForWorker } from "store/actions/rostering.actions";
import Loader from "components/common/Loader/Loader";

const TimeSheetSideBar = (props) => {
  const {
    timeSheets,
    selectedTimeSheet,
    setSelectedTimeSheetLocally,
    isAnyPendingExist,
    workerTimeSheetsLocallySearch,
    fetchTimesheets: handleFeatchTimesheetsCall,
    workerTimesheetPaginationLoader,
    workerTimesheetLoader,
    paginationObj,
  } = props;

  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);

  const myRef = useRef(null);

  useEffect(() => {
    scrollToRef(myRef);
  }, [myRef]);

  const scrollToRef = (ref) => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (
      isAnyPendingExist !== null &&
      isAnyPendingExist !== true &&
      isAnyPendingExist === "false"
    ) {
      dispatch(
        showToast({
          title: "Worker doesn't have any pending timesheet!",
        })
      );
      dispatch(resetIsAnyPendingExistForWorker());
    }
  }, [isAnyPendingExist]);

  useEffect(() => {
    if (search === null) return;
    workerTimeSheetsLocallySearch(search);
  }, [search]);

  useEffect(() => {
    return () => {
      setSearch(null);
      workerTimeSheetsLocallySearch(null);
    };
  }, []);

  const handleScroll = () => {
    if (myRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = myRef.current;
      if (
        scrollTop + clientHeight + 10 >= scrollHeight &&
        paginationObj.totalPages > paginationObj.page
      ) {
        fetchTimesheets({ page: paginationObj.page + 1 });
      }
    }
  };

  const fetchTimesheets = async ({ page }) => {
    if (
      page > paginationObj?.totalPages ||
      workerTimesheetPaginationLoader ||
      workerTimesheetLoader
    ) {
      return;
    }

    const payload = {
      page,
      per_page: 5,
      paginationCall: true,
    };
    handleFeatchTimesheetsCall(payload);
  };

  return (
    <div className="sticky top-0">
      <div className='bg-orange-50 py-3 px-2.5 rounded-tl-lg rounded-tr-lg text-center'>
        <span className='text-base font-medium text-orange-700'>
          {timeSheets.ttlPendingTimeSheets} pending timesheets
        </span>
      </div>
      <div className='p-3'>
        <Search
          customClassSearch='start-time-input'
          placeholder='Search...'
          className='mb-5'
          setSearch={setSearch}
          search={search}
        />
        <div
          className='overflow-y-auto h-[860px]'
          onScroll={handleScroll}
          ref={myRef}
        >
          <TimeSheetList
            setSelectedTimeSheetLocally={setSelectedTimeSheetLocally}
            selectedTimeSheet={selectedTimeSheet}
            timeSheetData={timeSheets.timeSheets}
          />
          {workerTimesheetPaginationLoader && workerTimesheetLoader && (
            <div className='pt-3 flex justify-center items-center'>
              <Loader cssClass={"text-black"} isSmallView={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSheetSideBar;
