import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import SideBar from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import CaseNotesHistoryTable from "./CaseNotesHistoryTable";
import SelectOption from "components/common/Select/Select";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import Header from "components/common/Header/Header";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import { BsFilterCircle } from "react-icons/bs";
import { FiDelete } from "react-icons/fi";
import api from "store/services/cos.services";

const CaseNotesHistoryMainPage = (props) => {
  const {
    ContactList,
    getContactListRequest,
    getClientList,
    caseNotesHistoryLoading,
    clientsList,
    getCaseNotesHistoryList,
    caseNotesHistoryList,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [tableData, setTableData] = useState([]);
  const [serviceContract, setserviceContract] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    getContactListRequest({ status: "active" });
    getClientList();
    searchFilterDataRequest();
  }, []);

  useEffect(() => {
    if (clientsList && clientsList) {
      clientsList.unshift({ label: "All", value: "all" });
    }
  }, [clientsList]);

  useEffect(() => {
    if (ContactList && ContactList) {
      ContactList.unshift({ label: "All", value: "all" });
    }
  }, [ContactList]);

  useEffect(() => {
    setTableData(caseNotesHistoryList);
  }, [caseNotesHistoryList]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleCancelButton = () => {
    setFromDate(null);
    setToDate(null);
  };

  const searchFilterDataRequest = (isClearAll = false) => {
    getCaseNotesHistoryList({
      pt_id: isClearAll ? "all" : selectedClient ? selectedClient.value : "all",
      contact_type_id: isClearAll
        ? "all"
        : serviceContract
        ? serviceContract.value
        : "all",
      from_date: isClearAll ? "all" : fromDate ? fromDate : "all",
      to_date: isClearAll ? "all" : toDate ? toDate : "all",
    });
  };

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const handleFileDownLoad = async (e) => {
    if (e && tableData && tableData.length === 0) {
      toast("No Data For Exporting!");
      return;
    }

    setRequest({ ...request, isRequested: true });
    try {
      const res = await api.getCaseNotesHistoryListApi({
        pt_id: selectedClient ? selectedClient.value : "all",
        from_date: fromDate ? fromDate : "all",
        to_date: toDate ? toDate : "all",
        contact_type_id: serviceContract ? serviceContract.value : "all",
        report: e,
      });
      setRequest({ ...request, isRequested: false, isSuccess: true });
      window.open(res.report.download_url, "_blank");
    } catch (err) {
      console.log("err", err);
      setRequest({ ...request, isRequested: false, isSuccess: true });
      toast.error(err.message ?? "Soemthing Went Wrong!");
    }
  };

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col `}
        >
          <main className='flex-1 bg-gray-50 '>
            <Header
              title='Case Notes History'
              breadcrumb={
                <Breadcrumb
                  pages={COS_BREADCRUMB(
                    COS_BREADCRUMB_PAGES.CASE_NOTES_HISTORY
                  )}
                />
              }
            />
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={request.isRequested}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
              </div>
            </div>
            <div className='max-w-7xl mx-auto py-3'>
              <div className='space-y-6 lg:col-start-1 lg:col-span-3 '>
                <div className='bg-gray-50 sm:px-6 lg:px-8 pt-0 sticky top-[67px] z-[7]'>
                  <section>
                    <div className='flex py-4 w-full justify-between space-x-2'>
                      <div className='w-full'>
                        <div className='col-span-1'>
                          <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                            Participant
                          </label>
                          <SelectOption
                            name='pt_id'
                            isSearch={true}
                            data={clientsList ? clientsList : []}
                            selectedOption={selectedClient}
                            handleChange={(e) => {
                              setSelectedClient(e);
                            }}
                            // placeHolder="Participant Name"
                          />
                        </div>
                      </div>
                      <div className='w-full'>
                        <div className='col-span-1'>
                          <label className='block  text-sm font-medium text-gray-700 text-start'>
                            Note Contact Type
                          </label>
                          <SelectOption
                            name='contact_type_id'
                            isSearch={true}
                            data={ContactList ? ContactList : []}
                            handleChange={(e) => {
                              setserviceContract(e);
                            }}
                            selectedOption={serviceContract}
                            // placeHolder="Services"
                          />
                        </div>
                      </div>

                      <div className='w-full'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Created Date Range
                        </label>
                        <DateRangePickerNew
                          isClearable={false}
                          handleCancel={handleCancelButton}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              setFromDate(obj.fromDate);
                              setToDate(obj.toDate);
                            }
                          }}
                          fromDate={fromDate}
                          toDate={toDate}
                        />
                      </div>

                      <div className='w-full'>
                        <div
                          className='flex d-flex justify-end pt-4 space-x-2'
                          style={{ marginTop: "2.5px" }}
                        >
                          <CustomButton
                            Icon={<BsFilterCircle />}
                            clickHandler={() => searchFilterDataRequest(false)}
                            label='Search'
                            // showLoading={caseNotesHistoryLoading || false}
                            isDisabled={caseNotesHistoryLoading || false}
                          />
                          <CustomButton
                            isDisabled={caseNotesHistoryLoading || false}
                            Icon={<FiDelete />}
                            clickHandler={() => {
                              setSelectedClient(null);
                              setserviceContract(null);
                              setFromDate(null);
                              setToDate(null);
                              searchFilterDataRequest(true);
                            }}
                            label='Clear Filter'
                            cssClass='whitespace-nowrap'
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className='sm:px-6 lg:px-8' style={{ marginTop: "0" }}>
                  {caseNotesHistoryLoading ? (
                    <div
                      className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                    >
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </div>
                  ) : (
                    <CaseNotesHistoryTable printData={tableData} />
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default CaseNotesHistoryMainPage;
