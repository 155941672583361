import React, { useEffect, useContext } from "react";
import { BiBook } from "react-icons/bi";
import { RiFileHistoryLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { classNames } from "Utils/utils";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import Header from "../../../../components/common/Header/Header";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import { LoginContext } from "../../../../helpers/LoginContext";

const CosSettings = (props) => {
  const { emptyNewData } = props;

  const { sideBarPadding } = useContext(LoginContext);

  useEffect(() => {
    return () => emptyNewData();
  }, []);

  const cards = [
    {
      name: "Task Categories",
      role: "",
      href: "/cos/settings/category-setting",
      icon: RiFileHistoryLine,
      bgColor: "#00D1B7",
      for: "cos",
    },
    {
      name: "Note Contact Type",
      role: "",
      href: "/cos/settings/contact-setting",
      icon: BiBook,
      bgColor: "#FFCC00",
      for: "cos",
    },
    {
      name: "Task Priorities",
      role: "",
      href: "/cos/settings/priority-setting",
      icon: RiFileHistoryLine,
      bgColor: "#00D1B7",
      for: "cos",
    },
  ];

  const navigate = useNavigate();
  return (
    <div>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title='Settings'
            breadcrumb={
              <Breadcrumb
                pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.COS_SETTING)}
              />
            }
          />

          <section className='container mx-auto sm:px-6 lg:px-8 mt-3 '>
            <div className='grid grid-cols-3 gap-3 mt-3'>
              {cards.map((card, index) => (
                <div
                  onClick={() => {
                    if (card.href) navigate(`${card.href}`);
                  }}
                  role='button'
                  tabIndex={0}
                  key={index}
                  className='cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 col-span-1'
                >
                  <div
                    className={classNames(
                      "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                    )}
                    style={{ backgroundColor: `${card.bgColor}` }}
                  >
                    <card.icon
                      className={classNames("flex-shrink-0 h-5 w-5 text-white")}
                      aria-hidden='true'
                    />
                  </div>
                  <div className='flex-1 min-w-0'>
                    <div className='focus:outline-none'>
                      <span className='absolute inset-0' aria-hidden='true' />
                      <p className='text-sm font-medium text-gray-900 mt-3'>
                        {card.name}
                      </p>
                      <p className='text-sm text-gray-500 truncate mb-3'>
                        {card.role}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CosSettings;
