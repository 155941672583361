import React, { useEffect, useState, useContext } from "react";
import Select from "../../../../components/common/Select/Select";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import InputField from "../../../../components/common/Input/Input";
import { checkEmail, checkPhoneNo } from "../../../../Utils/FormValidation";
import {
  APPLICANT_ASSESSMENT_STATUS,
  statusOption,
  // portalOption,
  employeeTypeOption,
  SalarySlabOptions,
  portalTypeOptions,
  internalRoleOption,
  REGEX_PHONE_NUMBER,
} from "../../../../Constants/constant";
import { toast } from "react-toastify";
import { checkPasswordValidity, hasBlankSpaces } from "../../../../Utils/utils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { apiPostRequest } from "../../../../helpers/Requests";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import { LoginContext } from "../../../../helpers/LoginContext";
import {
  HRM_BREADCRUMB,
  HRM_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import CustomButton from "components/common/Button/CustomButton";
import api from "store/services/auth.services";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const ApplicantAssessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    const { state } = location;
    if (!state || !state.applicantId || !state.name) {
      navigate("/hrm/all-applicants");
    }
  }, []);

  const [selectedOption, setSelectedOption] = useState(statusOption[0]);

  // const [selectedPortalOption, setSelectedPortalOption] = useState(
  //   portalOption[0]
  // );

  const [selectedRole, setSelectedRole] = useState(null);

  const [SelectedEmployeeTypeOption, setSelectedEmployeeTypeOption] = useState(
    employeeTypeOption[0]
  );

  const [selectedSalarySlabOption, setSelectedSalarySlabOption] = useState(
    SalarySlabOptions[0]
  );

  const [selectInternalRole, setSelectInternalRole] = useState(
    internalRoleOption[0]
  );

  const handleChange = (status) => {
    setSelectedOption(status);
  };

  // const handlePortalAccess = (status) => {
  //   setSelectedPortalOption(status);
  // };

  const [roleOptions, setRoleOptions] = useState([]);
  const [fetchRoleLoader, setFetchRoleLoader] = useState(false);

  useEffect(() => {
    if (selectedRole && getError("role")) {
      setErrors({ ...errors, role: null });
    }
  }, [selectedRole]);

  useEffect(() => getRoles(), []);

  const getRoles = async () => {
    try {
      setFetchRoleLoader(true);
      const response = await api.roleAndPermissionList({
        type: portalTypeOptions[1].value,
      });
      if (response && response.data) {
        setRoleOptions(
          response?.data?.map((x) => ({ label: x.name, value: x.id }))
        );
      }
      setFetchRoleLoader(false);
    } catch (err) {
      setFetchRoleLoader(false);
      console.log("err", err);
    }
  };

  const [assessmentDate, setAssessmentDate] = useState("");
  const [question, setQuestion] = useState("");
  const [notes, setNotes] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rejectNotes, setRejectNotes] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordType, setPasswordType] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  // const setSendEmail(!sendEmail)
  const validateInput = () => {
    const fnError = errors;
    if (!assessmentDate || assessmentDate.length === 0) {
      Object.assign(fnError, { assessmentDate: "Assessment Date is Required" });
    }
    if (!question || question.length === 0) {
      Object.assign(fnError, { question: "This field is required" });
    }
    if (!notes || !notes.length === 0) {
      Object.assign(fnError, { notes: "This field is required" });
    }
    if (
      selectedOption.value === APPLICANT_ASSESSMENT_STATUS.rejected ||
      selectedOption.value === APPLICANT_ASSESSMENT_STATUS.pending
    ) {
      if (!rejectNotes || rejectNotes.length <= 0) {
        Object.assign(fnError, { rejectNotes: "Rejection Notes is Required" });
      }
      Object.assign(fnError, {
        phone: null,
        email: null,
        password: null,
        confirmPassword: null,
      });
    } else {
      if (
        !SelectedEmployeeTypeOption ||
        SelectedEmployeeTypeOption.value.length === 0
      ) {
        Object.assign(fnError, { phone: "Employee Type is Required" });
      }
      if (
        !selectedSalarySlabOption ||
        selectedSalarySlabOption.value.length === 0
      ) {
        Object.assign(fnError, { phone: "Salary Slab is Required" });
      }
      if (!phone || phone.length === 0) {
        Object.assign(fnError, { phone: "Phone Number is Required" });
      }
      if (!email || email.length <= 0 || checkEmail(email)) {
        const inValid = checkEmail(email);
        if (inValid) {
          Object.assign(fnError, { email: "Invalid email" });
        } else {
          Object.assign(fnError, { email: "Email is Required" });
        }
      }
      if (!password || password.length <= 0) {
        Object.assign(fnError, { password: "Password is Required" });
      }
      if (!confirmPassword || confirmPassword.length <= 0) {
        Object.assign(fnError, {
          confirmPassword: "Confirm Password is Required",
        });
      }
      if (!confirmPassword || confirmPassword.length > 16) {
        Object.assign(fnError, {
          confirmPassword: "Password must be 8-16 Characters Long.",
        });
      }
      if (password.length > 0 || confirmPassword.length > 0) {
        if (checkPasswordValidity(password) !== null) {
          Object.assign(fnError, {
            password: checkPasswordValidity(password),
          });
        } else if (password !== confirmPassword) {
          Object.assign(fnError, {
            password: "Password does not match",
            confirmPassword: "Confirm Password does not match",
          });
        }
      }
      Object.assign(fnError, { rejectNotes: null });
    }

    if (selectedRole === null) {
      Object.assign(fnError, {
        role: "Role is required!",
      });
    }

    const isCheckAnyError =
      Object.values(fnError).filter((x) => x !== null).length > 0 ||
      checkEmail(email);

    if (isCheckAnyError) {
      setErrors({ ...fnError });
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    if (isUserGracePeriodOver) {
      return;
    }

    if (validateInput()) {
      return;
    }

    const params = {
      name: location.state.name,
      assessment_date: assessmentDate,
      questions: question,
      notes: notes,
      status: selectedOption.label,
      send_email: sendEmail,
    };

    if (selectedOption.value === APPLICANT_ASSESSMENT_STATUS.pending) {
      handleSubmitStatus(params);
    } else if (selectedOption.value === APPLICANT_ASSESSMENT_STATUS.hired) {
      Object.assign(params, {
        // portal_access: selectedPortalOption.label,
        portal_access: "Yes",
        role_id: selectedRole.value,
        phone: phone,
        email: email,
        password: password,
        employee_type: SelectedEmployeeTypeOption.value,
        salary_slab: selectedSalarySlabOption.value,
        internal_role: selectInternalRole.value,
      });
    } else if (selectedOption.value === APPLICANT_ASSESSMENT_STATUS.rejected) {
      Object.assign(params, {
        rejection_note: rejectNotes,
      });
    }

    handleSubmitStatus(params);
  };

  const getError = (value) => {
    if (!value) return "";
    // return errors[value] && errors[value].length > 0 ? errors[value] : "";

    return errors[value] && errors[value].length > 0 && value !== "phone"
      ? `Field is Required`
      : errors[value] && errors[value].length > 0 && value === "phone"
      ? // ? "Phone No required & must start with 04, 1300 or 1800 & 10 digits"
        "Please enter a valid phone number!"
      : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (name === "email" && value && value.length > 0) {
      const inValid = checkEmail(value);
      if (inValid) {
        setErrors({ ...errors, email: "Invalid email" });
      } else {
        setErrors({ ...errors, email: null });
      }
      return;
    } else if (
      (name === "password" || name === "confirmPassword") &&
      value &&
      value.length > 0
    ) {
      if (password.length < 8) {
        setErrors({ ...errors, password: "Password must be 8 characters" });
      } else if (password !== confirmPassword) {
        setErrors({
          ...errors,
          password: "Password does not match",
          confirmPassword: "Confirm Password does not match",
        });
      } else {
        setErrors({
          ...errors,
          password: null,
          confirmPassword: null,
        });
      }
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }

    if (name === "phone" && value.length > 0) {
      const inValidPhoneNo = checkPhoneNo(value);
      if (inValidPhoneNo) {
        setErrors({ ...errors, phone: "phone no must start with 0" });
      } else {
        setErrors({ ...errors, phone: null });
      }
    }
  };

  const handleSubmitStatus = async (data) => {
    if (!location.state.applicantId) return;
    setIsLoading(true);
    try {
      const res = await apiPostRequest(
        `/hrm/applicant-assessments?applicant_id=${location.state.applicantId}`,
        data
      );
      if (res.status === 200) {
        setIsLoading(false);
        handleClear();
        toast.success("Assessment Submitted Successfully");
        setTimeout(() => navigate("/hrm/all-applicants"), 1200);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toast.error(err?.message ?? "Something went wrong!");
    }
  };

  const handleClear = () => {
    setSelectedOption(statusOption[0]);
    // setSelectedPortalOption(portalOption[0]);
    setSelectedRole(null);
    setSelectedEmployeeTypeOption(employeeTypeOption[0]);
    setSelectedSalarySlabOption(SalarySlabOptions[0]);
    setAssessmentDate("");
    setQuestion("");
    setNotes("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setRejectNotes("");
    setErrors({});
  };

  return (
    <div className='min-h-full'>
      <SideBar classes='bg-gradient' />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        } flex flex-col fullScreenHeight`}
      >
        <main className='flex-1 bg-gray-50'>
          {/* <Header 
            title="Applicant Assessment" 
            handleClick={() => navigate("/hrm/applicant-profile")} 
            btnLabel="Profile" 
            isButton
          /> */}
          <div className='items-center flex mb-5 border-b border-gray-200 px-4 py-4 sm:flex sm:items-center h-auto sm:justify-between sm:px-6 lg:px-8 sticky top-0 bg-gray-50 z-[9]'>
            <div className='flex-1 min-w-0'>
              <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                Applicant Assessment
              </h1>

              <div className='mt-2'>
                <Breadcrumb
                  state={location.state}
                  pages={HRM_BREADCRUMB(
                    HRM_BREADCRUMB_PAGES.ASSESSMENT_APPLICANT
                  )}
                />
              </div>
            </div>

            {/* Profile link */}
            {roleAccess(
              [],
              PERMISSION_KEYS.HRM,
              PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW
            ) && (
              <div className='flex sm:mt-0 sm:ml-4'>
                <Link
                  to='/hrm/applicant-profile'
                  state={{
                    applicantId: location.state
                      ? location.state.applicantId
                      : "",
                  }}
                  className='cursor-pointer mr-2 flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
                >
                  Profile
                </Link>
              </div>
            )}
          </div>

          <div className='flex'>
            {fetchRoleLoader ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='w-full'>
                <div className='max-w-7xl mx-auto'>
                  <div className='md:grid md:grid-cols-2 md:gap-6 px-4'>
                    <div className='mt-5 md:mt-0 md:col-span-2'>
                      {/* <form action="#" method="POST"> */}
                      <div className='shadow sm:rounded-md sm:overflow-hidden'>
                        <div className='px-4 py-5 bg-white sm:p-6'>
                          <div className='grid grid-cols-6 gap-6'>
                            <div className='col-span-6 sm:col-span-3'>
                              <InputField
                                isDisabled={true}
                                readOnly={true}
                                label='Applicant Name'
                                type='text'
                                handleChange={() => {}}
                                value={
                                  location.state ? location.state.name : ""
                                }
                                name='applicantName'
                              />
                            </div>

                            <div className='col-span-6 sm:col-span-3'>
                              <InputField
                                value={assessmentDate}
                                label='Assessment Date'
                                // min={moment().format("DD-MM-YYYY")}
                                type='date'
                                handleChange={setAssessmentDate}
                                name='assessmentDate'
                                ErrorMessage={getError("assessmentDate")}
                                blurHandler={handleBlur}
                              />
                            </div>

                            <div className='col-span-6 sm:col-span-6'>
                              <label
                                htmlFor='interview-questions'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Interview Questions
                              </label>
                              <textarea
                                onBlur={(e) => handleBlur(e)}
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                name='question'
                                rows={3}
                                className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md overflow-y-auto'
                              />
                              {getError("question") && (
                                <span
                                  className='capitalize'
                                  style={{
                                    fontSize: "13px",
                                    color: "red",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {getError("question")}
                                </span>
                              )}
                            </div>
                            <div className='col-span-6 sm:col-span-6'>
                              <label
                                htmlFor='interview-questions'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Notes
                              </label>
                              <textarea
                                name='notes'
                                onBlur={(e) => handleBlur(e)}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                rows={3}
                                className='mt-1 overflow-y-auto focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                              />
                              <div style={{ display: "grid" }}>
                                {getError("notes") && (
                                  <span
                                    className='capitalize'
                                    style={{
                                      fontSize: "13px",
                                      color: "red",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {getError("notes")}
                                  </span>
                                )}
                                <span>
                                  Please enter notes. These will be shared with
                                  the applicant.
                                </span>
                              </div>
                            </div>
                            <div className='col-span-6 sm:col-span-6'>
                              <label
                                htmlFor='interview-questions'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Status
                              </label>
                              <Select
                                isSearch={false}
                                data={statusOption}
                                selectedOption={selectedOption}
                                // placeHolder="Select Status"
                                handleChange={handleChange}
                              />
                            </div>
                            {selectedOption &&
                            selectedOption.value ===
                              APPLICANT_ASSESSMENT_STATUS.hired ? (
                              <>
                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor='interview-questions'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Role
                                  </label>
                                  <Select
                                    isDisabled={fetchRoleLoader}
                                    loading={fetchRoleLoader}
                                    isSearch={false}
                                    data={roleOptions}
                                    selectedOption={selectedRole}
                                    handleChange={setSelectedRole}
                                    errorMessage={
                                      getError("role") === ""
                                        ? ""
                                        : "Role is required!"
                                    }
                                  />
                                </div>

                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor='interview-questions'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Internal Role
                                  </label>
                                  <Select
                                    isSearch={false}
                                    data={internalRoleOption}
                                    selectedOption={selectInternalRole}
                                    // placeHolder="Select Status"
                                    handleChange={(data) =>
                                      setSelectInternalRole(data)
                                    }
                                  />
                                </div>

                                {/* <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor='interview-questions'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Allow Portal Access
                                  </label>
                                  <Select
                                    isSearch={false}
                                    data={portalOption}
                                    selectedOption={selectedPortalOption}
                                    // placeHolder="Select"
                                    handleChange={handlePortalAccess}
                                  />
                                </div> */}
                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor='interview-questions'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Employee Type
                                  </label>
                                  <Select
                                    isSearch={false}
                                    data={employeeTypeOption}
                                    selectedOption={SelectedEmployeeTypeOption}
                                    // placeHolder="Select EMployee Type"
                                    handleChange={(data) =>
                                      setSelectedEmployeeTypeOption(data)
                                    }
                                  />
                                </div>
                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor='interview-questions'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Salary Slab
                                  </label>
                                  <Select
                                    isSearch={false}
                                    data={SalarySlabOptions}
                                    selectedOption={selectedSalarySlabOption}
                                    // placeHolder="Select Salary Slab"
                                    handleChange={(data) =>
                                      setSelectedSalarySlabOption(data)
                                    }
                                  />
                                </div>
                                <div className='col-span-6 sm:col-span-3'>
                                  <InputField
                                    maxLength='10'
                                    label='Phone Number'
                                    pattern={REGEX_PHONE_NUMBER}
                                    type='number'
                                    handleChange={(e) => {
                                      const sanitizedValue = e.replace(
                                        /\D/g,
                                        ""
                                      );
                                      const formattedValue =
                                        sanitizedValue.slice(0, 10);
                                      setPhone(formattedValue);
                                    }}
                                    value={phone}
                                    name='phone'
                                    ErrorMessage={getError("phone")}
                                    blurHandler={handleBlur}
                                    // cssClass='mt-0'
                                  />
                                </div>

                                <div className='col-span-6 sm:col-span-3'>
                                  <InputField
                                    label='Official Email Address'
                                    type='email'
                                    handleChange={setEmail}
                                    value={email}
                                    name='email'
                                    ErrorMessage={getError("email")}
                                    blurHandler={handleBlur}
                                  />
                                </div>

                                <div className='col-span-6 sm:col-span-3'>
                                  <span
                                    className='flex justify-end pr-2 relative top-[50px]'
                                    onClick={() =>
                                      setPasswordType(!passwordType)
                                    }
                                  >
                                    {/* <AiFillEye className="relative top-[50px] " /> */}
                                    <div className=' z-10'>
                                      {passwordType ? (
                                        <AiFillEyeInvisible className='relative top-[0px] text-blue-dark' />
                                      ) : (
                                        <AiFillEye className='relative top-[0px] text-blue-dark' />
                                      )}
                                    </div>
                                  </span>
                                  <InputField
                                    label='Password'
                                    type={
                                      passwordType === true
                                        ? "password"
                                        : "text"
                                    }
                                    handleChange={setPassword}
                                    value={password}
                                    name='password'
                                    ErrorMessage={getError("password")}
                                    blurHandler={handleBlur}
                                  />
                                </div>
                                <div className='col-span-6 sm:col-span-3'>
                                  <span
                                    className=' flex justify-end pr-2 relative top-[50px] '
                                    onClick={() =>
                                      setPasswordType(!passwordType)
                                    }
                                  >
                                    {/* <AiFillEye className="relative top-[50px] " /> */}
                                    <div className=' z-10'>
                                      {passwordType ? (
                                        <AiFillEyeInvisible className='relative top-[0px] text-blue-dark' />
                                      ) : (
                                        <AiFillEye className='relative top-[0px] text-blue-dark' />
                                      )}
                                    </div>
                                  </span>
                                  <InputField
                                    label='Confirm Password'
                                    type={passwordType ? "password" : "text"}
                                    handleChange={setConfirmPassword}
                                    value={confirmPassword}
                                    name='confirmPassword'
                                    ErrorMessage={
                                      getError("confirmPassword")
                                      // === "confirmPassword Is Required"
                                      //   ? "Confirm Password Is Required"
                                      //   : getError("confirmPassword")
                                    }
                                    blurHandler={handleBlur}
                                  />
                                </div>

                                <div className='col-span-6 sm:col-span-6'>
                                  <div className='col-span-2 space-x-4'>
                                    <label className=' text-start did-floating-label'>
                                      Notify via Email
                                    </label>
                                    <input
                                      value={sendEmail}
                                      type='checkbox'
                                      onClick={() => setSendEmail(!sendEmail)}
                                      className='rounded '
                                    />
                                  </div>
                                </div>
                              </>
                            ) : selectedOption &&
                              selectedOption.value ===
                                APPLICANT_ASSESSMENT_STATUS.rejected ? (
                              <div className='col-span-6 sm:col-span-6'>
                                <label
                                  htmlFor='interview-questions'
                                  className='block text-sm font-medium text-gray-700'
                                >
                                  Rejection Notes
                                </label>
                                <textarea
                                  name='rejectNotes'
                                  onBlur={(e) => handleBlur(e)}
                                  onChange={(e) =>
                                    setRejectNotes(e.target.value)
                                  }
                                  value={rejectNotes}
                                  rows={3}
                                  className='mt-1 overflow-y-auto focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                />
                                <div style={{ display: "grid" }}>
                                  {getError("rejectNotes") && (
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "red",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {getError("rejectNotes")}
                                    </span>
                                  )}
                                  <span>
                                    Please enter in detail the rejection notes.
                                    These will be shared with the applicant.
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 space-x-4'>
                          <CustomButton
                            label='Cancel'
                            variant='danger'
                            clickHandler={() => {
                              if (
                                roleAccess(
                                  [],
                                  PERMISSION_KEYS.HRM,
                                  PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW
                                )
                              ) {
                                navigate(`/hrm/applicant-profile`, {
                                  state: {
                                    applicantId: location.state
                                      ? location.state.applicantId
                                      : "",
                                  },
                                });
                              } else {
                                navigate(`/hrm/all-applicants`);
                              }
                            }}
                          />
                          <CustomButton
                            showLoading={isLoading}
                            isDisabled={isLoading || isUserGracePeriodOver}
                            clickHandler={handleSubmit}
                            label='Submit'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ApplicantAssessment;
