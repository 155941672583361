import React, { useEffect, useState, useContext } from "react";
import SideBar from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CaseNotesTable from "./caseNotesReportTable";
import FundingReportTable from "./fundingReportTable";
import SelectOption from "components/common/Select/Select";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import api from "store/services/cos.services";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import { BsFilterCircle } from "react-icons/bs";
import { FiDelete } from "react-icons/fi";

const ReportingMainPage = (props) => {
  const {
    tableDataOfCaseNotesForReporting,
    tableDataForReporting,
    tableDataForReportingLoading,
    getTableDataForReporting,
    supportCoordinator,
    getSupportCoordinatoreList,
    getClientList,
    clientsList,
    caseNotesHistoryList,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [printData, setPrintData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [serviceContract, setserviceContract] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [report, setReport] = useState("funding");
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    getClientList();
    getSupportCoordinatoreList();
  }, []);

  useEffect(() => searchFilterDataRequest(true), [report]);

  const searchFilterDataRequest = (isClearAll = false) => {
    getTableDataForReporting({
      cos_id: isClearAll
        ? "all"
        : serviceContract
        ? serviceContract.value
        : "all",
      pt_id: isClearAll ? "all" : selectedClient ? selectedClient.value : "all",
      from_date: isClearAll ? "all" : fromDate ? fromDate : "all",
      to_date: isClearAll ? "all" : toDate ? toDate : "all",
      type: report,
    });
  };

  useEffect(() => {
    if (caseNotesHistoryList && caseNotesHistoryList.length > 0) {
      setPrintData(caseNotesHistoryList);
      setTableData(caseNotesHistoryList);
    }
  }, [caseNotesHistoryList]);

  const fetchReport = async (data) => {
    try {
      setIsLoading(true);
      const res = await api.getCosFundingReportApi(data);
      if (res) {
        window.open(res.report.download_url, "_blank");
      }
      setIsLoading(false);
    } catch (Err) {
      setIsLoading(false);
      console.log(Err);
    }
  };

  const handleFileDownLoad = (value) => {
    if (value) {
      fetchReport({
        cos_id: serviceContract ? serviceContract.value : "all",
        pt_id: selectedClient ? selectedClient.value : "all",
        from_date: fromDate ? fromDate : "all",
        to_date: toDate ? toDate : "all",
        type: report,
        reportType: value,
      });
    }
  };

  useEffect(() => {
    if (clientsList && clientsList) {
      clientsList.unshift({ label: "All", value: "all" });
    }
  }, [clientsList]);

  useEffect(() => {
    if (supportCoordinator && supportCoordinator) {
      supportCoordinator.unshift({ label: "All", value: "all" });
    }
  }, [supportCoordinator]);

  const handleCancelButton = () => {
    setFromDate(null);
    setToDate(null);
  };

  return (
    <div className='min-h-full bg-red-500'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Reporting'
            breadcrumb={
              <Breadcrumb
                pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.COS_REPORTING)}
              />
            }
          />
          <div className='max-w-7xl mx-auto'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <div className='w-[20%]'>
                <label className='text-sm font-medium text-gray-700 text-start'>
                  Select Report
                </label>
                <SelectOption
                  cssClass=''
                  name='report_type'
                  isSearch={false}
                  data={[
                    { value: "funding", label: "Funding" },
                    { value: "case-notes", label: "Case Notes" },
                  ]}
                  selectedOption={[
                    { value: "funding", label: "Funding" },
                    { value: "case-notes", label: "Case Notes" },
                  ].find((x) => x.value === report)}
                  handleChange={(e) => {
                    setReport(e.value);
                  }}
                  // placeHolder="Select One"
                />
              </div>
              <div className='mt-3'>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={loading}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
              </div>
            </div>
            <div>
              <div className='sm:px-6 lg:px-8 pt-0 sticky top-[79px] bg-gray-50 z-[5]'>
                <section>
                  <div className='py-4 grid gap-x-2  sm:grid-cols-1 lg:grid-cols-4 md-grid-cols-4'>
                    <div className='col-span-1'>
                      <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                        Participant
                      </label>
                      <SelectOption
                        isDisabled={tableDataForReportingLoading}
                        name='pt_id'
                        isSearch={true}
                        data={clientsList ? clientsList : []}
                        selectedOption={selectedClient}
                        handleChange={(e) => {
                          setSelectedClient(e);
                        }}
                        // placeHolder="Participant Name"
                      />
                    </div>
                    <div className='col-span-1'>
                      <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                        Support Coordinators
                      </label>
                      <SelectOption
                        isDisabled={tableDataForReportingLoading}
                        name='contact_type_id'
                        isSearch={true}
                        data={supportCoordinator ? supportCoordinator : []}
                        handleChange={(e) => {
                          setserviceContract(e);
                        }}
                        selectedOption={serviceContract}
                        // placeHolder="Services"
                      />
                    </div>
                    <div className='col-span-1'>
                      <label
                        htmlFor='from-date-to-to-date'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Select Created Date Range
                      </label>
                      <DateRangePickerNew
                        isDisabled={tableDataForReportingLoading}
                        isClearable={false}
                        handleCancel={handleCancelButton}
                        handleChange={(obj) => {
                          if (obj && obj.fromDate && obj.toDate) {
                            setFromDate(obj.fromDate);
                            setToDate(obj.toDate);
                          }
                        }}
                        fromDate={fromDate}
                        toDate={toDate}
                      />
                    </div>
                    <div className='col-span-1'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => searchFilterDataRequest(false)}
                          label='Search'
                          showLoading={tableDataForReportingLoading}
                          isDisabled={tableDataForReportingLoading || loading}
                        />
                        <CustomButton
                          isDisabled={tableDataForReportingLoading || loading}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSelectedClient(null);
                            setserviceContract(null);
                            setFromDate(null);
                            setToDate(null);
                            searchFilterDataRequest(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <section className='sm:px-6 lg:px-8 py-4 pt-0'>
                {loading || tableDataForReportingLoading ? (
                  <div
                    className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : report === "funding" ? (
                  <FundingReportTable fundingData={tableDataForReporting} />
                ) : (
                  report === "case-notes" && (
                    <CaseNotesTable
                      caseNoteData={tableDataOfCaseNotesForReporting}
                    />
                  )
                )}
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ReportingMainPage;
