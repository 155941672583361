import React, { useCallback, useEffect, useState } from "react";
import InputField from "components/common/Input/Input";
import SelectOption from "components/common/Select/Select";
import { SHIFT_TIME_OPTIONS } from "Constants/constant";
import {
  calculateTotalHoursAndMinutes,
  getShiftLength,
  parseBooleanVal,
  validDate,
} from "Utils/utils";
import moment from "moment";
import { Controller } from "react-hook-form";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import ActiveHours from "./ActiveHours";
import TimeInput from "react-time-picker-input";

const TimesheetBreakdown = ({
  watchFormData,
  register,
  setValue,
  clearErrors,
  errors,
  item,
  index,
  itemNumber,
  selectedTimeSheet,
  suggestedItemNumber,
  isNonBillable,
  disabledAllComponent = false,
  spRosteringSetting,
  serviceCategory,
  control,
  totalBreakDown,
  setError,
  totalItemsDuration
}) => {
  const isFirstBreakdown = index === 0;
  const isLastBreakdown = index === totalBreakDown - 1;
  const firstStartTime = watchFormData?.start_time_0 || "";
  const lastEndTime = watchFormData?.[`end_time_${totalBreakDown - 1}`] || "";
  const [selectedItemNumber, setSelectedItemNumber] = useState("");
  const [activeSleepoverIndex, setActiveSleepoverIndex] = useState(null);



  
  useEffect(() => {
    if (item && item.start_time) {
      setValue(
        `start_time_${index}`,
        moment(item?.start_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      );
    }
    if (item && item.end_time) {
      setValue(
        `end_time_${index}`,
        moment(item.end_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      );
      if (item?.invoice_item_id) {
        setValue(`invoice_item_id_${index}`, item?.invoice_item_id);
      }
    }
  }, [item, index, setValue]);

  const val = watchFormData[`invoice_item_id_${index}`];
  useEffect(() => {
    if (item && val) {
      const selectedItem = itemNumber?.find((item) => item.value === val);
      if (selectedItem) {
        setSelectedItemNumber(selectedItem);
      }
    }
  }, [itemNumber, val, index, item, watchFormData]);

  useEffect(() => {
    setValue(`invoice_item_name_${index}`, selectedItemNumber.number);
  }, [selectedItemNumber.number, index, setValue]);

  const NDIS_RATE = [
    {
      label: "Apply Alternative Rate",
      value: "alternate",
    },
    {
      label: selectedItemNumber ? selectedItemNumber?.price : "",
      value: selectedItemNumber ? selectedItemNumber?.price : "",
    },
  ];

  useEffect(() => {
    if (watchFormData[`invoice_ndis_rate_${index}`] !== "alternate") {
      setValue(
        `rate_amount_${index}`,
        watchFormData[`invoice_ndis_rate_${index}`]
      );
    } else if (watchFormData[`invoice_ndis_rate_${index}`] === "alternate") {
      if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
        setValue(
          `rate_amount_${index}`,
          spRosteringSetting?.default_alternate_rate || 0
        );
      } else {
        setValue(`rate_amount_${index}`, item?.ndis_rate);
      }
    }
  }, [watchFormData[`invoice_ndis_rate_${index}`]]);

  useEffect(() => {
    if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
      setValue(`invoice_ndis_rate_${index}`, "alternate");
      setValue(
        `rate_amount_${index}`,
        spRosteringSetting?.default_alternate_rate || 0
      );
    }
  }, [spRosteringSetting]);

  const getAppliedAmount = () => {
    if (
      watchFormData &&
      watchFormData[`invoice_ndis_rate_${index}`] &&
      selectedTimeSheet &&
      selectedTimeSheet?.participants &&
      watchFormData[`invoice_ndis_rate_${index}`] !== "alternate"
    ) {
      const result =
        watchFormData[`invoice_ndis_rate_${index}`] /
        selectedTimeSheet?.participants?.length;

      return result.toFixed(2);
    } else if (watchFormData[`invoice_ndis_rate_${index}`] === "alternate") {
      const result2 =
        watchFormData[`rate_amount_${index}`] /
        selectedTimeSheet?.participants?.length;

      return !isNaN(result2.toFixed(2)) ? result2.toFixed(2) : "";
    }
  };

  useEffect(() => {
    if (watchFormData && isNonBillable) {
      setValue(`rate_amount_${index}`, 0);
      setValue(`invoice_ndis_rate_${index}`, 0);
    }
  }, [isNonBillable]);

 const calculateTotalBreakdownTime = () => {
    let totalMinutes = 0;
  for (let i = 0; i < totalBreakDown; i++) {
      const startTime = watchFormData[`start_time_${i}`];
      const endTime = watchFormData[`end_time_${i}`];
  if (startTime && endTime) {
        const startMoment = moment(startTime, "HH:mm");
        let endMoment = moment(endTime, "HH:mm");
  
        // If the end time is earlier than the start time, it's on the next day
        if (endMoment.isBefore(startMoment)) {
          endMoment = endMoment.add(1, 'days'); // Add 1 day to the end time
        }
        const duration = moment.duration(endMoment.diff(startMoment));
        totalMinutes += duration.asMinutes(); 
      }
    }
 
    return totalMinutes;
  };

  const validateTimeRange = () => {
    const totalBreakdownMinutes = calculateTotalBreakdownTime();
    const totalDurationMinutes = moment
      .duration(totalItemsDuration)
      .asMinutes();
     
    if (totalBreakdownMinutes > totalDurationMinutes || totalBreakdownMinutes < totalDurationMinutes ) {
      return `The total duration of all breakdowns combined must be exactly ${totalItemsDuration?.split(":")[0]} hours and ${totalItemsDuration?.split(":")[1]} minutes in total.`;
    }

    return true;
  };
  const handleSleepoverToggle = (value, idx) => {
    if (value) {
   
      setActiveSleepoverIndex(idx);
      for (let i = 0; i < totalBreakDown; i++) {
        setValue(`sleepover_${i}`, i === idx);
      }
    } else {
    
      setActiveSleepoverIndex(null);
      for (let i = 0; i < totalBreakDown; i++) {
        setValue(`sleepover_${i}`, false);
      }
    }
  };

  useEffect(() => {

    const activeIndex = Array.from({ length: totalBreakDown }).findIndex(
      (_, i) => watchFormData[`sleepover_${i}`]
    );
    setActiveSleepoverIndex(activeIndex >= 0 ? activeIndex : null);
  }, [watchFormData, totalBreakDown]);


  return (
    <div className='border border-gray-200 py-7 px-6 rounded-lg mt-4'>
      {serviceCategory?.name === "Self-Care" && watchFormData?.sleepover && (
        <>
          <div className='flex items-center gap-x-4'>
            <label className=' text-sm font-semibold mb-1'>Sleepover</label>
            <div>
              <Controller
                name={`sleepover_${index}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchWithIcon
                  disabled={activeSleepoverIndex !== null && activeSleepoverIndex !== index}
                    onChange={(value) => {
                      field.onChange(value);
                      handleSleepoverToggle(value, index)
                  
                    }}
                    checked={field.value}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
    
 
     <div className='flex items-start border-gray-200 border-b py-5'>
        <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
          Scheduled Start/Finish.
        </label>
        <div className='flex items-start w-full space-x-2'>
          <div className='w-[45%]'>
            <div
              style={{
                pointerEvents:isFirstBreakdown  ? "none" : "auto",
                opacity:isFirstBreakdown ? 0.6 : 1,
              }}
            >
              <TimeInput
                {...register(`start_time_${index}`, {
                  required: false,

                  validate: (value) =>
                   !isFirstBreakdown
                      ? validateTimeRange(value, "start")
                      : true,
                })}
                className='did-floating-input'
                hour12Format={true}
                eachInputDropdown={true}
                manuallyDisplayDropdown={true}
                onChange={(e) => {
                  const isValid =
                  !isFirstBreakdown && validateTimeRange(e, "start");

                  if (isValid) {
                    setValue(`start_time_${index}`, e);
                  }
                }}
                value={watchFormData[`start_time_${index}`] || ""}
              />

              {!isFirstBreakdown && validateTimeRange(
                watchFormData[`start_time_${index}`],
                "start"
              ) && (
                <p className='text-red-600 text-sm'>
                  {validateTimeRange(
                    watchFormData[`start_time_${index}`],
                    "start"
                  )}
                </p>
              )}
            </div>
          
          </div>
          <div className='w-[10%] text-center'>To</div>
          <div className='w-[45%]'>
            <div
              style={{
                pointerEvents:isLastBreakdown ? "none" : "auto",
                opacity: isLastBreakdown ? 0.6 : 1,
              }}
            >
              <TimeInput
                {...register(`end_time_${index}`, {
                  required: false,
                  validate: (value) => {
                    const timeValidation = !isLastBreakdown
                      ? validateTimeRange(value, "end")
                      : true;
                    if (timeValidation !== true) {
                      return timeValidation;
                    }

                    const shiftLengthValidation = getShiftLength(
                      watchFormData[`start_time_${index}`],
                      value,
                      false,
                      true
                    );
                    if (shiftLengthValidation === "00:00") {
                      return "Shift can't be zero hours & minutes!";
                    }

                    return true;
                  },
                })}
                className='did-floating-input'
                hour12Format={true}
                eachInputDropdown={true}
                manuallyDisplayDropdown={true}
                onChange={(e) => {
                  const isValid =
                    !isLastBreakdown && validateTimeRange(e, "end");

                  if (isValid) {
                    setValue(`end_time_${index}`, e);
                  }
                }}
                value={watchFormData[`end_time_${index}`] || ""}
              />
              {!isLastBreakdown && validateTimeRange(watchFormData[`end_time_${index}`], "end") && (
                <p className='text-red-600 text-sm' style={{ textTransform: "none" }}>
                  {validateTimeRange(watchFormData[`end_time_${index}`], "end")}
                </p>
              )}
            </div>
           
          </div>
        </div>
      </div>
      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            NDIS Item Name<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <SelectOption
              isDisabled={disabledAllComponent}
              name={`invoice_item_id_${index}`}
              data={itemNumber}
              cssClass='w-full'
              isRequired={true}
              isSearch={false}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedOption={
                (itemNumber && watchFormData[`invoice_item_id_${index}`]) ||
                item?.invoice_item_id
                  ? itemNumber?.find(
                      (x) =>
                        x.value === watchFormData[`invoice_item_id_${index}`]
                    )
                  : suggestedItemNumber
                  ? itemNumber?.find((x) => x.value === suggestedItemNumber?.id)
                  : ""
              }
              // handleChange={()=>{
              //   setValue(`invoice_item_id_active_hours${index}`,"")
              //   setValue(`invoice_item_name_active_hours${index}`,"")
              //   setValue(`rate_amount_active_hours${index}`,"")
              //   setValue(`invoice_ndis_rate_active_hours${index}`,"")
                
              // }}
              //   onChange={handleItemNumberChange}
            />
          </div>
        </div>
      </div>

      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            NDIS Item Number<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <InputField
              isDisabled={disabledAllComponent}
              readOnly={true}
              cssClass='mt-0 h-40-important'
              inputWidth='w-full'
              name={`invoice_item_name_${index}`}
              value={selectedItemNumber.number}
              isRequired={true}
              register={register}
            />
          </div>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Hourly Rate<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <SelectOption
              isDisabled={isNonBillable || disabledAllComponent}
              data={NDIS_RATE}
              name={`invoice_ndis_rate_${index}`}
              cssClass='w-full'
              isRequired={true}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedOption={
                watchFormData[`invoice_ndis_rate_${index}`] &&
                NDIS_RATE?.find(
                  (x) => x.value === watchFormData[`invoice_ndis_rate_${index}`]
                )
              }
              //   onChange={handleItemNumberChange}
            />
          </div>
        </div>
      </div>
      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Rate Amount <span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            {watchFormData?.[`invoice_ndis_rate_${index}`] === "alternate" ? (
              <InputField
                isDisabled={watchFormData?.non_billable || disabledAllComponent}
                cssClass='mt-0 h-40-important'
                inputWidth='w-full'
                setValue={setValue}
                // value={watchFormData?.rate_amount}
                name={`rate_amount_${index}`}
                isRequired={true}
                register={register}
                type='number'
                step='0.01'
              />
            ) : (
              <InputField
                isDisabled={true || disabledAllComponent}
                cssClass='mt-0 h-40-important'
                inputWidth='w-full'
                name={`rate_amount_${index}`}
                isRequired={true}
                register={register}
                setValue={setValue}
              />
            )}
          </div>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Applied Rate
          </label>
          <div className='w-full'>
            <InputField
              isDisabled={true || disabledAllComponent}
              cssClass='mt-0 h-40-important'
              inputWidth='w-full'
              name={`applied_rate_${index}`}
              value={
                watchFormData[`invoice_ndis_rate_${index}`] &&
                getAppliedAmount()
              }
            />
          </div>
        </div>
      </div>
      {watchFormData?.[`sleepover_${index}`] &&
        watchFormData?.sleepover &&
        serviceCategory?.name === "Self-Care" && (
          <ActiveHours
            register={register}
            watchFormData={watchFormData}
            index={index}
            item={item}
            setValue={setValue}
            clearErrors={clearErrors}
            itemNumber={itemNumber}
            suggestedItemNumber={suggestedItemNumber}
            NDIS_RATE={NDIS_RATE}
            getAppliedAmount={getAppliedAmount}
            selectedTimeSheet={selectedTimeSheet}
            disabledAllComponent={disabledAllComponent}
            isNonBillable={isNonBillable}
            totalBreakdownLength={calculateTotalHoursAndMinutes(
              watchFormData[`start_time_${index}`],
              watchFormData[`end_time_${index}`]
            )}
            errors={errors}
            setError={setError}
          alreadySelectedItemNumber={watchFormData[`invoice_item_id_${index}`]}
          />
        )}
    </div>
  );
};

export default TimesheetBreakdown;
