import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import * as FileSaver from "file-saver";
import { toast } from "react-toastify";
import COSInvoiceTabel from "./COSInvoicePreviewTabel";
import SelectOption from "components/common/Select/Select";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import CheckBox from "components/common/checkBox/checkbox";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SelectBox from "components/common/DropDownWithIocns/DropDown";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import { LoginContext } from "helpers/LoginContext";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LOADER_STATUS } from "Constants/constant";
import { convertCosDataToPrint, convertClientDataToPrint } from "Utils/utils";
import swal from "sweetalert";
import api from "store/services/cos.services";

const COSInvoicePreviewPage = (props) => {
  const {
    changeCaseNotesInvoiceStatusSuccess,
    createdCosInvoiceReviewLoading,
    createdCosReviewInvoiceDeliveryStatus,
    createdCosReviewInvoiceClients,
    changeNDISCaseNotesInvoiceStatusLoading,
    changeInvoiceStatus,
    createdCosRiviewInvoice,
    getInvoicesList,
    cosInvoiceLoading,
    getClientsRequest,
    emptyTableData,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);
  const [statusSelect, setStatus] = useState(null);
  const [fileDownloaderLoader, setFileDownloaderLoader] = useState(false);

  const { uuid } = useParams();

  const createdCosRiviewInvoiceToMap = useMemo(() => {
    if (createdCosRiviewInvoice && createdCosRiviewInvoice.length > 0) {
      return createdCosRiviewInvoice;
    }
    return [];
  }, [createdCosRiviewInvoice]);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? createdCosRiviewInvoiceToMap.map((t) => t.id) : []);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (
      createdCosRiviewInvoiceToMap &&
      checkboxes &&
      checkboxes.length === createdCosRiviewInvoiceToMap.length &&
      createdCosRiviewInvoiceToMap.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  useEffect(() => {
    getClientsRequest();
    return () => emptyTableData();
  }, []);

  useEffect(() => {
    if (!cosInvoiceLoading && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [cosInvoiceLoading]);

  const [updatedAction, setUpdatedAction] = useState(null);

  useEffect(() => {
    if (updatedAction && checkboxes.length > 0) {
      handleChangeAction();
    }
  }, [updatedAction]);

  useEffect(() => {
    if (
      createdCosRiviewInvoiceToMap &&
      createdCosRiviewInvoiceToMap.length === 0 &&
      changeCaseNotesInvoiceStatusSuccess === true
    ) {
      navigate("/cos/invoice");
    }
  }, [createdCosRiviewInvoiceToMap]);

  useEffect(() => {
    getInvoicesList({
      id: uuid,
      status: statusSelect ? statusSelect.value : "all",
      pt_id: selectedClient ? selectedClient.value : "all",
    });
  }, [statusSelect, selectedClient]);

  const [clientHoursReport, setClientHoursReport] = useState(false);
  const [cosHoursReport, setCosHoursReport] = useState(false);

  const [printData, setPrintData] = useState(null);

  useEffect(() => {
    if (cosHoursReport) {
      const dataToPrint = convertCosDataToPrint(createdCosRiviewInvoiceToMap);
      setPrintData(dataToPrint);
    }
  }, [cosHoursReport]);

  useEffect(() => {
    if (clientHoursReport) {
      const dataToPrint = convertClientDataToPrint(
        createdCosRiviewInvoiceToMap
      );
      setPrintData(dataToPrint);
    }
  }, [clientHoursReport]);

  const getText = () => {
    if (updatedAction === "revert") {
      return "Are you sure you want to Revert these case notes";
    } else if (updatedAction === "cancelled") {
      return "Are you sure you want to cancel these case notes";
    } else if (updatedAction === "extract_generated") {
      return "Are you sure you want to undo cancel these case notes";
    } else if (updatedAction === "provider_paid") {
      return "Are you sure you want to Change the action to  Provider Paid";
    }
  };

  const handleChangeAction = async () => {
    if (isUserGracePeriodOver) return;
    if (
      updatedAction &&
      checkboxes.length > 0 &&
      (updatedAction === "pdf" || updatedAction === "xlsx")
    ) {
      try {
        setFileDownloaderLoader(true);
        const res = await api.changeActionsClaimInvoiceApi({
          report: updatedAction,
          cos_invoice_ids: checkboxes,
        });

        if (res && res.report) {
          await FileSaver.saveAs(
            res.report.download_url,
            `Invoices-${updatedAction === "xlsx" ? "EXCEL" : "PDF"}-File${
              updatedAction === "xlsx" ? ".xlsx" : ".pdf"
            }`
          );
        }
        setCheckboxes([]);
        setUpdatedAction("");
        setFileDownloaderLoader(false);
      } catch (err) {
        setUpdatedAction("");
        setFileDownloaderLoader(false);
        console.log("err", err);
        toast.error("Something went wrong while downloading!");
      }

      return;
    }

    swal({
      title: "Confirm your action?",
      text: `${getText()}`,
      icon: "",
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,
        // className: `${
        //   updatedAction === "cancelled" ? "bg-blue-600" "
        // }`,
        closeModal: true,
      },

      dangerMode: updatedAction === "cancelled" ? true : false,
    }).then((willDelete) => {
      if (isUserGracePeriodOver) return;
      if (willDelete) {
        if (checkboxes.length > 0) {
          changeInvoiceStatus({
            status: updatedAction && updatedAction,
            cos_invoice_ids: checkboxes,
          });
          setCheckboxes([]);
          setUpdatedAction("");
        }
      } else {
        setCheckboxes([]);
        setUpdatedAction("");
      }
    });
  };

  useEffect(() => {
    if (
      createdCosReviewInvoiceDeliveryStatus &&
      createdCosReviewInvoiceDeliveryStatus
    ) {
      createdCosReviewInvoiceDeliveryStatus.unshift({
        label: "All",
        value: "all",
      });
    }
  }, [createdCosReviewInvoiceDeliveryStatus]);

  useEffect(() => {
    if (createdCosReviewInvoiceClients && createdCosReviewInvoiceClients) {
      createdCosReviewInvoiceClients.unshift({ label: "All", value: "all" });
    }
  }, [createdCosReviewInvoiceClients]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header
            title='Invoice Preview'
            breadcrumb={
              <Breadcrumb
                pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.COS_INVOICE_REVIEW)}
              />
            }
          />
          <div className='max-w-7xl mx-auto bg-white'>
            <div className='flex justify-between sm:px-6 lg:px-8 py-3 items-end bg-white max-w-7xl mx-auto py-2 sticky top-[80px] z-[9]'>
              <div className='flex gap-x-2 w-[70%]'>
                <div className='col-span-1 w-[25%]'>
                  <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                    Participant
                  </label>
                  <SelectOption
                    isClearable={true}
                    isDisabled={
                      createdCosInvoiceReviewLoading ||
                      changeNDISCaseNotesInvoiceStatusLoading
                    }
                    cssClass=''
                    name='client'
                    isSearch={false}
                    data={
                      createdCosReviewInvoiceClients &&
                      createdCosReviewInvoiceClients
                    }
                    selectedOption={selectedClient}
                    handleChange={(e) => {
                      setSelectedClient(e);
                    }}
                    // placeHolder="Participant"
                  />
                </div>
                <div className='col-span-1 w-[30%]'>
                  <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                    Status
                  </label>
                  <SelectOption
                    isClearable={true}
                    isDisabled={
                      createdCosInvoiceReviewLoading ||
                      changeNDISCaseNotesInvoiceStatusLoading
                    }
                    cssClass=''
                    name='sp_cos_id'
                    isSearch={false}
                    data={
                      createdCosReviewInvoiceDeliveryStatus &&
                      createdCosReviewInvoiceDeliveryStatus
                    }
                    selectedOption={statusSelect}
                    handleChange={(e) => {
                      setStatus(e);
                    }}
                    // placeHolder="Status"
                  />
                </div>
              </div>
              <div className='w-56 inline-flex flex-col'>
                <SelectBox
                  disabled={
                    (checkboxes.length > 0 ? false : true) ||
                    isUserGracePeriodOver
                  }
                  setUpdatedAction={setUpdatedAction}
                  setClientHoursReport={setClientHoursReport}
                  setCosHoursReport={setCosHoursReport}
                />
              </div>
            </div>
            <div className='px-2 sm:px-6 lg:px-8 py-3'>
              <div className='flex flex-col'>
                <div className='-my-2 overflow-x-auto'>
                  <div className='min-w-full py-2 align-middle'>
                    <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      <table className='min-w-full divide-y divide-gray-300 sticky capitalize'>
                        <thead className='bg-blue-50 sticky capitalize'>
                          <tr>
                            <th
                              scope='col'
                              className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                            >
                              <CheckBox
                                isDisabled={isUserGracePeriodOver}
                                checked={allSelected}
                                handleChange={(e) => {
                                  if (isUserGracePeriodOver) {
                                    return;
                                  }
                                  if (
                                    createdCosRiviewInvoiceToMap &&
                                    createdCosRiviewInvoiceToMap.length > 0
                                  ) {
                                    setAllSelected(e);
                                    toggleCheckboxes(e);
                                  }
                                }}
                              />
                            </th>

                            <TableHeader isSort={false} label={"Reference"} />
                            <TableHeader isSort={false} label={"Created By"} />
                            <TableHeader isSort={false} label={"Participant"} />
                            <TableHeader isSort={false} label={"Mgmt Style"} />
                            <TableHeader
                              isSort={false}
                              label={"Support Item"}
                            />
                            <TableHeader
                              isSort={false}
                              label={"Support Item Number"}
                            />
                            <TableHeader isSort={false} label={"Status"} />
                            <TableHeader
                              isSort={false}
                              label={"Date Delivered"}
                            />
                            <TableHeader isSort={false} label={"Hours"} />
                            <TableHeader isSort={false} label={"Rate"} />
                            <TableHeader
                              isSort={false}
                              label={"Total Amount"}
                            />
                            <th>
                              <div className='sr-only'>View</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {fileDownloaderLoader ||
                          createdCosInvoiceReviewLoading ||
                          changeNDISCaseNotesInvoiceStatusLoading ? (
                            <tr>
                              <td colSpan={14} className='bg-gray-50 py-2'>
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </td>
                            </tr>
                          ) : createdCosRiviewInvoiceToMap &&
                            createdCosRiviewInvoiceToMap.length > 0 ? (
                            createdCosRiviewInvoiceToMap.map((item, index) => (
                              <COSInvoiceTabel
                                isUserGracePeriodOver={isUserGracePeriodOver}
                                checkboxes={checkboxes}
                                handleCheckboxChange={(e) => {
                                  if (isUserGracePeriodOver) {
                                    return;
                                  }
                                  handleCheckboxChange(e, item.id);
                                }}
                                item={item}
                                key={index}
                              />
                            ))
                          ) : (
                            (!createdCosRiviewInvoiceToMap ||
                              createdCosRiviewInvoiceToMap.length === 0) && (
                              <tr>
                                <td
                                  colSpan={14}
                                  className='py-6 text-center text-red-600'
                                >
                                  No Data Found !
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default COSInvoicePreviewPage;
