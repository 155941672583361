import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";
import { GET_MONTH } from "store/constants";
import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  portalTypeOptions,
  REPORTING_LIMIT,
  SORT_TYPE,
  StatusOptions,
  StatusOptionsReporting,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_WORKER_REPORTS } from "store/services/URL";

import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";

import {
  addMonths,
  exportTOExcel,
  formatDateIntoDMYToDisplay,
} from "Utils/utils";
import api from "store/services/auth.services";

const WorkerListReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);
  const [BirthDate, setBirthDate] = useState(null);

  const [joiningDate, setJoiningDate] = useState(null);
  const [monthOfBorn, setMonthOfBorn] = useState(null);
  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [role, setRole] = useState(null);
  const [roleList, setRoleList] = useState([{ label: "All", value: "all" }]);
  const [suburb, setSuburb] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  useEffect(() => {
    api
      .roleAndPermissionList({
        type: portalTypeOptions[1].value,
      })
      .then((res) => {
        const rolesList = res.data;
        const Roles =
          rolesList &&
          rolesList?.length > 0 &&
          rolesList?.map((role) => ({
            label: `${role?.name}`,
            value: `${role?.name}`,
          }));

        setRoleList([{ label: "All", value: "all" }, ...Roles]);
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  }, []);
  const handleCancelButton = () => {
    setBirthDate(null);
  };

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select date range to get data");
      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_WORKER_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_WORKER_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      dob: isClearAll ? "all" : BirthDate ? BirthDate : "all",

      born_month: isClearAll ? "all" : monthOfBorn ? monthOfBorn?.value : "all",
      suburb: suburb ? suburb : "",
      role: isClearAll ? "all" : role ? role?.value : "all",
      joining_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.workers?.total,
              totalPages: Math.ceil(
                res?.data?.data?.workers?.total /
                  res?.data?.data?.workers?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.workers &&
      reportData?.workers?.map((item) => {
        const Participants =
          item.participants && item?.participants?.length > 0
            ? item.participants
                .map((el) => `${el.first_name} ${el.last_name}`)
                .join(", ")
            : "";

        return {
          Worker: `${item?.first_name} ${item?.last_name}` || "N/A",
          Participants: Participants || "N/A",

          Contact_Number: item?.phone || "N/A",
          Email: item?.email || "N/A",
          Status: item?.status ? "Active" : "In-Active",
          Date_of_Birth: item?.dob
            ? formatDateIntoDMYToDisplay(item?.dob)
            : "N/A",
          Suburb: item?.suburb || "N/A",
          Emergency_Contact: item?.emergency_contact || "N/A",
          Emergency_Contact_Phone:
            item?.emergency_contact_phone_number || "N/A",
          Role: item?.role || "N/A",
          Employe_Type: item?.employee_type || "N/A",
          Level: item?.salary_slab || "N/A",
          Joining_Date: item?.joining_date
            ? formatDateIntoDMYToDisplay(item?.joining_date)
            : "N/A",
          Born_Month: item?.born_month || "N/A",
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Worker List Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.WORKER_LIST_REPORTS
                )}
              />
            }
          />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by worker name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 py-4 w-full'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptionsReporting || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Suburb
                        </label>
                        <input
                          value={suburb}
                          onChange={(e) => setSuburb(e.target.value)}
                          className='did-floating-input'
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 py-4 w-full'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Date of Birth
                        </label>
                        <DateRangePickerNew
                          isSingleDate
                          isClearable
                          handleCancel={handleCancelButton}
                          handleChange={(obj) => {
                            setBirthDate(obj);
                          }}
                          singleSelectedDate={BirthDate && new Date(BirthDate)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Role
                        </label>
                        <SelectOption
                          name='primary_disability'
                          isSearch={true}
                          data={roleList || []}
                          selectedOption={role}
                          handleChange={(e) => {
                            setRole(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <label
                        htmlFor='from-date-to-to-date'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Month of Birth
                      </label>
                      <SelectOption
                        name='born_month'
                        isSearch={true}
                        data={GET_MONTH}
                        selectedOption={monthOfBorn}
                        handleChange={(e) => {
                          setMonthOfBorn(e);
                        }}
                      />
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Date Range of Joining
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          // setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          // maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end  space-x-2'
                        // style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setBirthDate(null);
                            setJoiningDate(null);
                            setMonthOfBorn(null);
                            setStatus(null);
                            setSelectedWorker(null);
                            setSelectedFromDate(null);
                            setRole(null);
                            setSearch(null);
                            setReports([]);
                            setFromDate(null);
                            setToDate(null);
                            setSuburb("");
                            setSelectedParticipant(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                        sortName='first_name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Support Worker"}
                      />
                      <TableHeader
                        // sortName='participant_name'
                        // flipSort={flipSort}
                        // sortBy={sortBy}
                        // sortDescending={sortDescending}
                        isSort={false}
                        label={"Participant Name"}
                      />

                      <TableHeader
                        sortName='phone'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Contact Number"}
                      />

                      <TableHeader
                        sortName='email'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Email"}
                      />

                      <TableHeader
                        sortName='status'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Status"}
                      />

                      <TableHeader
                        sortName='dob'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Date of Birth"}
                      />

                      <TableHeader
                        sortName='city'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Suburb"}
                      />

                      <TableHeader
                        sortName='emergency_contact_name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Emergency Contact"}
                      />

                      <TableHeader
                        sortName='emergency_contact_phone_number'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Emergency Phone"}
                      />

                      <TableHeader isSort={false} label={"Role"} />
                      <TableHeader
                        sortName='employee_type'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Emp Type"}
                      />
                      <TableHeader
                        sortName='salary_slab'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Level"}
                      />
                      <TableHeader
                        sortName='joining_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Joining Date"}
                      />
                      <TableHeader isSort={false} label={"Born Month"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={14} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.workers?.data?.length > 0 ? (
                      reportList?.workers?.data?.map((worker, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm'>
                            <div className='flex items-center'>
                              <div className='capitalize'>
                                <span
                                  title={`${worker.first_name} ${worker.last_name}`}
                                >
                                  <p
                                    className={`w-40 truncate cursor-pointer `}
                                  >{`${worker.first_name} ${worker.last_name}`}</p>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className=' text-sm text-gray-500'>
                            {(worker?.participants &&
                              _.isArray(worker?.participants) && (
                                <AvatarGroup
                                  type='worker'
                                  arrayData={worker?.participants || []}
                                  cssClass='mt-0'
                                  maxLength={4}
                                />
                              )) ||
                              "-"}
                          </td>

                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {worker?.phone || "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {worker?.email ? worker?.email : "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            <span
                              className={`${
                                worker.status
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                            >
                              {worker.status ? "Active" : "InActive"}
                            </span>
                          </td>

                          <td className='px-2 py-[11px] text-sm text-gray-500'>
                            {worker?.dob
                              ? formatDateIntoDMYToDisplay(worker.dob)
                              : "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.suburb || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.emergency_contact || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.emergency_contact_phone_number || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.role || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.employee_type || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.salary_slab || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.joining_date
                              ? formatDateIntoDMYToDisplay(worker.joining_date)
                              : "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {worker?.born_month || "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={14}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.workers?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.workers?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default WorkerListReports;
