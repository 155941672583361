import React, { useState, useEffect, useContext, useRef } from "react";
import { NumericFormat } from "react-number-format";
import _, { isArray } from "lodash";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { LoginContext } from "helpers/LoginContext";
import TableHeader from "components/common/Table/TableHeader";
import CustomButton from "components/common/Button/CustomButton";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { classNames, parseBooleanVal } from "Utils/utils";
import SideBarNew from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import PopoverContainer from "components/common/Popover/PopoverContainer";

const StaffCompliance = ({
  spStaffComplianceSettingsRequest,
  spStaffComplianceSetting,
  spStaffComplianceLoader,
  spStaffComplianceSettingsUpdateRequest,
  spStaffComplianceUpdateLoader,
}) => {
  const { isUserGracePeriodOver, sideBarPadding } = useContext(LoginContext);

  const [expandedRows, setExpandedRows] = useState([]);

  const [lastErrorLocation, setLastErrorLocation] = useState([]);

  const [compliancesData, setCompliancesData] = useState({
    document: [],
    form: [],
  });

  // const errorRef = useRef(null);

  useEffect(() => spStaffComplianceSettingsRequest(), []);

  useEffect(
    () => setCompliancesData(spStaffComplianceSetting),
    [spStaffComplianceSetting]
  );

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  const handleSubmit = () => {
    if (isUserGracePeriodOver || lastErrorLocation.length > 0) {
      return;
    }
    spStaffComplianceSettingsUpdateRequest({
      worker_compliance_settings: [
        ...(compliancesData?.document || []),
        ...(compliancesData?.form || []),
      ],
    });
  };

  const handleSwitchChange = (complianceType, item, value, type = "score") => {
    const prevSwitches = _.cloneDeep(compliancesData);
    if (prevSwitches?.[`${complianceType}`]) {
      if (!isArray(prevSwitches?.[`${complianceType}`])) {
        Object.assign(prevSwitches?.[`${complianceType}`], []);
      }
      const index = prevSwitches[`${complianceType}`]?.findIndex(
        (x) => x.id === item.id
      );
      if (index !== -1) {
        if (type === "score") {
          prevSwitches[`${complianceType}`][index].score = Number(value);
        } else {
          prevSwitches[`${complianceType}`][index].is_compliance = value;
        }
      } else {
        console.log("in else");
        // if (type === "score") {
        //   prevSwitches[`${complianceType}`]?.push({
        //     id: item.id,
        //     score: value,
        //     name: item.name,
        //   });
        // } else {
        //   prevSwitches[`${complianceType}`]?.push({
        //     id: item.id,
        //     is_compliance: value,
        //     name: item.name,
        //   });
        // }
      }
    }
    // else {
    //   if (type === "score") {
    //     Object.assign(prevSwitches, {
    //       [`${complianceType}`]: [
    //         { id: item.id, score: Number(value), name: item.name },
    //       ],
    //     });
    //   } else {
    //     Object.assign(prevSwitches, {
    //       [`${complianceType}`]: [
    //         { id: item.id, is_compliance: value, name: item.name },
    //       ],
    //     });
    //   }
    // }

    setCompliancesData({ ...prevSwitches });
  };

  const calculateTotalPercentage = () => {
    const documentScore = compliancesData?.document
      .filter((doc) => doc.is_compliance)
      .reduce((acc, doc) => acc + doc.score, 0);

    const formScore = compliancesData?.form
      .filter((form) => form.is_compliance)
      .reduce((acc, form) => acc + form.score, 0);

    const totalScore = documentScore + formScore;

    if (lastErrorLocation.length > 0 && Number(totalScore) === 100) {
      setLastErrorLocation([]);
    }
    return totalScore;
  };

  const checkIsAnyComplianceTrueWithZeroScore = () => {
    const data = [...compliancesData.document, ...compliancesData.form];
    const index = data.findIndex(
      (x) => x.is_compliance === true && Number(x.score) <= 0
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className='min-h-full'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title='Worker Compliance Settings'
            breadcrumb={
              <Breadcrumb
                pages={SP_SETTING_BREADCRUMB(
                  SETTINGS_BREADCRUMB_PAGES.WORKER_COMPLIANCE_SETTING
                )}
              />
            }
          />
          <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
            {spStaffComplianceLoader ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='max-w-7xl mx-auto'>
                  <div>
                    <div className='sticky top-[79.5px] z-50'>
                      <div className='my-5 grid grid-cols-1 gap-5'>
                        <div className='flex justify-between items-center gap-x-4 rounded-lg bg-white px-4 py-5 shadow-md sm:p-6'>
                          <div className='px-2 w-[80%]'>
                            <ul style={{ listStyle: "disc" }}>
                              <li>
                                Please specify the percentage for each document
                                and form related to worker compliance in the
                                onboarding process.
                              </li>
                              <li>
                                The total percentage must be equal to 100.
                              </li>
                            </ul>
                          </div>
                          <div className='px-4 w-[20%]'>
                            <div className='truncate text-sm font-medium text-gray-500'>
                              Total Percentage
                            </div>
                            <div className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                              {calculateTotalPercentage()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <div className='min-w-full'>
                        <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                          <div className='table-wrp block'>
                            {spStaffComplianceLoader ? (
                              <div
                                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                              >
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </div>
                            ) : (
                              <table className='min-w-full  divide-y divide-gray-300  capitalize'>
                                <thead className='bg-gray-50 sticky top-[187px] z-[5] capitalize'>
                                  <tr>
                                    <TableHeader
                                      isSort={false}
                                      label={"Staff Onboarding"}
                                      cssClass='sm:pl-3 w-[80%] border '
                                    />
                                    <TableHeader
                                      cssClass='sm:pl-3 text-center border'
                                      isSort={false}
                                      label={"Percentage"}
                                    />
                                  </tr>
                                </thead>
                                <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                                  {Object.entries(compliancesData).map(
                                    ([key, childs], index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr className='bg-white  '>
                                            <td
                                              className='whitespace-nowrap  py-4 px-3  text-sm sm:pl-3 w-[40%] '
                                              onClick={() =>
                                                handleRowClick(index)
                                              }
                                            >
                                              <div className='flex items-center cursor-pointer '>
                                                {expandedRows.includes(
                                                  index
                                                ) ? (
                                                  <BiMinus className='h-3 w-3 text-gray-600' />
                                                ) : (
                                                  <BsPlusLg className='h-3 w-3 text-gray-600' />
                                                )}
                                                <span className='ml-3'>
                                                  {key}
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                          {expandedRows.includes(index) &&
                                            childs
                                              // .sort((a, b) =>
                                              //   a.is_compliance ? -1 : 1
                                              // )
                                              .map((item, childIndex) => {
                                                return (
                                                  <tr
                                                    className='even:bg-white odd:bg-gray-50 '
                                                    key={`${item.name}-${childIndex}`}
                                                  >
                                                    <td className='whitespace-nowrap  border py-4 px-3 text-sm sm:pl-3 w-[40%]'>
                                                      <div className='flex items-center cursor-pointer '>
                                                        <span className='ml-9'>
                                                          {item.name}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td className='text-center py-2'>
                                                      <div className='flex justify-center items-center w-full space-x-10'>
                                                        <div className='flex items-center'>
                                                          <PopoverContainer
                                                            isHover={true}
                                                            placement={"left"}
                                                            itemComponent={() => (
                                                              <div className='ml-1 mr-1 rounded border bg-white text-sm min-w-[260px] max-w-[350px]'>
                                                                <div
                                                                  className={classNames(
                                                                    "rounded flex justify-between gap-2 items-center py-0.5 px-3",
                                                                    "background-gradient border-gradient text-white"
                                                                  )}
                                                                >
                                                                  {`Click here to
                                                                  set the
                                                                  ${key} as
                                                                  ${
                                                                    !item.is_compliance
                                                                      ? "mandatory"
                                                                      : "non-mandatory."
                                                                  }`}
                                                                </div>
                                                              </div>
                                                            )}
                                                          >
                                                            <span>
                                                              <SwitchWithIcon
                                                                onChange={(
                                                                  value
                                                                ) => {
                                                                  const prevObj =
                                                                    _.cloneDeep(
                                                                      lastErrorLocation
                                                                    );
                                                                  const index =
                                                                    prevObj.findIndex(
                                                                      (x) =>
                                                                        x ===
                                                                        `switches[${item.name}]['${item.id}']`
                                                                    );
                                                                  if (value) {
                                                                    if (
                                                                      index ===
                                                                      -1
                                                                    ) {
                                                                      prevObj.push(
                                                                        `switches[${item.name}]['${item.id}']`
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      index !==
                                                                      -1
                                                                    ) {
                                                                      prevObj.splice(
                                                                        index,
                                                                        1
                                                                      );
                                                                    }
                                                                  }

                                                                  setLastErrorLocation(
                                                                    [...prevObj]
                                                                  );

                                                                  handleSwitchChange(
                                                                    key,
                                                                    item,
                                                                    value,
                                                                    "switch"
                                                                  );
                                                                }}
                                                                checked={
                                                                  item.is_compliance
                                                                }
                                                              />
                                                            </span>
                                                          </PopoverContainer>
                                                        </div>
                                                        <div className='w-[40%]'>
                                                          <NumericFormat
                                                            disabled={
                                                              !item.is_compliance
                                                            }
                                                            allowNegative={
                                                              false
                                                            }
                                                            value={item.score}
                                                            id={`switches[${item.name}]['${item.id}']`}
                                                            min={0}
                                                            minLength={1}
                                                            suffix='%'
                                                            type='text'
                                                            max={100}
                                                            maxLength={4}
                                                            className={`${
                                                              (parseBooleanVal(
                                                                item.is_compliance
                                                              ) &&
                                                                Number(
                                                                  item.score
                                                                ) === 0) ||
                                                              lastErrorLocation.findIndex(
                                                                (x) =>
                                                                  x ===
                                                                  `switches[${item.name}]['${item.id}']`
                                                              ) !== -1
                                                                ? "errorBorder"
                                                                : ""
                                                            } ${"focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"} w-full disabled:opacity-50`}
                                                            valueIsNumericString={
                                                              true
                                                            }
                                                            decimalScale={0}
                                                            allowLeadingZeros={
                                                              false
                                                            }
                                                            onValueChange={(
                                                              e
                                                            ) => {
                                                              let {
                                                                floatValue,
                                                              } = e;

                                                              floatValue =
                                                                parseFloat(
                                                                  floatValue ===
                                                                    undefined
                                                                    ? 0
                                                                    : floatValue
                                                                );

                                                              floatValue =
                                                                Math.round(
                                                                  floatValue *
                                                                    100
                                                                ) / 100;

                                                              if (
                                                                floatValue > 100
                                                              ) {
                                                                floatValue = 100;
                                                              }

                                                              const prevObj =
                                                                _.cloneDeep(
                                                                  lastErrorLocation
                                                                );
                                                              const index =
                                                                prevObj.findIndex(
                                                                  (x) =>
                                                                    x ===
                                                                    `switches[${item.name}]['${item.id}']`
                                                                );
                                                              if (
                                                                index === -1
                                                              ) {
                                                                prevObj.push(
                                                                  `switches[${item.name}]['${item.id}']`
                                                                );
                                                              }

                                                              setLastErrorLocation(
                                                                [...prevObj]
                                                              );

                                                              handleSwitchChange(
                                                                key,
                                                                item,
                                                                floatValue
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      {lastErrorLocation.findIndex(
                                                        (x) =>
                                                          x ===
                                                          `switches[${item.name}]['${item.id}']`
                                                      ) !== -1 && (
                                                        <div>
                                                          <span className='error-message'>
                                                            Percentage should be
                                                            100%
                                                          </span>
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div ref={errorRef}>
                    {Number(calculateTotalPercentage()) !== 100 && (
                      <div className={`mt-5 flex justify-end items-center`}>
                        <span className='text-md text-red-600'>
                          The sum of percentage should equal 100%
                        </span>
                      </div>
                    )}
                  </div> */}
                  <div className='mt-6 flex justify-end'>
                    <CustomButton
                      showLoading={spStaffComplianceUpdateLoader}
                      isDisabled={
                        checkIsAnyComplianceTrueWithZeroScore() ||
                        lastErrorLocation.length > 0 ||
                        spStaffComplianceUpdateLoader ||
                        isUserGracePeriodOver ||
                        Number(calculateTotalPercentage()) !== 100
                      }
                      variant='primary'
                      clickHandler={handleSubmit}
                      label='Update Changes'
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffCompliance;
