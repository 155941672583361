import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import { useForm } from "react-hook-form";
import { apiGetRequest, apiPostRequest } from "../../../../helpers/Requests";
import { AiOutlineClockCircle } from "react-icons/ai";
import CustomButton from "../../../../components/common/Button/CustomButton";
import { toast } from "react-toastify";
import { LoginContext } from "../../../../helpers/LoginContext";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import {
  baseURLImage,
  getAge,
  getNameProfile,
  isValidFile,
  validDate,
} from "../../../../Utils/utils";
import moment from "moment";
import Header from "../../../../components/common/Header/Header";
import { DUMMY_REGEX_EXP, REGEX_PHONE_NUMBER } from "Constants/constant";
import { updateAuthUser } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";
import ReadOnlyStarRating from "pages/HRM/Pages/Worker/Component/KeyStregnthsReadOnly";
import WorkerAvailabilty from "pages/HRM/Pages/Worker/WorkerAvailabilty";

const EmployeeSettings = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const dispatch = useDispatch();
  const watchFormData = watch();

  const [picture, setPicture] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [file, setFile] = useState(undefined);
  const [timeSlotData, setRefreshPage] = React.useState(false);

  const onSubmit = async (data) => {
    if (isUserGracePeriodOver) return;

    let formData = new FormData();
    if (file && picture !== null) {
      formData.append("photo", picture);
    }
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("bio", data.bio);
    formData.append("address", data.address);
    formData.append("phone", data.phone);
    formData.append("emergency_contact_name", data.emergencyContactName);
    formData.append(
      "emergency_contact_phone_number",
      data.emergencyContactPhone
    );
    formData.append("identification_number", data.identificationNumber);
    formData.append("dob", data.dob);
    formData.append("time_zone", data.timeZone);
    formData.append("account_name", data.accountName);
    formData.append("bsb_no", data.bsbNumber);
    formData.append("account_no", data.accountNumber);
    formData.append("tax_file_no", data.taxFileNumber);
    formData.append(
      "super_innovation_company_name",
      data.supperInovationNumber
    );
    formData.append("supper_innovation_no", data.supperInovationCompanyName);

    setIsLoading(true);
    try {
      const res = await apiPostRequest(`hrm/employee-setting`, formData);
      if (res.status === 200) {
        setIsLoading(false);
        toast.success("Setting updated successfully!");
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          user.profile_picture = `${res.data.data.photo}`;
          user.dob = res.data.data.dob;
          user.first_name = res.data.data.first_name;
          user.last_name = res.data.data.last_name;
          user.name = `${user.first_name} ${user.last_name}`;
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(updateAuthUser(user));
        }
      } else if (res.status !== 201) {
        setIsLoading(false);
        toast.error("Setting not updated, Try Again!");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message ?? "Setting not updated, Try Again!");
      setIsLoading(false);
    }
  };

  const fetchSettingsData = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(`hrm/worker/employee-setting`);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setSettingsData(res?.data?.setting);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const message = isValidFile(event.target.files[0], "image");
      if (message.length > 0) {
        toast.error(message);
      } else {
        setFile(URL.createObjectURL(event.target.files[0]));
        setPicture(event.target.files[0]);
      }
    }
  };

  useEffect(() => {
    fetchSettingsData();
  }, [timeSlotData]);

  useEffect(() => {
    if (settingsData) {
      // simulate async api call with set timeout
      setUser({
        first_name: settingsData.first_name || "",
        last_name: settingsData.last_name || "",
        bio: settingsData.bio || "",
        photo: settingsData.photo || "",
        address: settingsData.address || "",
        phone: settingsData.phone || "",
        emergencyContactName: settingsData.emergency_contact_name || "",
        emergencyContactPhone:
          settingsData.emergency_contact_phone_number || "",
        identificationNumber: settingsData.identification_number || "",
        dob: settingsData.dob || "",
        timeZone: settingsData.time_zone || "",
        accountName: settingsData.account_name || "",
        bsbNumber: settingsData.bsb_no || "",
        accountNumber: settingsData.account_no || "",
        taxFileNumber: settingsData.tax_file_no || "",
        supperInovationNumber: settingsData.supper_innovation_no || "",
        supperInovationCompanyName:
          settingsData.super_innovation_company_name || "",
      });
      if (settingsData.photo !== null) {
        setFile(`https://${settingsData.photo}`);
      }
    }
  }, [settingsData]);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user, settingsData]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <>
      <div className='min-h-full bg-white'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          <main className='flex-1'>
            <Header
              title='Settings'
              breadcrumb={
                <Breadcrumb
                  pages={EMPLOYEE_BREADCRUMB(
                    EMPLOYEE_BREADCRUMB_PAGES.SETTINGS
                  )}
                />
              }
            />
            {request.isRequested && !user ? (
              <div className='col-span-3 h-screen'>
                <CircularLoader
                  classes='h-full flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <main className='max-w-7xl mx-auto'>
                <div className='md:grid md:gap-6 px-6 '>
                  <div className='md:col-span-2'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='shadow sm:rounded-md sm:overflow-hidden'>
                        <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                          <div>
                            <label className='block text-sm font-medium text-gray-700'>
                              Photo
                            </label>
                            <div className='mt-1 flex items-center'>
                              {file ? (
                                <img
                                  className='object-cover w-10 h-10 rounded-full'
                                  src={file}
                                  alt=''
                                />
                              ) : (
                                <img
                                  onError={(e) =>
                                    handleImageError(
                                      e,
                                      settingsData?.first_name,
                                      settingsData?.last_name
                                    )
                                  }
                                  className='object-cover w-10 h-10 rounded-full'
                                  src={
                                    settingsData &&
                                    settingsData?.profile_picture
                                      ? baseURLImage(
                                          settingsData?.profile_picture
                                        )
                                      : getNameProfile(
                                          settingsData?.first_name,
                                          settingsData?.last_name
                                        )
                                  }
                                  alt=''
                                />
                              )}
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 ml-5'
                              >
                                <span>Upload a file</span>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  className='sr-only'
                                  accept='image/*'
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='grid grid-cols-2 gap-y-4 gap-x-5 px-4 py-4 pt-0'>
                          <div>
                            <label
                              htmlFor='first_name'
                              className='capitalize block text-sm font-medium text-gray-700'
                            >
                              First Name
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                className='did-floating-input capitalize'
                                {...register("first_name", {
                                  required: true,
                                  maxLength: 50,
                                })}
                              />
                              {errors.first_name && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor='fullName'
                              className='capitalize block text-sm font-medium text-gray-700'
                            >
                              Last Name
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='last_name'
                                id='lastName'
                                className='did-floating-input capitalize'
                                {...register("last_name", {
                                  required: true,
                                  maxLength: 50,
                                })}
                              />
                              {errors.last_name && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor='bio'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Bio
                            </label>
                            <div className='mt-1'>
                              <textarea
                                rows={3}
                                name='bio'
                                id='bio'
                                className='overflow-y-auto px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                                {...register("bio", { required: true })}
                              />
                              {errors.bio && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                              {/* <span className="text-gray-500 text-sm">275 characters left</span> */}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Street Address
                            </label>
                            <div className='relative'>
                              <input
                                type='text'
                                name='address'
                                id='address'
                                className='did-floating-input '
                                {...register("address", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.address && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor='phone'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Phone Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='number'
                                name='phone'
                                id='phone'
                                maxLength={10}
                                className='did-floating-input'
                                {...register("phone", {
                                  required: true,
                                  // minLength: 10,
                                  maxLength: 10,
                                  pattern: REGEX_PHONE_NUMBER,
                                  onChange: (e) => {
                                    const sanitizedValue =
                                      e.target.value.replace(/\D/g, "");
                                    const formattedValue = sanitizedValue.slice(
                                      0,
                                      10
                                    );
                                    e.target.value = formattedValue;
                                  },
                                })}
                              />

                              {errors?.phone?.type === "required" ? (
                                errors.phone && (
                                  <span className='text-red-500'>
                                    This field is required
                                  </span>
                                )
                              ) : (
                                <>
                                  {errors.phone?.type === "minLength" && (
                                    <span className='text-red-500'>
                                      Please Enter a 10 digits phone Number
                                    </span>
                                  )}
                                  {errors?.phone?.type === "pattern" && (
                                    <span className='text-sm text-red-500'>
                                      {/* Phone number must start with 04, 1300 or
                                      1800 */}
                                      Please enter a valid phone number!
                                    </span>
                                  )}
                                  {/* {errors.phone?.type === "maxLength" && (
                                    <span className="text-red-500">
                                      Phone number must have 10 digits
                                    </span>
                                  )} */}
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor='name'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Emergency Contact (Name)
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='name'
                                id='name'
                                className='did-floating-input'
                                {...register("emergencyContactName", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.emergencyContactName && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor='number'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Emergency Contact (Phone Number)
                            </label>
                            <div className='mt-1'>
                              <input
                                type='number'
                                name='number'
                                id='numbr'
                                className='did-floating-input'
                                {...register("emergencyContactPhone", {
                                  required: true,
                                  // minLength: 10,
                                  maxLength: 10,
                                  pattern: REGEX_PHONE_NUMBER,
                                  onChange: (e) => {
                                    const sanitizedValue =
                                      e.target.value.replace(/\D/g, "");
                                    const formattedValue = sanitizedValue.slice(
                                      0,
                                      10
                                    );
                                    e.target.value = formattedValue;
                                  },
                                })}
                              />
                              {errors.required ? (
                                errors.emergencyContactPhone && (
                                  <span className='text-red-500'>
                                    This field is required
                                  </span>
                                )
                              ) : (
                                <>
                                  {errors.emergencyContactPhone?.type ===
                                    "minLength" && (
                                    <span className='text-red-500'>
                                      Please enter a valid phone number with the
                                      min length of 10
                                    </span>
                                  )}
                                  {errors?.emergencyContactPhone?.type ===
                                    "pattern" && (
                                    <span className='text-sm text-red-500'>
                                      {/* Phone number must start with 04, 1300 or
                                      1800 */}
                                      Please enter a valid phone number!
                                    </span>
                                  )}

                                  {/* {errors.emergencyContactPhone?.type ===
                                    "maxLength" && (
                                    <span className="text-red-500">
                                      Please enter a valid phone number with the
                                      max length of 10
                                    </span>
                                  )} */}
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Identification Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='identificationNumber'
                                id='identificationNumber'
                                className='did-floating-input'
                                {...register("identificationNumber", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.identificationNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Date of Birth
                            </label>
                            <div className='mt-1'>
                              <input
                                min={moment().format("DD-MM-YYYY")}
                                type='date'
                                name='dob'
                                id='dob'
                                className='did-floating-input'
                                {...register("dob", {
                                  required: true,
                                  // validate: (value) => validDate(value),
                                  pattern:
                                    watchFormData &&
                                    getAge(watchFormData?.dob || "").years < 18
                                      ? DUMMY_REGEX_EXP
                                      : false,
                                })}
                              />
                              {errors?.dob?.type === "required" && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                              {errors?.dob?.type === "pattern" && (
                                <span className='text-red-500'>
                                  You should be 18+
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor='timeZone'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Timezone
                            </label>
                            <div className='relative'>
                              <select
                                id='timeZone'
                                name='timeZone'
                                className='shadow-sm border mt-1 block w-full pl-8 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:bg-[#4b6bb710] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                                defaultValue=''
                                {...register("timeZone", { required: true })}
                              >
                                <option>
                                  Australian Western Standard Time (AWST) UTC +8
                                </option>
                                <option>
                                  Australian Central Western Standard Time
                                  (ACWST) UTC +8:45
                                </option>
                                <option>
                                  Australian Central Standard Time (ACST) UTC
                                  +9:30
                                </option>
                                <option>
                                  Australian Eastern Standard Time (AEST) UTC
                                  +10
                                </option>
                                <option>
                                  Lord Howe Standard Time (LHST) UTC +10:30
                                </option>
                              </select>
                              {errors.timeZone && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                              <div className='absolute top-1/2 -translate-y-1/2 left-3'>
                                <AiOutlineClockCircle />
                              </div>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Account Name
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='accountName'
                                id='accountName'
                                className='did-floating-input'
                                {...register("accountName", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.accountName && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              BSB Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='number'
                                name='bsbNumber'
                                id='bsbNumber'
                                className='did-floating-input'
                                {...register("bsbNumber", { required: true })}
                              />
                              {errors.bsbNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Account Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='number'
                                name='accountNumber'
                                id='accountNumber'
                                className='did-floating-input'
                                {...register("accountNumber", {
                                  required: true,
                                })}
                              />
                              {errors.accountNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Tax File Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='number'
                                name='taxFileNumber'
                                id='taxFileNumber'
                                className='did-floating-input'
                                {...register("taxFileNumber", {
                                  required: true,
                                })}
                              />
                              {errors.taxFileNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Superannuation Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='supperInovationNumber'
                                id='supperInovationNumber'
                                className='did-floating-input'
                                {...register("supperInovationNumber", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.supperInovationNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Superannuation Company Name
                            </label>
                            <div className='mt-1'>
                              <input
                                type='text'
                                name='supperInovationCompanyName'
                                id='supperInovationCompanyName'
                                className='did-floating-input'
                                {...register("supperInovationCompanyName", {
                                  required: true,
                                  maxLength: 254,
                                })}
                              />
                              {errors.supperInovationCompanyName && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='px-4'>
                          <label className='block text-md font-bold'>
                            Key Strengths, Likes & Area of Improvement
                          </label>
                          <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                            {settingsData?.key_strengths ? (
                              JSON.parse(settingsData.key_strengths).map(
                                (item, index) => (
                                  <div key={index} className='mt-3'>
                                    <ReadOnlyStarRating
                                      key={index}
                                      label={Object.keys(item)[0]}
                                      value={Object.values(item)[0]}
                                    />
                                  </div>
                                )
                              )
                            ) : (
                              <div className='mt-3 flex justify-center'>
                                No data available
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='p-5'>
                          <WorkerAvailabilty
                            isUserGracePeriodOver={isUserGracePeriodOver}
                            worker={settingsData}
                            isWorkerAvailablity={timeSlotData}
                            setRefreshPageForWorkerTimeSlot={setRefreshPage}
                          />
                        </div>
                        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 space-x-4'>
                          <CustomButton
                            showLoading={isLoading}
                            label='Save Changes'
                            isDisabled={isLoading || isUserGracePeriodOver}
                            type='submit'
                            variant='btn-purple'
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </main>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default EmployeeSettings;
