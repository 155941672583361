import React, { useContext, useEffect, useMemo, useState } from "react";
import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import Breadcrumb from "components/common/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Search from "components/common/Search/Search";
import SelectOption from "components/common/Select/Select";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import {
  PARENT_FORM_CATEGORY_SLUG,
  emptyPaginationObj,
} from "Constants/constant";
import PaginationComponent from "components/common/Pagination/index";
import { HiEye } from "react-icons/hi";
import CategoryFormsListing from "components/Modals/CategoryFormsListingHRM/CategoryFormsListing";
import {
  formatDateIntoDMYToDisplay,
  getPaginationObj,
  parseBooleanVal,
} from "Utils/utils";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { MdOutlineNotStarted } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";

const SubmittedForms = (props) => {
  const {
    currentUser,
    hrmFormCategories,
    hrmFormCategoriesListRequest,
    hrmFormCatsLoader,
    getHrmSubmittedFormsListByCategoryRequest,
    hrmSubmittedFormListLoader,
    hrmSubmittedFormsList,
  } = props;

  const { sideBarPadding } = useContext(LoginContext);
  const navigate = useNavigate();

  if (currentUser === null || !currentUser) {
    navigate("/");
  }

  const [search, setSearch] = useState(null);
  const [paginationObj, setPaginationObj] = useState({ ...emptyPaginationObj });
  const [selectedFormCategory, setSelectedFormCategory] = useState(null);

  useEffect(() => hrmFormCategoriesListRequest(), []);

  useEffect(() => {
    if (
      !hrmFormCatsLoader &&
      selectedFormCategory === null &&
      hrmFormCategories &&
      hrmFormCategories.length > 0
    ) {
      setSelectedFormCategory(hrmFormCategories[0]);
    }
  }, [hrmFormCatsLoader]);

  useEffect(() => {
    if (search) setSearch(null);
    if (selectedFormCategory) {
      handleFetch({
        c_id: selectedFormCategory.value,
        page: 1,
      });
    }
  }, [selectedFormCategory]);

  const viewFormTemplateHandler = (selectedForm) => {
    if (selectedFormCategory) {
      navigate(
        `/submitted-form/${selectedFormCategory.slug}/${selectedForm.uuid}/view`
      );
    }
  };

  const handleFetch = (obj = {}) => {
    if (Object.keys(obj).length > 0 && obj !== "undefined" && obj !== null) {
      !obj?.page &&
        Object.assign(obj, {
          page: paginationObj.page,
        });
      !obj?.c_id &&
        Object.assign(obj, {
          c_id: selectedFormCategory.value,
        });
    }
    getHrmSubmittedFormsListByCategoryRequest({
      ...obj,
      per_page: 10,
      sort_field: "version",
      sort_order: "desc",
    });
  };

  const loadHeaderButton = () => {
    if (selectedFormCategory === null) return null;
    if (!currentUser || currentUser === null) return null;
    switch (selectedFormCategory.slug) {
      case PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      case PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Compliance.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      case PARENT_FORM_CATEGORY_SLUG.Performance_Management.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Performance_Management.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Performance_Management.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      case PARENT_FORM_CATEGORY_SLUG.Training_And_Development.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Training_And_Development.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Training_And_Development.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      case PARENT_FORM_CATEGORY_SLUG.Other.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Other.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Other.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      case PARENT_FORM_CATEGORY_SLUG.Onboarding.SLUG:
        return (
          <CategoryFormsListing
            role='worker'
            buttonText={PARENT_FORM_CATEGORY_SLUG.Onboarding.NAME}
            slug={PARENT_FORM_CATEGORY_SLUG.Onboarding.SLUG}
            particpantUuid={currentUser.uuid}
          />
        );
      default:
        return null;
    }
  };

  const hrmSubmittedFormsListToMap = useMemo(() => {
    if (
      hrmSubmittedFormsList &&
      hrmSubmittedFormsList?.submitted_forms?.data?.length > 0
    ) {
      return hrmSubmittedFormsList.submitted_forms?.data;
    }
    return [];
  }, [hrmSubmittedFormsList]);

  useEffect(() => {
    if (hrmSubmittedFormsList) {
      setPaginationObj({
        ...getPaginationObj(
          hrmSubmittedFormsList?.submitted_forms,
          hrmSubmittedFormsList?.query_params
        ),
      });
      if (hrmSubmittedFormsList?.query_params?.search) {
        setSearch(hrmSubmittedFormsList?.query_params?.search);
      }
    }
  }, [hrmSubmittedFormsList]);

  useEffect(() => {
    // setRenderLoading(LOADER_STATUS.PENDING);
    const timeOutId = setTimeout(
      () =>
        !hrmSubmittedFormListLoader &&
        selectedFormCategory &&
        search !== null &&
        handleFetch({
          c_id: selectedFormCategory.value,
          search: search,
          page: 1,
        }),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <div className='min-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        } flex flex-col`}
      >
        <main className='flex-1'>
          <div className='w-full min-h-full'>
            <main className='flex-1'>
              <Header
                title='Submitted Forms'
                breadcrumb={
                  <Breadcrumb
                    pages={EMPLOYEE_BREADCRUMB(
                      EMPLOYEE_BREADCRUMB_PAGES.SUBMITTED_FORM
                    )}
                  />
                }
                {...(roleAccess(
                  [],
                  PERMISSION_KEYS.SUBMITTED,
                  BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED.FORM.CREATE
                ) && { btnComponent: loadHeaderButton() })}
              />
              <div className='px-6 max-w-7xl mx-auto'>
                <div className='mt-2 flex justify-between items-center bg-white py-2 '>
                  <div className='w-1/4'>
                    <SelectOption
                      isDisabled={
                        hrmFormCatsLoader || hrmSubmittedFormListLoader
                      }
                      loading={hrmFormCatsLoader}
                      isSearch={false}
                      selectedOption={selectedFormCategory}
                      handleChange={(e) => {
                        setSelectedFormCategory(e);
                        //   handleFetch(
                        //     {
                        //       status: formTemplateStatus.value,
                        //       form_category: e,
                        //       page: 1,
                        //     },
                        //     sortBy
                        //   );
                        //   const options = e.map((some) => {
                        //     const obj = { value: some.id, label: some.label };
                        //     return obj;
                        //   });
                        //   setSelectedFormCategories(options);
                      }}
                      data={hrmFormCategories}
                    />
                  </div>
                  <div>
                    <Search setSearch={setSearch} search={search} />
                  </div>
                </div>
              </div>
              <main>
                <div className='flex flex-col px-6 pb-6 w-full max-w-7xl mx-auto'>
                  <div className='inline-block min-w-full py-2 align-middle'>
                    <div className='overflow-hidden111 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      {hrmSubmittedFormListLoader ? (
                        <div
                          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                        >
                          <CircularLoader
                            classes='flex justify-center items-center'
                            classloader='loader-l'
                          />
                        </div>
                      ) : (
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-white capitalize'>
                            <tr className='sticky top-[78px]'>
                              <TableHeader
                                sortName='title'
                                isSort={false}
                                //   flipSort={flipSort}
                                //   sortBy={sortBy}
                                //   sortDescending={sortDescending}
                                label='Title'
                              />
                              <TableHeader isSort={false} label='Category' />
                              <TableHeader isSort={false} label='Version' />
                              <TableHeader
                                isSort={false}
                                label='Submitted By'
                              />
                              <TableHeader isSort={false} label='Updated At' />
                              <TableHeader isSort={false} label='Updated By' />
                              {(roleAccess(
                                [],
                                PERMISSION_KEYS.SUBMITTED,
                                BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED
                                  .FORM.VIEW
                              ) ||
                                roleAccess(
                                  [],
                                  PERMISSION_KEYS.SUBMITTED,
                                  BLK_PORTAL_PREMISSION_KEYS_CHILDREN.SUBMITTED
                                    .FORM.CREATE
                                )) && (
                                <th
                                  scope='col'
                                  className='py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-900 bg-blue-50 z-0'
                                >
                                  Action
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {hrmSubmittedFormsListToMap &&
                              hrmSubmittedFormsListToMap.length > 0 &&
                              hrmSubmittedFormsListToMap
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((form) => (
                                  <tr
                                    key={form.id}
                                    className='even:bg-white odd:bg-gray-50'
                                  >
                                    <td className='whitespace-nowrap py-4 px-3 text-sm'>
                                      <div className='flex items-center'>
                                        <div className=' flex items-center space-x-2'>
                                          <span className='capitalize font-medium text-gray-900'>
                                            {form.title}
                                          </span>
                                          {form.is_complete ? (
                                            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                                              Complete
                                            </span>
                                          ) : (
                                            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                                              Incomplete
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                      <div className='text-gray-900 capitalize'>
                                        {form.category_name}
                                      </div>
                                    </td>
                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                      <div className='text-gray-900 capitalize'>
                                        {form.version}
                                      </div>
                                    </td>
                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                      <div className='text-gray-900 capitalize'>
                                        {form.created_by}
                                      </div>
                                    </td>

                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                      <div className='text-gray-900 capitalize'>
                                        {form.updated_at
                                          ? formatDateIntoDMYToDisplay(
                                              form.updated_at
                                            )
                                          : "-"}
                                      </div>
                                    </td>
                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                      <div className='text-gray-900 capitalize'>
                                        {form.updated_by ?? "-"}
                                      </div>
                                    </td>

                                    <td>
                                      <div className='flex items-center justify-center gap-x-3'>
                                        {roleAccess(
                                          [],
                                          PERMISSION_KEYS.SUBMITTED,
                                          BLK_PORTAL_PREMISSION_KEYS_CHILDREN
                                            .SUBMITTED.FORM.VIEW
                                        ) && (
                                          <button
                                            onClick={() =>
                                              viewFormTemplateHandler(form)
                                            }
                                          >
                                            <div
                                              className='icon-eye-wrapper'
                                              title='View'
                                            >
                                              <HiEye className='icon-size' />
                                            </div>
                                          </button>
                                        )}
                                        {roleAccess(
                                          [],
                                          PERMISSION_KEYS.SUBMITTED,
                                          BLK_PORTAL_PREMISSION_KEYS_CHILDREN
                                            .SUBMITTED.FORM.CREATE
                                        ) &&
                                        selectedFormCategory !== null &&
                                        selectedFormCategory?.slug &&
                                        !parseBooleanVal(form.is_complete) ? (
                                          <Link
                                            to={`/view-template/hrm/${
                                              currentUser.uuid
                                            }/${selectedFormCategory.slug}/${
                                              form.uuid
                                            }/${window.btoa(
                                              encodeURIComponent("false")
                                            )}`}
                                            className='text-indigo-600 hover:text-indigo-900'
                                          >
                                            <div className='icon-stopPlay-wrapper'>
                                              <MdOutlineNotStarted className='icon-size' />
                                            </div>
                                          </Link>
                                        ) : (
                                          roleAccess(
                                            [],
                                            PERMISSION_KEYS.SUBMITTED,
                                            BLK_PORTAL_PREMISSION_KEYS_CHILDREN
                                              .SUBMITTED.FORM.VIEW
                                          ) &&
                                          parseBooleanVal(form.is_complete) &&
                                          selectedFormCategory &&
                                          selectedFormCategory?.slug && (
                                            <Link
                                              role='button'
                                              to={`/submitted-form/${selectedFormCategory.slug}/${form.uuid}/update`}
                                              // state={{
                                              //   participantId: participantId,
                                              //   particpantUuid: participantsUid,
                                              // }}
                                              className='text-indigo-600 hover:text-indigo-900'
                                            >
                                              <div className='icon-pencil-wrapper'>
                                                <BiPencil className='icon-size' />
                                              </div>
                                            </Link>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            {!hrmSubmittedFormListLoader &&
                              hrmSubmittedFormsListToMap &&
                              hrmSubmittedFormsListToMap?.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={7}
                                    className='py-6 text-center text-red-600 align-baseline'
                                  >
                                    No data found!
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </main>
              {hrmSubmittedFormsListToMap &&
                hrmSubmittedFormsListToMap.length > 0 &&
                hrmFormCategories &&
                hrmFormCategories.length > 0 && (
                  <div className='bg-white px-6 max-w-7xl pb-10'>
                    <PaginationComponent
                      page={paginationObj.page}
                      activeListLength={hrmSubmittedFormsListToMap}
                      limit={10}
                      totalList={paginationObj.total}
                      handlePagination={(e) => {
                        const obj = paginationObj;
                        obj.page = e;
                        setPaginationObj({ ...obj });
                        const payload = { page: e };
                        if (search)
                          Object.assign(payload, {
                            search: search,
                          });
                        handleFetch(payload);
                      }}
                      totalPages={paginationObj.totalPages}
                    />
                  </div>
                )}
            </main>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SubmittedForms;
