import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import CreatedCosInvoiceTabel from "./COSInvoiceTabel";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";
import { BiPlus } from "react-icons/bi";

const COSInvoiceListPage = (props) => {
  const {
    createdCosInvoiceLoading,
    getInvoicesList,
    createdCosInvoice,
    cosInvoiceLoading,
    emptyTableData,
  } = props;

  const navigate = useNavigate();
  const { sideBarPadding } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);

  const createdCosInvoiceToMap = useMemo(() => {
    if (createdCosInvoice && createdCosInvoice.length > 0) {
      return createdCosInvoice;
    }
    return [];
  }, [createdCosInvoice]);

  useEffect(() => {
    getInvoicesList();
    return () => emptyTableData();
  }, []);

  useEffect(() => {
    if (!cosInvoiceLoading && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [cosInvoiceLoading]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header
            title='Cos Invoices'
            breadcrumb={
              <Breadcrumb
                pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.CREATED_COS_INVOICE)}
              />
            }
            handleClick={() => navigate("/cos/invoice-new")}
            btnLabel='Cos Invoice'
            icon={<BiPlus />}
            isButton
          />
          <div className='max-w-7xl mx-auto px-4 py-3 bg-white'>
            <div className='sm:px-6 lg:px-4 py-4'>
              <div className='flex flex-col'>
                <div className='min-w-full'>
                  <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <div className='table-wrp block'>
                      <table className='min-w-full divide-y divide-gray-300'>
                        <thead
                          className='bg-blue-50 sticky z-[5] capitalize'
                          style={{ top: "80px" }}
                        >
                          <tr>
                            <TableHeader
                              sortName='reference'
                              isSort={false}
                              label={"Reference"}
                            />
                            <TableHeader
                              sortName='created_by'
                              isSort={false}
                              label={"Created By"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='created-at'
                              label={"Created Date"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='support_item_no'
                              label={"Service Delivered From"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='created_by'
                              label={"Service Delivered To"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='client'
                              label={"Num Delivered Services"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='management_style'
                              label={"Total Value"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='status'
                              label={"Actions"}
                            />
                            <th>
                              <div className='sr-only'>View</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {createdCosInvoiceLoading ? (
                            <tr>
                              <td
                                className='w-full text-center py-4 bg-gray-50'
                                colSpan='8'
                              >
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </td>
                            </tr>
                          ) : createdCosInvoiceToMap &&
                            createdCosInvoiceToMap.length > 0 ? (
                            createdCosInvoiceToMap.map((item, index) => (
                              <CreatedCosInvoiceTabel item={item} key={index} />
                            ))
                          ) : (
                            // renderLoading !== LOADER_STATUS.NA &&
                            createdCosInvoiceToMap.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className='py-6 text-center text-red-600'
                                >
                                  No Data Found!
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default COSInvoiceListPage;
