import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";
import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  DOCUMENTS_TYPE,
  emptyPaginationObj,
  portalTypeOptions,
  REPORTING_LIMIT,
  SORT_TYPE,
  StatusOptions,
  StatusOptionsReporting,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_WORKER_EXPIRING_DOCUMENT_REPORTS } from "store/services/URL";

import moment from "moment";
import { FiDelete } from "react-icons/fi";

import { toast } from "react-toastify";
import {
  addMonths,
  exportTOExcel,
  formatDateIntoDMYToDisplay,
  formatDateReporting,
  truncateTextReporting,
} from "Utils/utils";
import api from "store/services/auth.services";
import PopoverContainer from "components/common/Popover/PopoverContainer";

const WorkerExpiringDocuments = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);

  const [status, setStatus] = useState(null);
  const [BirthDate, setBirthDate] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [role, setRole] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [selectedDocumentType, setSelectedDocType] = useState(
    DOCUMENTS_TYPE[0]
  );
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);
      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  useEffect(() => {
    api
      .roleAndPermissionList({
        type: portalTypeOptions[1].value,
      })
      .then((res) => {
        const rolesList = res.data;

        const Roles =
          rolesList &&
          rolesList?.length > 0 &&
          rolesList?.map((role) => ({
            label: `${role?.name}`,
            value: `${role?.name}`,
          }));
        // setRoleList((prevRoleList) => [...prevRoleList, ...Roles]);
        setRoleList([{ label: "All", value: "all" }, ...Roles]);
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  }, []);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_WORKER_EXPIRING_DOCUMENT_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_WORKER_EXPIRING_DOCUMENT_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",

      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      document_type: isClearAll
        ? "all"
        : selectedDocumentType
        ? selectedDocumentType?.value
        : "all",

      role: isClearAll ? "all" : role ? role?.value : "all",
      expire_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.documents?.total,
              totalPages: Math.ceil(
                res?.data?.data?.documents?.total /
                  res?.data?.data?.documents?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);

    // if (e === "xlsx") {
    //   exportTOExcelFile();
    // } else {
    //   fetchListReports(false, e);
    // }
  };

  const exportTOExcelFile = (reportData) => {
    let transformedData = null;
    if (reportData && reportData?.documents) {
      let preparedData = reportData?.documents;
      transformedData =
        preparedData &&
        preparedData?.length > 0 &&
        preparedData?.map((item) => {
          return {
            Support_Worker: `${item.worker.first_name} ${item.worker.last_name}`,
            Document: item.name,
            Expiry_Date:
              item.expire_date && moment(item.expire_date).format("DD/MM/YYYY"),
            Contact_Number: item.worker.phone,
            Email: item.worker.email,
            Worker_Status: item.worker.status ? "Active" : "InActive",
            Role: item?.worker.role,
            Employee_Type: item.worker.employee_type,
            Employee_Level: item.worker.salary_slab,
            Employee_Joining_Date:
              item.worker.joining_date &&
              moment(item.worker.joining_date).format("DD/MM/YYYY"),
          };
        });
    }
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full '>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Worker Expiring Document Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.WORKER_EXPIRING_DOCUMENTS_REPORTS
                )}
              />
            }
          />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by document name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Document Type
                        </label>
                        <SelectOption
                          name='document_type'
                          isSearch={true}
                          data={DOCUMENTS_TYPE || []}
                          selectedOption={selectedDocumentType}
                          handleChange={(e) => {
                            setSelectedDocType(e);
                            setReports([]);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Role
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={roleList || []}
                          selectedOption={role}
                          handleChange={(e) => {
                            setRole(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Worker Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptionsReporting || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Expiry Date Range(Max 6 months)
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 6)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>

                    <div className='w-full'></div>
                    <div className='w-full'></div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setBirthDate(null);

                            setStatus(null);
                            setSelectedWorker(null);
                            setSelectedFromDate(null);
                            setRole(null);
                            setSearch(null);
                            setReports([]);
                            setFromDate(null);
                            setToDate(null);

                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={"Support Worker"} />

                      <TableHeader
                        sortName={
                          selectedDocumentType.value === "general"
                            ? "link"
                            : "name"
                        }
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Document"}
                      />

                      <TableHeader
                        sortName={
                          selectedDocumentType.value === "general"
                            ? "expiry_time"
                            : "expire_date"
                        }
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Expiry Date"}
                      />

                      <TableHeader isSort={false} label={"Contact Number"} />

                      <TableHeader isSort={false} label={"Email"} />

                      <TableHeader isSort={false} label={"Worker Status"} />

                      <TableHeader isSort={false} label={"Role"} />

                      <TableHeader isSort={false} label={"Emp_Type"} />

                      <TableHeader isSort={false} label={"Level"} />
                      <TableHeader isSort={false} label={"Joining Date"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={10} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.documents?.data?.length > 0 ? (
                      reportList?.documents?.data?.map((document, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap py-4 text-sm'>
                            <div className='flex items-center'>
                              <div className='capitalize'>
                                <span
                                  title={`${document.worker.first_name} ${document.worker.last_name}`}
                                >
                                  <p
                                    className={`w-40 truncate cursor-pointer pl-1`}
                                  >{`${document.worker.first_name} ${document.worker.last_name}`}</p>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {/* <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "100px",
                                  textOverflow: "ellipsis",
                                }}
                                title={document?.name}
                              >
                            {document?.name || "-"}
                            </div> */}

                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {document?.name ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${document?.name}`}
                                >
                                  <span>
                                    {truncateTextReporting(document?.name)}
                                  </span>
                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {document?.expire_date
                              ? moment(document.expire_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.phone || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.email || "-"}
                          </td>

                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            <span
                              className={`${
                                document?.worker?.status
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                            >
                              {document?.worker?.status ? "Active" : "InActive"}
                            </span>
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.role || "-"}
                          </td>
                          <td className='px-2 py-[11px] text-sm text-gray-500'>
                            {document?.worker.employee_type || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.salary_slab || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.joining_date
                              ? formatDateIntoDMYToDisplay(
                                  document.worker.joining_date
                                )
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={10}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {reportList && reportList?.documents?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.documents?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default WorkerExpiringDocuments;
