import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import * as FileSaver from "file-saver";
import { toast } from "react-toastify";
import swal from "sweetalert";
import SelectOption from "components/common/Select/Select";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import { LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";
import CheckBox from "components/common/checkBox/checkbox";
import NdisClaimNewTableItem from "./NdisClaimNewTableItem";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import NdisClaimNewModal from "./NdisClaimNewModal";
import SelectBox from "components/common/DropDownWithIocns/DropDown";
import Breadcrumb from "components/common/Breadcrumb";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Header from "components/common/Header/Header";
import api from "store/services/cos.services";

const NdisClaimReview = (props) => {
  const {
    changeStatusSuccess,
    emptyTableData,
    getCaseNotesReviewRequest,
    changeStatus,
    statusList,
    selectedClientList,
    caseNoteReview,
    caseNoteReviewLoading,
    changeNDISCaseNotesStatusLoading,
    caseNotesListForClaim,
    caseNotesListForClaimLoader,
  } = props;

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [status, setStatus] = useState(null);
  const [updatedAction, setUpdatedAction] = useState(null);
  const [fileDownloaderLoader, setFileDownloaderLoader] = useState(false);

  const ndisClaimReviewToMap = useMemo(() => {
    if (caseNotesListForClaim && caseNotesListForClaim.length > 0) {
      return caseNotesListForClaim;
    }
    return [];
  }, [caseNotesListForClaim]);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? caseNoteReview.map((t) => t.id) : []);
  };

  useEffect(() => {
    if (
      caseNoteReview &&
      checkboxes &&
      checkboxes.length === caseNoteReview.length &&
      caseNoteReview.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  useEffect(() => {
    if (!caseNotesListForClaimLoader && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [caseNotesListForClaimLoader]);

  useEffect(() => {
    setCheckboxes([]);
    getCaseNotesReviewRequest({
      id: uuid,
      pt_id:
        selectedClient && selectedClient.uuid ? selectedClient.uuid : "all",
      status: status ? status.value : "all",
    });
  }, [status, selectedClient]);

  useEffect(() => {
    if (updatedAction && updatedAction && checkboxes.length > 0) {
      handleChangeAction();
    }
  }, [updatedAction]);

  // const [clientHoursReport, setClientHoursReport] = useState(false);
  // const [cosHoursReport, setCosHoursReport] = useState(false);

  // useEffect(() => {
  //   if (cosHoursReport) {
  //     const dataToPrint = convertCosDataToPrint(caseNoteReview);
  //     setPrintData(dataToPrint);
  //   }
  // }, [cosHoursReport]);

  // useEffect(() => {
  //   if (clientHoursReport) {
  //     const dataToPrint = convertClientDataToPrint(caseNoteReview);
  //     setPrintData(dataToPrint);
  //   }
  // }, [clientHoursReport]);

  const handleChangeAction = async () => {
    if (isUserGracePeriodOver) return;
    if (
      updatedAction &&
      checkboxes.length > 0 &&
      (updatedAction === "pdf" || updatedAction === "xlsx")
    ) {
      try {
        setFileDownloaderLoader(true);
        const res = await api.changeNDISClaimActionsApi({
          report: updatedAction,
          cos_case_notes_ids: checkboxes,
        });

        if (res && res.report) {
          await FileSaver.saveAs(
            res.report.download_url,
            `NDIS-Claim-${updatedAction === "xlsx" ? "EXCEL" : "PDF"}-File${
              updatedAction === "xlsx" ? ".xlsx" : ".pdf"
            }`
          );
        }
        setCheckboxes([]);
        setUpdatedAction("");
        setFileDownloaderLoader(false);
      } catch (err) {
        setUpdatedAction("");
        setFileDownloaderLoader(false);
        console.log("err", err);
        toast.error("Something went wrong while downloading!");
      }

      return;
    }
    swal({
      title: "Confirm your action?",
      text: `${getText()}`,
      icon: "",
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,
        // className: `${
        //   updatedAction === "cancelled" ? "bg-blue-600" "
        // }`,
        closeModal: true,
      },

      dangerMode: updatedAction === "cancelled" ? true : false,
    }).then((willDelete) => {
      if (isUserGracePeriodOver) return;
      if (willDelete) {
        if (checkboxes.length > 0) {
          changeStatus({
            status: updatedAction,
            cos_case_notes_ids: checkboxes,
            navigate,
          });
          setCheckboxes([]);
          setUpdatedAction("");
        }
      } else {
        setCheckboxes([]);
        setUpdatedAction("");
      }
    });
  };

  const getText = () => {
    if (updatedAction === "revert") {
      return "Are you sure you want to Revert these case notes";
    } else if (updatedAction === "cancelled") {
      return "Are you sure you want to cancel these case notes";
    } else if (updatedAction === "extract_generated") {
      return "Are you sure you want to undo cancel these case notes";
    } else if (updatedAction === "provider_paid") {
      return "Are you sure you want to Change the action to  Provider Paid";
    }
  };

  useEffect(() => {
    if (statusList && statusList) {
      statusList.unshift({ label: "All", value: "all" });
    }
  }, [statusList]);

  useEffect(() => {
    if (selectedClientList && selectedClientList) {
      selectedClientList.unshift({ label: "All", value: "all" });
    }
  }, [selectedClientList]);
  useEffect(() => {
    if (!caseNoteReview && changeStatusSuccess === true) {
      navigate("/cos/ndis-claims");
    }
  }, [caseNoteReview]);

  useEffect(() => {
    if (
      caseNoteReview &&
      caseNoteReview.length === 0 &&
      changeStatusSuccess === true
    ) {
      navigate("/cos/ndis-claims");
    }
  }, [caseNoteReview]);

  useEffect(() => {
    return () => emptyTableData();
  }, []);

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header
              title='NDIS Claim Review'
              breadcrumb={
                <Breadcrumb
                  pages={COS_BREADCRUMB(
                    COS_BREADCRUMB_PAGES.CREATED_NDIS_CLAIMS_REVIEW
                  )}
                />
              }
            />
            <div className='max-w-7xl mx-auto py-3 bg-white'>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='flex my-3 justify-between items-center py-2 sticky top-[80px] z-[8] bg-white'>
                  <div className='flex gap-x-2 w-[70%]'>
                    <div className='col-span-1 w-[25%]'>
                      <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                        Participant
                      </label>
                      <SelectOption
                        isClearable={true}
                        isDisabled={
                          caseNoteReviewLoading ||
                          changeNDISCaseNotesStatusLoading
                        }
                        cssClass=''
                        name='client'
                        isSearch={false}
                        data={selectedClientList && selectedClientList}
                        handleChange={(e) => {
                          setSelectedClient(e);
                        }}
                        selectedOption={selectedClient}
                      />
                    </div>
                    <div className='col-span-1 w-[30%]'>
                      <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                        Status
                      </label>
                      <SelectOption
                        isClearable={true}
                        isDisabled={
                          caseNoteReviewLoading ||
                          changeNDISCaseNotesStatusLoading
                        }
                        cssClass=''
                        name='sp_cos_id'
                        isSearch={false}
                        data={statusList && statusList}
                        handleChange={(e) => {
                          setStatus(e);
                        }}
                        selectedOption={status}
                      />
                    </div>
                  </div>
                  <div className=' w-56 inline-flex flex-col mt-4'>
                    <SelectBox
                      disabled={
                        (checkboxes.length > 0 ? false : true) ||
                        isUserGracePeriodOver
                      }
                      setUpdatedAction={setUpdatedAction}
                    />
                  </div>
                </div>
              </div>
              <div className='px-4 sm:px-6 lg:px-8'>
                <div className='flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='min-w-full py-2 align-middle md:px-6 lg:px-6'>
                      <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300 sticky capitalize'>
                          <thead className='bg-blue-50 capitalize'>
                            <tr>
                              <th
                                scope='col'
                                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <CheckBox
                                  isDisabled={isUserGracePeriodOver}
                                  checked={allSelected}
                                  handleChange={(e) => {
                                    if (isUserGracePeriodOver) {
                                      return;
                                    }
                                    if (
                                      caseNoteReview &&
                                      caseNoteReview.length > 0
                                    ) {
                                      setAllSelected(e);
                                      toggleCheckboxes(e);
                                    }
                                  }}
                                />
                              </th>
                              <TableHeader isSort={false} label={"Reference"} />
                              <TableHeader
                                isSort={false}
                                label={"Support Item"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Support Item No."}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Created By"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Created Date"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Participant"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Mgmt Style"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Date Delivered"}
                              />
                              <TableHeader isSort={false} label={"Quantity"} />
                              <TableHeader isSort={false} label={"Rate"} />
                              <TableHeader
                                isSort={false}
                                label={"Total Amount"}
                              />
                              <TableHeader isSort={false} label={"Status"} />
                              <th>
                                <div className='sr-only'>View</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {fileDownloaderLoader ||
                            caseNoteReviewLoading ||
                            changeNDISCaseNotesStatusLoading ? (
                              <tr>
                                <td colSpan={13} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : caseNoteReview && caseNoteReview.length > 0 ? (
                              caseNoteReview.map((item, index) => (
                                <NdisClaimNewTableItem
                                  isUserGracePeriodOver={isUserGracePeriodOver}
                                  checkboxes={checkboxes}
                                  handleCheckboxChange={(e) =>
                                    handleCheckboxChange(e, item.id)
                                  }
                                  item={item}
                                  key={index}
                                />
                              ))
                            ) : (
                              (!caseNoteReview ||
                                caseNoteReview.length === 0) && (
                                <tr>
                                  <td
                                    colSpan={13}
                                    className='py-6 text-center text-red-600'
                                  >
                                    No Data Found !
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {openModal && (
        <NdisClaimNewModal
          selectedNdisClaims={
            checkboxes &&
            checkboxes.length > 0 &&
            ndisClaimReviewToMap &&
            ndisClaimReviewToMap.length > 0
              ? ndisClaimReviewToMap.filter(
                  (x) => checkboxes.indexOf(x.id) !== -1
                )
              : []
          }
          openModel={openModal}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default NdisClaimReview;
