import React, { useState, useEffect } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import Breadcrumb from "../../../components/common/Breadcrumb/Breadcrumb";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import Header from "../../../components/common/Header/Header";
import PaginationComponent from "../../../components/common/Pagination/index";
import Search from "../../../components/common/Search/Search";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import TableHeader from "../../../components/common/Table/TableHeader";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "../../../Constants/BreadcrumbConstant";
import {
  emptyPaginationObj,
  PARTICIPANT_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant";
import { LoginContext } from "../../../helpers/LoginContext";
import { getPaginationObj } from "../../../Utils/utils";
import EXternalServiceProviderModal from "./ServiceProvicerModal";
import { HiEye, HiOutlineTrash } from "react-icons/hi";
import DeleteExterenalServiceModal from "./DeleteExternalServiceProvider";
import ViewExternalServiceProviderModal from "./ViewExternalServiceProviderModal";
import EditExternalServiceModal from "./EditExternalServiceProviderModal";

const ServiceProvider = (props) => {
  const {
    getExternalSPListRequest,
    loading,
    externalSPList,
    createExternalSP,
    catalogStates,
    getExternalSubCategory,
    ExternalSpCreateLoader,
    externalSpCreateError,
    isUserGracePeriodOver,
  } = props;

  const { sideBarPadding } = React.useContext(LoginContext);

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedServiceProvider, setselectedServiceProvider] = useState({});
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );

  useEffect(() => {
    getExternalSubCategory();
  }, []);

  const externalSP = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PARTICIPANT_LIST_LIMIT });
    if (filters.status === "both") {
      Object.assign(filters, { status: undefined });
    }
    getExternalSPListRequest(filters);
  };

  useEffect(() => {
    if (externalSPList) {
      setPaginationObj(
        getPaginationObj(externalSPList, externalSPList.query_params)
      );
    }
  }, [externalSPList]);

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (
      !search &&
      parseInt(externalSPList?.query_params?.page) !== paginationObj.page
    )
      handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    externalSP({ ...obj });
  };

  const refreshServiceProviderList = () => {
    externalSP();
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };
  return (
    <div className='min-h-screen'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='flex-1 bg-white'>
          <Header
            title='External Service Providers'
            breadcrumb={
              <Breadcrumb
                pages={COS_BREADCRUMB(
                  COS_BREADCRUMB_PAGES.EXTERNAL_SERVICE_PROVIDERS
                )}
              />
            }
            isButton
            handleClick={() => setOpen(true)}
            btnLabel='Service Provider'
            icon={<BiPlus />}
          />
          <div className='max-w-7xl mx-auto'>
            <div
              style={{ paddingTop: "15px", top: "70px" }}
              className='flex sm:px-6 lg:px-8 justify-between items-center py-2 bg-white sticky top-20 '
            >
              <div className='w-1/3 md:w-2/12 lg:w-2/12' />
              <Search setSearch={setSearch} search={search} />
            </div>

            <div className='sm:px-6 lg:px-8'>
              <div className='border md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50 sticky top-[7.8rem] capitalize'>
                    <tr>
                      <TableHeader
                        sortName='name'
                        isSort={true}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Service Provider"}
                      />
                      <th
                        scope='col'
                        className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Contact Information
                      </th>
                      <th
                        scope='col'
                        className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Billing Contact
                      </th>
                      <th
                        scope='col'
                        className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loading ? (
                      <tr>
                        <td colSpan={3} className='bg-gray-50'>
                          <CircularLoader
                            classes='flex justify-center items-center'
                            classloader='loader-l'
                          />
                        </td>
                      </tr>
                    ) : externalSPList &&
                      externalSPList?.data &&
                      externalSPList?.data.length > 0 ? (
                      externalSPList.data.map((item, index) => (
                        <tr
                          key={index}
                          className='text-left even:bg-white odd:bg-gray-50'
                        >
                          <td className='capitalize whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
                            {item.name}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {item.phone}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {item.billing_contact}
                          </td>
                          <td>
                            <button
                              className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md mr-2'
                              onClick={() => {
                                setselectedServiceProvider(item);
                                setOpenEditModal(true);
                              }}
                            >
                              <div className='icon-pencil-wrapper' title='Edit'>
                                <BiPencil className='icon-size' />
                              </div>
                            </button>

                            <button
                              className='mr-2'
                              onClick={() => {
                                setselectedServiceProvider(item);
                                setOpenViewModal(true);
                              }}
                            >
                              <div className='icon-eye-wrapper' title='View'>
                                <HiEye className='icon-size' />
                              </div>
                            </button>

                            <button
                              onClick={() => {
                                setselectedServiceProvider(item);
                                setOpenDeleteModal(true);
                              }}
                            >
                              <div
                                className='icon-delete-wrapper'
                                title='Delete'
                              >
                                <HiOutlineTrash className='icon-size' />
                              </div>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={3}
                          className='py-6 text-center text-red-600'
                        >
                          No data found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {externalSPList && (
              <div className='sm:px-6 lg:px-8 py-2'>
                <PaginationComponent
                  page={paginationObj.page}
                  activeListLength={externalSPList?.data || []}
                  limit={PARTICIPANT_LIST_LIMIT}
                  totalList={paginationObj.total}
                  handlePagination={(e) => {
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                    handleFetch({}, sortBy);
                  }}
                  totalPages={paginationObj.totalPages}
                />
              </div>
            )}
          </div>
        </main>
      </div>

      {open && (
        <EXternalServiceProviderModal
          catalogStates={catalogStates}
          open={open}
          setOpen={setOpen}
          createExternalSP={createExternalSP}
          ExternalSpCreateLoader={ExternalSpCreateLoader}
          externalSpCreateError={externalSpCreateError}
        />
      )}
      {openDeleteModal && (
        <DeleteExterenalServiceModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          isUserGracePeriodOver={isUserGracePeriodOver}
          selectedServiceProvider={selectedServiceProvider}
          handleClose={() => setOpenDeleteModal(false)}
          refreshServiceProviderList={refreshServiceProviderList}
        />
      )}
      {openViewModal && (
        <ViewExternalServiceProviderModal
          open={openViewModal}
          setOpen={setOpenViewModal}
          isUserGracePeriodOver={isUserGracePeriodOver}
          handleClose={() => setOpenViewModal(false)}
          selectedServiceProvider={selectedServiceProvider}
        />
      )}
      {openEditModal && (
        <EditExternalServiceModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          isUser
          GracePeriodOver={isUserGracePeriodOver}
          handleClose={() => setOpenEditModal(false)}
          selectedServiceProvider={selectedServiceProvider}
          refreshServiceProviderList={refreshServiceProviderList}
        />
      )}
    </div>
  );
};

export default ServiceProvider;
