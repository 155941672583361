import React, { useContext, useEffect, useState } from "react";
import crossIcon from "assets/svg/Button-close-X.svg";
import SelectOption from "components/common/Select/Select";
// import { Link } from "react-router-dom";
import arrowRightIcon from "assets/svg/arrow-right-white.svg";
import ExportSelectedTimesheetItem from "./ExportSelectedTimesheetItem";
import CustomButton from "components/common/Button/CustomButton";
import { useNavigate } from "react-router";
import {
  capitalizeFirstLetter,
  classNames,
  parseBooleanVal,
} from "Utils/utils";
import { LoginContext } from "helpers/LoginContext";

const ExportedSelectedTimesheet = (props) => {
  const {
    selectedWorkers = [],
    handleChildItemTimeSheet,
    singleTimesheetDetailsRequest,
    singleTimesheetDetailsLoader,
    singleTimesheetDetails,
    // handleChangeStatusPaid,
    // paidStatusChangeStatusLoader,
    isAuthenticateForPayrolls,
    handlePayrollExportTimeSheet,
    exportTimesheetToPayrollLoader,
  } = props;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [selectedPayrollError, setSelectedPayrollError] = useState("");

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const navigate = useNavigate();

  useEffect(() => setSelectedPayroll(null), []);

  const authenticatedPayrollOptions = () => {
    if (isAuthenticateForPayrolls !== null) {
      return (
        Object.keys(isAuthenticateForPayrolls)
          // .filter((p) => parseBooleanVal(isAuthenticateForPayrolls[p]))
          .map((item) => ({
            value: item,
            label: capitalizeFirstLetter(item)?.replace("_", ""),
          }))
      );
    }
    return [];
  };

  const handleExportTimeSheets = () => {
    if (isUserGracePeriodOver) return;
    if (selectedPayroll === null) {
      setSelectedPayrollError("This field is required!");
      return;
    }
    if (selectedPayroll && selectedPayroll?.value) {
      handlePayrollExportTimeSheet(selectedPayroll.value);
      handleCloseSideMenu();
    }
  };

  const handleCloseSideMenu = () => {
    setSelectedPayrollError("");
    setSelectedPayroll(null);
    setIsShowPopup(false);
  };

  const checkanyAuthenticatedPayroll = () => {
    if (
      isAuthenticateForPayrolls === null ||
      isAuthenticateForPayrolls === false
    )
      return false;
    else if (
      Object.values(isAuthenticateForPayrolls || {})?.some((x) => x === true)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <section className=''>
        <div className='flex gap-4 justify-end w-full'>
          {selectedWorkers.length > 0 && checkanyAuthenticatedPayroll() ? (
            <CustomButton
              clickHandler={() => {
                if (isUserGracePeriodOver) return;
                setIsShowPopup(true);
              }}
              variant='tertiary'
              label={"Export to Payroll"}
              cssClass={classNames(
                "bg-gradient-to-r from-blue-500 to-blue-800 rounded-full shadow-sm py-3 px-5 text-white text-sm font-medium text-center",
                isUserGracePeriodOver === true
                  ? "cursor-auto"
                  : "cursor-pointer"
              )}
              loaderCssClass={"text-black"}
              showLoading={exportTimesheetToPayrollLoader}
              isDisabled={
                exportTimesheetToPayrollLoader || isUserGracePeriodOver
              }
            />
          ) : selectedWorkers.length > 0 &&
            (isAuthenticateForPayrolls === null ||
              isAuthenticateForPayrolls === false ||
              !checkanyAuthenticatedPayroll()) ? (
            <CustomButton
              isDisabled={isUserGracePeriodOver}
              clickHandler={() => {
                if (isUserGracePeriodOver) {
                  return;
                }
                navigate("/payroll-setting");
              }}
              variant='tertiary'
              label={"Connect Payroll"}
              cssClass='bg-gradient-to-r from-blue-500 to-blue-800 rounded-full shadow-sm py-3 px-5 text-white text-sm font-medium text-center cursor-pointer'
            />
          ) : (
            <></>
          )}
          {/* <CustomButton
                loaderCssClass={"text-black"}
                clickHandler={handleChangeStatusPaid}
                cssClass={
                  "bg-gray-50 border-gray-300 border border-solid rounded-full shadow-sm py-3 px-5 text-gray-600 text-sm font-medium text-center cursor-pointer"
                }
                variant={"tertiary"}
                label='Mark Selected Items as Paid'
                showLoading={paidStatusChangeStatusLoader}
                isDisabled={paidStatusChangeStatusLoader}
              /> */}
        </div>
        <div className='overflow-x-auto mt-8'>
          {selectedWorkers.map((item, index) => (
            <ExportSelectedTimesheetItem
              isUserGracePeriodOver={isUserGracePeriodOver}
              isAuthenticateForPayrolls={isAuthenticateForPayrolls}
              singleTimesheetDetailsLoader={singleTimesheetDetailsLoader}
              singleTimesheetDetailsRequest={singleTimesheetDetailsRequest}
              singleTimesheetDetails={singleTimesheetDetails}
              handleChildItemTimeSheet={handleChildItemTimeSheet}
              workerTimeSheets={item}
              key={index}
            />
          ))}
        </div>
      </section>
      {isShowPopup && (
        <>
          <div className='fixed h-full overflow-y-auto max-w-[400px] w-full p-6 top-0 right-0 z-[50] bg-white'>
            <div className='relative'>
              <img
                onClick={handleCloseSideMenu}
                src={crossIcon}
                alt='crossIcon'
                className='absolute cursor-pointer top-[-8px] right-3'
              />
              <h4 className='text-gray-400 text-xl font-bold'>
                Export to Payroll
              </h4>
              <p className='mt-2 text-sm text-gray-500 leading-6 font-normal'>
                Vertex360 Third Party Integrated Payrolls
              </p>
            </div>
            <div className='bodyContent'>
              <div className='bg-blue-50 p-3 mt-3 rounded-md'>
                <h5 className='text-gray-500 text-sm font-bold leading-6'>
                  Disclaimer
                </h5>
                <p className='text-sm text-[#4D5565] font-normal leading-6'>
                  While Vertex360 takes care to provide a quality pay rules
                  library, it does not guarantee (or accept responsibility for)
                  the accuracy, completeness or up to date nature of the
                  library, or that it will be suitable for the particular
                  circumstances of your business. You're advised to consult with
                  your legal and payroll advisers before relying on Vertex360’s
                  interpretation.
                </p>
              </div>
              <div>
                <label className='inline-block mb-2 mt-3 text-sm text-gray-700 font-medium leading-5'>
                  Integrated Payroll
                </label>
                <SelectOption
                  handleChange={(e) => {
                    setSelectedPayroll(e);
                    setSelectedPayrollError("");
                  }}
                  data={authenticatedPayrollOptions()}
                  selectedOption={selectedPayroll}
                  errorMessage={selectedPayrollError}
                />
              </div>
              <div className=''>
                {/* <label className='text-sm text-gray-700 font-medium leading-5 inline-block mb-2 mt-3'>
                  Please enter your API Key
                </label> */}
                {/* <textarea
                  className='w-full block resize-none rounded-md'
                  placeholder='enter your key here'
                ></textarea> */}
                {/* <span className='text-sm text-gray-500 font-normal'>
                  Enter the KeyPay API key. If you do not have it, please go to
                  &nbsp;
                  <Link to='https://keypay.com.au/api' className='underline'>
                    https://keypay.com.au/api
                  </Link>
                </span> */}
                {/* <label className='text-sm text-gray-700 font-medium leading-5 block mb-2 mt-3'>
                  Override Business ID
                </label>
                <input
                  type='text'
                  className='w-full input-lg rounded-md border border-gray-300 p-2 shadow-md'
                  placeholder='360'
                /> */}
              </div>
            </div>
            <footer className='w-[90%] absolute bottom-[10px] py-2 flex justify-end gap-3 mt-4'>
              <CustomButton
                label='Cancel'
                clickHandler={handleCloseSideMenu}
                variant='tertiary'
                cssClass='border border-gray-300 p-2 px-4 shadow-md bg-white rounded-full text-gray-700 text-sm font-bold leading-6'
              />
              {isAuthenticateForPayrolls !== null &&
              selectedPayroll &&
              !parseBooleanVal(
                isAuthenticateForPayrolls[selectedPayroll.value]
              ) ? (
                <CustomButton
                  isDisabled={isUserGracePeriodOver}
                  clickHandler={() => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    navigate("/payroll-setting");
                  }}
                  variant='tertiary'
                  label={"Connect Payroll"}
                  cssClass='bg-gradient-to-r from-blue-500 to-blue-800 rounded-full shadow-sm py-3 px-5 text-white text-sm font-medium text-center cursor-pointer'
                />
              ) : (
                <CustomButton
                  label='Export Timesheet'
                  Icon={<img src={arrowRightIcon} alt='arrowRight' />}
                  // isDisabled={selectedPayroll === null}
                  clickHandler={handleExportTimeSheets}
                  variant='tertiary'
                  cssClass='bg-gradient-to-r from-blue-500 to-blue-800 rounded-full shadow-sm p-2 px-4 text-white text-sm font-medium text-center cursor-pointer'
                  // cssClass='border inline-flex gap-x-2 px-4 items-center border-blue-800 p-2 shadow-md bg-gradient-to-r from-blue-500 to-blue-800 rounded-full text-white text-sm font-bold leading-6'
                />
              )}
            </footer>
          </div>
          <div className='overlay bg-black bg-opacity-50 fixed inset-0 w-full h-full z-[11]'></div>
        </>
      )}
    </>
  );
};

export default ExportedSelectedTimesheet;
