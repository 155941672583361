import React, { useEffect, useRef } from "react";
import { classNames } from "Utils/utils";
import closeIcon from "assets/close-icon.png";

const WorkerShiftModal = ({
  isOpen,
  onClose, // This is the function passed from parent to control modal visibility
  closeModal, // Optional function passed to close the modal
  children,
  modalFooter = null,
  title = "",
  titleCss = null,
  cssClass,
  bodyPadding = "p-4", // Default body padding
}) => {
  const modalRef = useRef(null);

  // Close modal on outside click
  //   useEffect(() => {
  //     if (!isOpen) return; // No need to handle click events when modal is closed

  //     const handleOutsideClick = (event) => {
  //       if (modalRef.current && !modalRef.current.contains(event.target)) {
  //         closeModal && closeModal();
  //       }
  //     };

  //     document.addEventListener("click", handleOutsideClick);
  //     return () => {
  //       document.removeEventListener("click", handleOutsideClick);
  //     };
  //   }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Re-enable scroll
    }

    // Cleanup the effect to reset body scroll when modal is closed
    return () => {
      document.body.style.overflow = ""; // Reset overflow in case of component unmount
    };
  }, [isOpen]);

  if (!isOpen) return null; // Don't render modal if it's closed

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-[9999]'>
      {/* Modal Container */}
      <div
        ref={modalRef}
        className={classNames(
          cssClass,
          "bg-white rounded-lg shadow-xl transform transition-all w-full z-60 relative borderRadiusX"
        )}
      >
        {/* Modal Header */}
        <div
          className={classNames(
            " p-4 relative border-b border-gray-400 rounded-t-xl",
            title === "" && "h-[57px]",
            titleCss ? titleCss : "bg-gradient"
          )}
        >
          {title && (
            <h3
              className={classNames(
                "text-lg font-semibold pr-4 leading-6 tracking-wider text-white",
                titleCss !== null && "text-black text-center"
              )}
            >
              {title}
            </h3>
          )}
          {closeModal && (
            <div
              className='absolute right-4 top-4 text-white cursor-pointer'
              onClick={closeModal}
            >
              <img
                src={closeIcon}
                alt='close icon'
                className='h-5 max-w-[20px] w-5'
              />
            </div>
          )}
        </div>

        {/* Modal Body */}
        <div
          className={`p-3 ${bodyPadding} max-h-[70vh] overflow-y-auto custom-scroll `}
        >
          {children}
        </div>

        {/* Modal Footer (optional) */}
        {modalFooter && (
          <div className='bg-gray-50 p-3 border-t border-gray-200 flex justify-end gap-x-2'>
            {modalFooter}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerShiftModal;
