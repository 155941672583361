import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  addFiltersAndPagination,
  apiFormDataRequest,
  apiGetRequest,
} from "../../../../helpers/Requests";
import CustomButton from "../../../../components/common/Button/CustomButton";
import FileUploadInput from "../../../../components/common/FileUpload/FileUploadInput";
import InputField from "../../../../components/common/Input/Input";
import Select from "../../../../components/common/Select/Select";
import { getPaginationObj, hasBlankSpaces } from "../../../../Utils/utils";
import {
  emptyPaginationObj,
  SORT_TYPE,
  WORKER_CATEGORY_DOCUMENT,
  WORKER_DOCUMENT_LIMIT,
} from "../../../../Constants/constant";
import DocumentsNew from "../../../HRM/Pages/Worker/Documents/index";
import { LoginContext } from "../../../../helpers/LoginContext";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import { toast } from "react-toastify";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import Header from "../../../../components/common/Header/Header";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";
import { roleAccess } from "helpers/RolesPermission";
import { useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";

const Documents = (props) => {
  const { FolderListForHRM, removeFolderRequest, removeFileRequest } = props;
  const { sideBarPadding } = useContext(LoginContext);

  const user = useSelector(getUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDocument, setIsAddDocument] = useState(false);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [storeDocLoader, setStoreDocLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    getDocumentsList();
  }, []);
  //to get documents list
  const getDocumentsList = async () => {
    try {
      const res = await apiGetRequest("/worker/employee/documents-list");
      if (res && res.status === 200) {
        setDocumentList(res.data?.data?.[1]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getDocuments = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: getPaginationObj.page,
      });
    }
    Object.assign(filters, { per_page: WORKER_DOCUMENT_LIMIT });
    try {
      setIsLoading(true);
      let res = await apiGetRequest(
        addFiltersAndPagination("/hrm/employee-docs", filters)
      );
      if (res.status === 200) {
        // setUserDocument(res?.data?.data?.employee_documents);
        const uploadedDoc = res?.data?.data?.employee_documents.data;
        setDocuments(uploadedDoc);
        setPaginationObj(
          getPaginationObj(
            res?.data?.data?.employee_documents,
            res?.data?.data?.query_params
          )
        );
      }
      if (res.status !== 200) {
        console.log("error in fetching");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    handleFetch({});
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page || obj.hasOwnProperty("page")) {
      Object.assign(obj, { page: obj.page ? obj.page : paginationObj.page });
    }
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }

    if (
      roleAccess(
        [],
        PERMISSION_KEYS.DOCUMENT,
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT.SHOW
      )
    ) {
      getDocuments({ ...obj });
    }
  };

  const handleSubmit = async () => {
    if (isValidInputs()) return;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", fileName);
    formData.append("file_type", selectedFileType.value);

    try {
      setStoreDocLoader(true);
      const res = await apiFormDataRequest(
        "hrm/employee/upload-document",
        formData
      );
      if (res) {
        if (res.status === 200) {
          handleClear();
          setStoreDocLoader(false);
          const obj = documents || [];
          obj.data.unshift(res.data.data);
          setDocuments({ ...obj });
          setPaginationObj({
            ...paginationObj,
            totalPages: paginationObj.total + 1,
          });
          handleCloseAddDocument();
          toast.success("Document uploaded successfully");
        }
      }
      setStoreDocLoader(false);
    } catch (err) {
      console.log(err);
      setStoreDocLoader(false);
    }
  };

  useEffect(() => {
    if (file && getError("File")) {
      setErrors({ ...errors, File: null });
    }
    if (Object.keys(selectedFileType).length > 0 && getError("FileType")) {
      setErrors({ ...errors, FileType: null });
    }
    if (file && selectedFileType && fileName) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [file, selectedFileType, fileName]);

  const getError = (value) => {
    if (!value) return "";
    // return errors[value] && errors[value].length > 0 ? errors[value] : "";
    return errors[value] && errors[value].length > 0
      ? "The Field is required!"
      : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (errors[name] && !hasBlankSpaces(value) && value.length > 0) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleClear = () => {
    setSelectedFileType("");
    setFile("");
    setFileName("");
  };

  const isValidInputs = () => {
    const fnError = errors;
    if (!fileName || fileName.length > 254) {
      Object.assign(fnError, { FileName: "Max Length Exceed" });
    }
    if (!fileName || fileName.length === 0) {
      Object.assign(fnError, { FileName: "File Name is Required" });
    }
    if (!file || file.length === 0) {
      Object.assign(fnError, { File: "File is Required" });
    }
    if (!selectedFileType || Object.keys(selectedFileType).length <= 0) {
      Object.assign(fnError, { FileType: "Category is Required" });
    }
    if (Object.values(fnError).filter((x) => x !== null).length > 0) {
      setErrors({ ...fnError });
      setDisabledButton(true);
      return true;
    }
    setDisabledButton(false);
    return false;
  };

  const handleCloseAddDocument = () => {
    setIsAddDocument(false);
  };

  const modalFooter = () => (
    <CustomButton
      type='submit'
      showLoading={storeDocLoader}
      isDisabled={storeDocLoader || disabledButton}
      label='Upload Document'
      clickHandler={() => {
        handleSubmit();
        const buttonRef = document.getElementById("createEmployeeDocument");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <>
      <div className='min-h-full bg-white'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          <main className='flex-1'>
            <Header
              title='My Documents'
              breadcrumb={
                <Breadcrumb
                  pages={EMPLOYEE_BREADCRUMB(
                    EMPLOYEE_BREADCRUMB_PAGES.ADD_DOCUMENTS
                  )}
                />
              }
              // handleClick={() => setIsAddDocument(true)}
              // btnLabel='Add New Documents'
              // icon={<BiPlus />}
              // btnCss=''
              // variants='btn-purple'
              // isButton
            />

            <main className='max-w-7xl mx-auto px-6'>
              <DocumentsNew
                getDocumentsListForProgressBar={getDocumentsList}
                removeFolderRequest={removeFolderRequest}
                removeFileRequest={removeFileRequest}
                handleCloseAddDocument={handleCloseAddDocument}
                isAddDocument={isAddDocument}
                worker={user?.employee_uuid}
                requiredDocuments={true}
                workerId={user?.employee_id}
                FolderListForHRM={FolderListForHRM}
                documentList={documentList || []}
                documents={documents}
                setDocuments={setDocuments}
              />
            </main>
          </main>
        </div>
      </div>

      {isAddDocument && (
        <ModalSkeleton
          cssClass='w-[55%]'
          title='Upload Document'
          modalFooter={modalFooter()}
          isOpen={isAddDocument}
          closeModal={() => {
            handleCloseAddDocument();
            setErrors([]);
            handleClear();
          }}
        >
          {
            <div className='flex flex-col'>
              <div className='overflow-x-auto first-letter:scrollbar-hide'>
                <div className='inline-block table-wrp min-w-full py-2 align-middle'>
                  <div>
                    <FileUploadInput
                      type='any'
                      handleFile={(e) => setFile(e)}
                      size='2'
                      file={file}
                      handleDelete={() => setFile("")}
                    />
                    {getError("File") && (
                      <span className='errorMessage '>{getError("File")}</span>
                    )}
                  </div>

                  <div className='mt-4 flex items-start justify-between gap-4'>
                    <div className='did-floating-label-content w-1/2'>
                      <label
                        htmlFor='interview-questions'
                        className='block text-sm mb-1 font-medium text-gray-700'
                      >
                        Category
                      </label>
                      <Select
                        name='FileType'
                        isSearch={false}
                        data={WORKER_CATEGORY_DOCUMENT}
                        selectedOption={selectedFileType}
                        // placeHolder="Select Category"
                        handleChange={(e) => setSelectedFileType(e)}
                      />
                      {getError("FileType") && (
                        <span className='errorMessage '>
                          {getError("FileType")}
                        </span>
                      )}
                    </div>
                    <div className='did-floating-label-content w-1/2'>
                      <InputField
                        maxLength={200}
                        value={fileName}
                        name='FileName'
                        blurHandler={handleBlur}
                        label='File Name'
                        type='text'
                        // placeHolder="File Name"
                        handleChange={setFileName}
                        ErrorMessage={getError("FileName")}
                      />
                    </div>
                  </div>
                  <button
                    type='submit'
                    className='hidden sr-only'
                    id='createEmployeeDocument'
                  />
                </div>
              </div>
            </div>
          }
        </ModalSkeleton>
      )}
    </>
  );
};

export default Documents;
