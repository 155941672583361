/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  lazy,
  Suspense,
  useMemo,
} from "react";
import { LoginContext } from "helpers/LoginContext";
import Header from "components/common/Header/Header";
import SideBar from "components/common/SideBar/SideBarNew";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import DateRangeSelect from "components/common/Select/DateRangeSelect";
import Loader from "components/common/Loader/Loader";
import { formatDateIntoDMY } from "Utils/utils";
import refreshIcon from "assets/svg/refresh.svg";
import SelectOption from "components/common/Select/Select";
// import imgThree from "assets/images/Rectangle 1 (3).png";
// import imgOne from "assets/images/Rectangle 1 (1).png";
// import imgTwo from "assets/images/Rectangle 1 (2).png";
import { MdPublish } from "react-icons/md";
import { showToast } from "store/actions/general.actions";
import { useDispatch, useSelector } from "react-redux";
import PubishShiftModal from "./PublishShiftModal/PublishShiftModal";
import CustomButton from "components/common/Button/CustomButton";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import {
  LOADER_STATUS,
  ROSTERING_CALENDAR_DATA_LIMIT,
  TOAST_TYPE,
  calendarViewOptions,
  emptyPaginationObj,
} from "Constants/constant";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import {
  ROSTERING_BREADCRUMB,
  ROSTERING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Breadcrumb from "components/common/Breadcrumb";
import { BiExit, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router";
import CopyShift from "components/Modals/CopyShift";
import { MdContentCopy } from "react-icons/md";
import MessageModal from "components/Modals/RecurringModal/MessageModal";
import { TiExportOutline } from "react-icons/ti";
import { classNames } from "Utils/utils";
import ExportRosterModal from "./ExportRoster/ExportRosterModal";
import { apiGetRequest } from "helpers/Requests";
import WorkerShiftModal from "../WorkerShift";
import {
  getSearchtextOfScheduleShifts,
  getWorkerAndParticipantOptionsPagination,
} from "store/selector/rostering.selector";
import _, { isArray, isEmpty } from "lodash";
import { updateCalendarSearchLocally } from "store/actions/rostering.actions";

const Calendar = lazy(() => import("../Calendar/Calendar"));

const Schedule = (props) => {
  const {
    workerTimeSheetExportListRequest,
    workerTimeSheetExportList,
    workerAndParticipantOptions,
    getWorkerAndParticipantList,
    // sortCalendarShiftsItem,
    workerAndPartsListLoader,
    // isSortCalendarShiftsItem,
    calendarDateChangeLocally,
    workerAndParticipantShiftCopyRequest,
    copyWorkerShiftLoader,
    copyWorkerShiftError,
    isRecurringModalShow,
    recurringMessageModal,
    recurringMessages,
    spRosteringSettingsRequest,
    spRosteringSettingLoader,
    spRosteringSetting,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const myRef = useRef(null);
  const dispatch = useDispatch();
  const [workerOptions, setWorkerOptions] = useState([]);
  const [participantOptions, setParticipantOptions] = useState([]);
  const [calendarView, setCalendarView] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isShowPublish, setIsShowPublish] = useState(false);
  const [expandedFooter, setExpandedFooter] = useState(false);
  const [initialCopyLoader, setInitialLoader] = useState(LOADER_STATUS.NA);
  const [reachedLast, setReachedLast] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [isPaginationLoading, setIsPaginationLoading] = useState(
    LOADER_STATUS.NA
  );
  const [copiedText, setCopiedText] = useState(null);
  const [isOpenCopyShift, setCopyShifts] = useState(false);
  const [fortnightlyCopyShiftsMessage, setForthnightlyCopyShiftsMessage] =
    useState(null);
  const [isMessageForCopyShift, setIsMessage] = useState(false);
  const navigate = useNavigate();
  const [isShowCsvModal, setShowCSVModal] = useState(false);
  const [serviceCategoriesTypes, setServiceCategoriesTypes] = useState([]);
  const [isServiceCategoriesFetchCall, setIsServiceCategoriesFetchCall] =
    useState(false);

  const [isOpenShiftModal, setIsOpenShiftModal] = useState(false);

  const [isSortCalendarShiftsItem, setIsSortCalendarShiftsItem] =
    useState("asc");

  const searchText = useSelector(getSearchtextOfScheduleShifts);
  const [selectedDateTypes, setSelectedDateTypes] = useState(null);

  const [selectedOps, setSelectedops] = useState(null);

  useEffect(() => {
    if (selectedOps === null) {
      return;
    }
    const payload = { isRefreshCall: true };
    // if (calendarView === "participant-view") {
    //   Object.assign(payload, {
    //     participants: selectedOps.map((x) => x.value),
    //   });
    // } else {
    //   Object.assign(payload, {
    //     workers: selectedOps.map((x) => x.value),
    //   });
    // }
    refreshCalendar(payload);
  }, [selectedOps]);

  useEffect(() => {
    scrollToRef(myRef);
  }, [myRef]);

  const scrollToRef = (ref) => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  };

  const scrollablePagination = (reached, stopScrollPagination = false) => {
    // console.log("scrooll", { paginationObj, stopScrollPagination });
    if (stopScrollPagination) return;
    if (
      (reached || reachedLast) &&
      paginationObj.totalPages > paginationObj.page &&
      !workerAndPartsListLoader
    ) {
      const obj = paginationObj;
      obj.page = paginationObj.page + 1;
      setPaginationObj({ ...obj });
      setIsPaginationLoading(LOADER_STATUS.PENDING);
      refreshCalendar({ page: obj.page });
    }
  };

  useEffect(() => {
    if (startDate && endDate && calendarView?.value) {
      setIsPaginationLoading(LOADER_STATUS.NA);
      const payload = { isRefreshCall: true };
      if (!isEmpty(searchText)) {
        Object.assign(payload, {
          search: searchText,
        });
      }
      refreshCalendar(payload);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (calendarView && endDate && startDate) {
      setIsPaginationLoading(LOADER_STATUS.NA);
      refreshCalendar({ isRefreshCall: true, notAddOps: true });
      setSelectedops(null);
    }
  }, [calendarView]);

  useEffect(() => {
    if (workerAndParticipantOptions) {
      const { pagination, workers, participants } = workerAndParticipantOptions;
      setWorkerOptions(workers ? [...workers] : []);
      setParticipantOptions(participants ? [...participants] : []);
      setPaginationObj({ ...pagination });
    }
  }, [workerAndParticipantOptions]);

  // const expandedFooterContent = [
  //   {
  //     img: imgOne,
  //     title: "Culturally Responsive NDIS Support",
  //     price: "$149",
  //   },
  //   {
  //     img: imgTwo,
  //     title: "Assistive Technology for NDIS Participants",
  //     price: "$249",
  //   },
  //   {
  //     img: imgThree,
  //     title: "NDIS Compliance Essentials for Providers",
  //     price: "$299",
  //   },
  // ];

  const handleDateChange = (dates) => {
    setSelectedDateTypes(dates);
    if (dates && dates?.startDate) {
      calendarDateChangeLocally({
        date: dates.startDate,
        view: dates?.view || "current",
      });
      setStartDate(dates.startDate);
    }
    if (dates && dates?.endDate) {
      setEndDate(dates.endDate);
    }
  };

  const refreshCalendar = (obj = {}) => {
    if (calendarView && startDate && endDate) {
      const payload = {
        data: {
          page: obj?.page || 1,
          per_page: ROSTERING_CALENDAR_DATA_LIMIT,
          type: calendarView.value === "worker-view" ? "worker" : "participant",
          from_date: formatDateIntoDMY(startDate),
          to_date: formatDateIntoDMY(endDate),
          sort: obj.hasOwnProperty("sort")
            ? obj.sort
            : isSortCalendarShiftsItem,
        },
        calendarView: calendarView.value,
      };

      if (obj && obj.hasOwnProperty("isRefreshCall")) {
        setIsPaginationLoading(LOADER_STATUS.NA);
        Object.assign(payload, {
          isRefreshCall: true,
        });
      }
      if (obj && obj.hasOwnProperty("search")) {
        setIsPaginationLoading(LOADER_STATUS.NA);
        Object.assign(payload.data, {
          search: obj.search,
        });
      }

      if (
        calendarView?.value === "worker-view" &&
        selectedOps !== null &&
        selectedOps?.length > 0 &&
        !obj.hasOwnProperty("notAddOps")
      ) {
        setIsPaginationLoading(LOADER_STATUS.PENDING);
        Object.assign(payload.data, {
          workers: selectedOps.map((x) => x.value),
          per_page: 100,
        });
      } else if (
        calendarView?.value === "participant-view" &&
        selectedOps?.length > 0 &&
        selectedOps !== null &&
        !obj.hasOwnProperty("notAddOps")
      ) {
        setIsPaginationLoading(LOADER_STATUS.PENDING);
        Object.assign(payload.data, {
          participants: selectedOps.map((x) => x.value),
          per_page: 100,
        });
      }
      getWorkerAndParticipantList(payload);
    }
  };

  const clearClipboard = () => {
    setCopiedText(null);
  };

  const handlePasteShift = ({ calendarDate, userId }) => {
    const currentTimeZone = momentTimeZone.tz.guess();
    const payloadData = {
      timezone: currentTimeZone,
      uuid: copiedText.uuid,
      shift_date: moment(new Date(calendarDate)).toISOString(),
      pt_id:
        calendarView.value === "participant-view"
          ? [userId]
          : Array.isArray(copiedText.pt_id)
          ? [...copiedText?.pt_id]
          : [copiedText.pt_id],
      worker_id:
        calendarView.value === "worker-view"
          ? [userId]
          : [copiedText.worker_id],
    };

    setInitialLoader(LOADER_STATUS.PENDING);
    workerAndParticipantShiftCopyRequest({
      payload: {
        ...payloadData,
      },
      calendarView: calendarView.value,
      userId: userId,
    });
  };

  useEffect(() => {
    if (
      !copyWorkerShiftLoader.hasOwnProperty("data") &&
      initialCopyLoader === LOADER_STATUS.PENDING &&
      copyWorkerShiftError.length === 0
    ) {
      clearClipboard();
      setInitialLoader(LOADER_STATUS.COMPLATE);
    }
  }, [copyWorkerShiftLoader]);

  useEffect(() => {
    if (copiedText) {
      dispatch(showToast({ type: TOAST_TYPE.DEFAULT, title: "Shift copied!" }));
    }
  }, [copiedText]);

  useEffect(() => {
    spRosteringSettingsRequest();

    return () => {
      clearClipboard();
      dispatch(updateCalendarSearchLocally(null));
    };
  }, []);

  useEffect(() => {
    if (spRosteringSetting?.id) {
      setCalendarView(
        calendarViewOptions?.find(
          (x) =>
            x.value === "worker-view" &&
            spRosteringSetting.roster_default_view === "worker"
        )
          ? calendarViewOptions[1]
          : calendarViewOptions[0]
      );
    }
  }, [spRosteringSetting]);

  useEffect(() => {
    if (!workerAndPartsListLoader && isPaginationLoading !== LOADER_STATUS.NA) {
      setIsPaginationLoading(LOADER_STATUS.COMPLATE);
    }
  }, [workerAndPartsListLoader]);

  useEffect(() => {
    setCopiedText(null);
  }, [calendarView]);

  const getMaxHeight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 900) {
      return 400;
    } else if (screenWidth < 1280) {
      return 500;
    } else {
      return 600; // default max height
    }
  };

  useEffect(() => fetchServiceCategoriesTypes(), []);

  const fetchServiceCategoriesTypes = () => {
    setIsServiceCategoriesFetchCall(true);
    apiGetRequest("/roster-invoices/service-type")
      .then((res) => {
        if (res.data) {
          const modifiedValues = res?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setServiceCategoriesTypes(modifiedValues);
        }
        setIsServiceCategoriesFetchCall(false);
      })
      .catch((error) => console.log({ error }));
    setIsServiceCategoriesFetchCall(false);
  };

  return (
    <div className='h-screen bg-gray-50 ' style={{ position: "sticky" }}>
      <SideBar />
      <div
        className={`duration-300 flex flex-col ${
          sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
        }`}
      >
        <Header
          //title='Rostering Schedule'
          btnComponent={
            <>
              <CustomButton
                label='Redirect To Timesheet'
                Icon={<BiExit />}
                clickHandler={() => navigate("/rostering/timesheet")}
              />
            </>
          }
          breadcrumb={
            <Breadcrumb
              pages={ROSTERING_BREADCRUMB(ROSTERING_BREADCRUMB_PAGES.SCHEDULE)}
            />
          }
        />
        {/* <div className='bg-gray-50 sm:px-4 lg:px-6 py-4'>
          <Breadcrumb
            pages={ROSTERING_BREADCRUMB(ROSTERING_BREADCRUMB_PAGES.SCHEDULE)}
          />
        </div> */}
        {spRosteringSettingLoader ? (
          <div
            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
          >
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : (
          <main
            className={`flex-1 relative ${
              expandedFooter ? "expandedFooter" : "notExpandedFooter"
            }`}
          >
            <section className='h-full h-screen bg-gray-50 px-5 py-2 pb-5'>
              {/* <div>
              <FrequentlyInteractedParticipants />
            </div> */}
              <div className='flex items-center mt-8'>
                <div className='flex items-center gap-4 w-full'>
                  <div>
                    <label className='text-sm text-gray-700 font-medium leading-5'>
                      View
                    </label>
                    <SelectOption
                      cssClass=''
                      isSearch={false}
                      selectedOption={calendarView}
                      handleChange={setCalendarView}
                      data={calendarViewOptions}
                    />
                  </div>
                  {/* <div className="w-1/3">
                  <label className="text-sm text-gray-700 font-medium leading-5">
                    Select Participant
                  </label>
                  <SelectOption
                    isSearch
                    // selectedOption={fieldType}
                    // handleChange={setFieldType}
                    // data={SelectTypeOptions}
                  />
                </div> */}
                  <div className='max-w-max'>
                    <DateRangeSelect
                      defaultType={spRosteringSetting?.calendar_type}
                      defaultFortnightView={
                        spRosteringSetting?.default_fortnight
                      }
                      dateHandler={handleDateChange}
                    />
                  </div>
                  {/* <div className='mt-5'>
                  <CustomButton
                    isDisabled={isUserGracePeriodOver}
                    clickHandler={() => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      setShowCSVModal(true);
                    }}
                    variant='tertiary'
                    label={"Export Roster"}
                    cssClass={classNames(
                      " flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                      !isUserGracePeriodOver && "cursor-pointer"
                    )}
                    loaderCssClass={"text-black"}
                    Icon={<TiExportOutline className='h-5 w-5' />}
                  />
                </div> */}
                </div>
              </div>
              <div className='flex justify-end items-center'>
                <div>
                  <label>&nbsp;</label>
                  <div className='flex items-center gap-x-2'>
                    <div>
                      <CustomButton
                        isDisabled={isUserGracePeriodOver}
                        clickHandler={() => {
                          if (isUserGracePeriodOver) {
                            return;
                          }
                          setIsOpenShiftModal(true);
                        }}
                        variant='tertiary'
                        label={"Add Shift"}
                        cssClass={classNames(
                          " flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                          !isUserGracePeriodOver && "cursor-pointer"
                        )}
                        loaderCssClass={"text-black"}
                        Icon={<BiPlus className='h-5 w-5' />}
                      />
                    </div>
                    <div>
                      <CustomButton
                        isDisabled={isUserGracePeriodOver}
                        clickHandler={() => {
                          if (isUserGracePeriodOver) {
                            return;
                          }
                          setShowCSVModal(true);
                        }}
                        variant='tertiary'
                        label={"Export Roster"}
                        cssClass={classNames(
                          " flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                          !isUserGracePeriodOver && "cursor-pointer"
                        )}
                        loaderCssClass={"text-black"}
                        Icon={<TiExportOutline className='h-5 w-5' />}
                      />
                    </div>
                    <div>
                      <CustomButton
                        // variant={null}
                        variant='tertiary'
                        //cssClass='cursor-pointer border whitespace-nowrap border-[#59A96D] bg-[#F2FEF5] gap-3  flex items-center justify-center p-2 text-base text-[#59A96D] rounded-lg '
                        cssClass=' flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                        clickHandler={() => setCopyShifts(true)}
                        Icon={<MdContentCopy />}
                        label='Copy Shifts'
                      />
                    </div>
                    <div>
                      <CustomButton
                        // variant={null}
                        variant='tertiary'
                        //cssClass='cursor-pointer whitespace-nowrap border border-[#59A96D] bg-[#F2FEF5] gap-3  flex items-center justify-center p-2 text-base text-[#59A96D] rounded-lg'
                        cssClass=' flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                        clickHandler={() => setIsShowPublish(true)}
                        Icon={<MdPublish />}
                        label='All Shifts Publish'
                      />
                    </div>
                    <div className='flex items-center gap-x-2'>
                      <div className='flex items-start gap-4 ml-2'>
                        <div
                          role='button'
                          tabIndex={0}
                          onClick={() =>
                            workerAndPartsListLoader
                              ? {}
                              : refreshCalendar({ isRefreshCall: true })
                          }
                          className='border flex items-center justify-center  min-w-[40px] border-gray-200 bg-gray-50 rounded-lg cursor-pointer p-2 py-3'
                        >
                          {workerAndPartsListLoader ? (
                            <Loader
                              isSmallView={true}
                              cssClass={"text-black mr-0 p-0 flex"}
                            />
                          ) : (
                            <img
                              className='w-4 h-4'
                              src={refreshIcon}
                              alt='refresh icon'
                            />
                          )}
                        </div>
                        {/* <div className="border border-gray-200 bg-gray-50 rounded-lg cursor-pointer p-2 py-3">
                        <img
                          className="w-4 h-4"
                          src={downloadIcon}
                          alt="download icon"
                        />
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='pb-4 mt-6 overflow-x-auto overflow-y-auto'
                style={{ maxHeight: getMaxHeight() + "px" }}
              >
                {(workerAndPartsListLoader &&
                  isPaginationLoading === LOADER_STATUS.NA) ||
                isServiceCategoriesFetchCall ? (
                  <div
                    className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : (
                  calendarView?.value && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Calendar
                        reachedLast={reachedLast}
                        isPaginationLoading={isPaginationLoading}
                        paginationObj={paginationObj}
                        isUserGracePeriodOver={isUserGracePeriodOver}
                        workerAndPartsListLoader={workerAndPartsListLoader}
                        isSort={isSortCalendarShiftsItem}
                        calendarView={calendarView.value}
                        workers={workerOptions}
                        participants={participantOptions}
                        sortCalendarShiftsItem={(e) => {
                          setIsSortCalendarShiftsItem(e);
                          refreshCalendar({ sort: e, isRefreshCall: true });
                        }}
                        clearClipboard={clearClipboard}
                        setCopiedText={setCopiedText}
                        copiedText={copiedText}
                        handlePasteShift={handlePasteShift}
                        copyWorkerShiftLoader={copyWorkerShiftLoader}
                        refreshCalendar={refreshCalendar}
                        setReachedLast={setReachedLast}
                        scrollablePagination={scrollablePagination}
                        serviceCategoriesTypes={serviceCategoriesTypes}
                        selectedOps={selectedOps}
                        setSelectedops={setSelectedops}
                      />
                    </Suspense>
                  )
                )}
              </div>
            </section>
            {/*Schedule footer section*/}
            {/* <section
            className={`border-t bg-white py-5 fixed bottom-0 max-h-[217px] schedule-custom-width  ${
              expandedFooter ? "" : "px-5"
            }`}
          >
            {expandedFooter && (
              <h3 className='text-gray-900 text-xl font-medium mb-0 pb-5 px-5'>
                NDIS External Courses
              </h3>
            )}
            <div
              className={`flex justify-between items-center gap-3 ${
                expandedFooter ? "border-t border-gray-400 p-5" : ""
              }`}
            >
              {expandedFooter ? (
                <div className='flex items-center justify-start gap-x-5'>
                  {expandedFooterContent.map((item, index) => (
                    <div key={index}>
                      <img
                        src={item.img}
                        alt=''
                        className='w-[88px] h-[88px] rounded-xl'
                      />
                      <div className=''>
                        <h5 className='text-gray-900 text-xl font-medium mb-0'>
                          {item.title}
                        </h5>
                        <span className='text-[#667085] text-sm font-normal'>
                          {item.price}
                        </span>
                        <span className='text-[#4B6CB7] text-sm font-medium pl-3 cursor-pointer'>
                          View Course
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='flex items-center gap-x-7'>
                  <img src={bookIcon} alt='book icon' className='max-w-40' />
                  <h3 className='text-gray-900 text-xl font-medium mb-0'>
                    View Courses
                  </h3>
                  <p className='mb-0 text-gray-500 text-sm font-normal'>
                    Become a Master in Your Field with Our Comprehensive Course.
                  </p>
                </div>
              )}

              <div
                className='bg-gray-50 rounded-full w-12 h-12 inline-flex items-center justify-center cursor-pointer'
                onClick={() => setExpandedFooter(!expandedFooter)}
              >
                <img
                  src={expandedFooter ? arrowDownSvg : arrowUpSvg}
                  alt={expandedFooter ? "arrow down" : "arrow up"}
                />
              </div>
            </div>
          </section> */}
          </main>
        )}
      </div>
      {isShowCsvModal && (
        <ExportRosterModal
          calendarViewValue={calendarView.value}
          handleClose={() => setShowCSVModal(false)}
          isShow={isShowCsvModal}
          workerTimeSheetExportListRequest={workerTimeSheetExportListRequest}
          workerTimeSheetExportList={workerTimeSheetExportList}
        />
      )}
      {isOpenCopyShift && (
        <CopyShift
          isUserGracePeriodOver={isUserGracePeriodOver}
          title={"Copy Shifts"}
          setCopyShifts={setCopyShifts}
          isOpen={isOpenCopyShift}
          startDate={startDate}
          endDate={endDate}
          setForthnightlyCopyShiftsMessage={setForthnightlyCopyShiftsMessage}
          recurringMessageModal={recurringMessageModal}
          setIsMessage={setIsMessage}
          selectedDateTypes={selectedDateTypes}
        />
      )}
      {isShowPublish && (
        <PubishShiftModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          calendarView={calendarView.value}
          handleClose={() => setIsShowPublish(false)}
          isShow={isShowPublish}
        />
      )}
      {isRecurringModalShow && (
        <MessageModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          setCopyShifts={recurringMessageModal}
          isOpen={isRecurringModalShow}
          reccuringResponseData={
            isMessageForCopyShift
              ? fortnightlyCopyShiftsMessage
              : recurringMessages
          }
          // handleClose={handleClose}
          refreshCalendar={() => {
            const payload = { isRefreshCall: true };
            if (!isEmpty(searchText)) {
              Object.assign(payload, {
                search: searchText,
              });
            }
            refreshCalendar(payload);
          }}
          setIsMessage={setIsMessage}
        />
      )}

      {isOpenShiftModal && (
        <WorkerShiftModal
          isCallFromAddShift={true}
          selectedShift={null}
          isShow={isOpenShiftModal}
          handleClose={() => {
            setIsOpenShiftModal(false);
          }}
          selectedDate={null}
          userItem={null}
          calendarView={calendarView?.value}
          refreshCalendar={refreshCalendar}
          // calendarDates={calendarDates}
          // setRecurringErrorModal={setRecurringErrorModal}
          // isRecurringErrorModalOpen={isRecurringErrorModalOpen}
          // setRecurringResponse={setRecurringResponse}
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
    </div>
  );
};

export default Schedule;
