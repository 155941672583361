import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Table from "../Table";

const InvoicingReports = () => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const COLUMNS = [
    {
      header: "Participant Name",
    },
    { header: "Worker Name" },
    {
      header: "Date",
    },
    {
      header: "Sign In Time",
    },
    {
      header: "Sign Out Time",
    },
    {
      header: "KMs",
    },
    {
      header: "Support Item Name",
    },
    {
      header: "Support Item Code",
    },
    {
      header: "Hours",
    },
  ];
  return (
    <div className='min-h-full bg-red-500'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header
            title='Invoicing Reports'
            breadcrumb={
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.INVOICING_REPORTS
                )}
              />
            }
          />
          <div className='max-w-7xl mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  // isLoading={request.isRequested}
                  // setReportType={(e) => {
                  //   pif (isUserGracePeriodOver) {
                  //     return;
                  //   }
                  // handleFileDownLoad(e);
                  // }}
                />
              </div>
            </div>
            <div className='p-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex py-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          // data={clientsList ? clientsList : []}
                          // selectedOption={selectedClient}
                          // handleChange={(e) => {
                          //   setSelectedClient(e);
                          // }}
                          // placeHolder="Participant Name"
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Worker
                        </label>
                        <SelectOption
                          name='contact_type_id'
                          isSearch={true}
                          // data={ContactList ? ContactList : []}
                          // handleChange={(e) => {
                          //   setserviceContract(e);
                          // }}
                          // selectedOption={serviceContract}
                          // placeHolder="Services"
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <label
                        htmlFor='from-date-to-to-date'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Select Created Date Range
                      </label>
                      <DateRangePickerNew
                        isClearable={false}
                        // handleCancel={handleCancelButton}
                        // handleChange={(obj) => {
                        //   if (obj && obj.fromDate && obj.toDate) {
                        //     setFromDate(obj.fromDate);
                        //     setToDate(obj.toDate);
                        //   }
                        // }}
                        // fromDate={fromDate}
                        // toDate={toDate}
                      />
                    </div>

                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          // clickHandler={() => searchFilterDataRequest(false)}
                          label='Search'
                          // showLoading={caseNotesHistoryLoading || false}
                          // isDisabled={caseNotesHistoryLoading || false}
                        />
                        <CustomButton
                          // isDisabled={caseNotesHistoryLoading || false}
                          // Icon={<FiDelete />}
                          // clickHandler={() => {
                          //   setSelectedClient(null);
                          //   setserviceContract(null);
                          //   setFromDate(null);
                          //   setToDate(null);
                          //   searchFilterDataRequest(true);
                          // }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Table COLUMNS={COLUMNS} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default InvoicingReports;
