import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import Header from "components/common/Header/Header";
import SideBar from "components/common/SideBar/SideBarNew";
import {
  ROSTERING_BREADCRUMB,
  ROSTERING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Breadcrumb from "components/common/Breadcrumb";
import DateRangeSelect from "components/common/Select/DateRangeSelect";
import CheckBox from "components/common/checkBox/checkbox";
import SelectionSummary from "./SelectionSummary";
import ExportedSelectedTimesheet from "./ExportSelectedTimesheet/ExportSelectedTimesheet";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  PAYROLL_EXPORT_STATUS,
  TIMESHEET_PROGRESS_STATUS,
  TIMESHEET_STATUS,
  TOAST_TYPE,
} from "Constants/constant";
import RosteringAPI from "store/services/rostering.services";
import SweetAlertConfirmationModal from "components/common/SweetAlertConfirmation/SweetAlertConfirmation";
import _ from "lodash";
import { showToast } from "store/actions/general.actions";
import { TOAST_IDS } from "store/constants";
import { timesheetStatusChangedLocally } from "store/actions/rostering.actions";
import PayrollTimesheetStatusPopover from "./PayrollTimesheetStatusPopover";
import {
  filterNonPayrollRecords,
  isExistPayrollPlatform,
  parseBooleanVal,
  hasCheckTimesheetStatus,
  checkWorkerAllTimesheetsSuccess,
  checkWorkerAnyTimesheetsFailedOrError,
  classNames,
  formatDateIntoDMY,
} from "Utils/utils";
import usePayrollAuthentication from "hooks/usePayrollAuthentication";
import CustomButton from "components/common/Button/CustomButton";
import { TiExportOutline } from "react-icons/ti";
import ExportCSVModal from "./ExportSelectedTimesheet/ExportCSVModal";

const ExportTimeSheet = (props) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const {
    workerTimeSheetExportListRequest,
    workerTimeSheetExportList,
    workerTimeSheetExportListLoader,
    singleTimesheetDetailsRequest,
    singleTimesheetDetailsLoader,
    singleTimesheetDetails,
    exportTimesheetToPayrollRequest,
    exportTimesheetToPayrollLoader,
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const { isAuthenticateForPayrolls } = usePayrollAuthentication();

  const dispatch = useDispatch();

  const handleDateChange = (dates) => {
    if (dates && dates?.startDate) {
      setStartDate(dates.startDate);
    }
    if (dates && dates?.endDate) {
      setEndDate(dates.endDate);
    }
  };

  useEffect(() => {
    if (startDate && endDate && !workerTimeSheetExportListLoader) {
      workerTimeSheetExportListRequest({
        start_date: formatDateIntoDMY(startDate),
        end_date: formatDateIntoDMY(endDate),
      });
      setCheckboxes([]);
    }
  }, [startDate, endDate]);

  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isShowCsvModal, setShowCSVModal] = useState(false);

  const handleSelectAll = (val) => {
    if (val) {
      const filter = workerTimeSheetExportList
        .filter(filterNonPayrollRecords)
        .map((s) =>
          checkboxes.find((x) => x.uuid === s.uuid)
            ? checkboxes.find((x) => x.uuid === s.uuid)
            : s
        );
      setCheckboxes([...filter]);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (e, uuid, item) => {
    const prevData = _.cloneDeep(checkboxes);
    if (e) {
      prevData.push({ ...item });
      setCheckboxes([...prevData]);
    } else {
      const filteredList = prevData.filter((c) => c.uuid !== uuid);
      setCheckboxes([...filteredList]);
    }
  };

  useEffect(() => {
    if (
      workerTimeSheetExportList &&
      checkboxes &&
      checkboxes.length ===
        workerTimeSheetExportList.filter(filterNonPayrollRecords).length &&
      workerTimeSheetExportList.length !== 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxes]);

  const handleChildItemTimeSheet = (
    parentUUID,
    e,
    uuid,
    item,
    isAllSelect = null
  ) => {
    const index = checkboxes.findIndex((x) => x.uuid === parentUUID);

    if (isAllSelect !== null && index !== -1) {
      const prevSelectedWorkerCheckbox = _.cloneDeep(checkboxes);
      if (isAllSelect) {
        const parentWorker = workerTimeSheetExportList.find(
          (x) => x.uuid === parentUUID
        );
        if (parentWorker) {
          Object.assign(prevSelectedWorkerCheckbox[index], {
            children: [
              ...(parentWorker.timeSheet?.filter(
                (x) =>
                  !hasCheckTimesheetStatus(x, PAYROLL_EXPORT_STATUS.SUCCESS)
              ) || []),
            ],
          });
        }
      } else {
        prevSelectedWorkerCheckbox.splice(index, 1, {
          ...prevSelectedWorkerCheckbox[index],
          children: [],
        });
      }
      setCheckboxes([...prevSelectedWorkerCheckbox]);
      return;
    }
    if (index !== -1) {
      const data = _.cloneDeep(checkboxes);
      if (e === true) {
        data[index].children.push({ ...item });
      } else {
        const childIndex = data[index]?.children?.findIndex(
          (i) => i.uuid === uuid
        );
        if (childIndex !== -1) {
          data[index].children.splice(childIndex, 1);
        }
      }
      setCheckboxes([...data]);
    }
  };

  const getAllChilds = () => {
    return checkboxes
      .filter((y) => y.children.length > 0)
      .filter((x) => !hasCheckTimesheetStatus(x, PAYROLL_EXPORT_STATUS.SUCCESS))
      .map((x) => {
        return {
          p_id: x.id,
          p_uuid: x.uuid,
          children: [
            ...x.children.map((m) => m.uuid),
            // ...getWorkerSuccesstimesheet(x.uuid),
          ],
        };
      });
  };

  const handleChangeStatusPaid = async () => {
    let uuids = [];
    getAllChilds().forEach((item) => uuids.push(...item.children));
    const data = {
      uuid: uuids,
      status: TIMESHEET_STATUS.PAID,
    };

    try {
      setIsLoading(true);
      const response = await RosteringAPI.changeTimesheetStatus(data);
      if (response) {
        dispatch(
          timesheetStatusChangedLocally({
            employees: getAllChilds(),
            status: TIMESHEET_PROGRESS_STATUS.PAID.value,
          })
        );
        toast.success(
          `Timesheet${uuids.length === 1 ? "" : "s"} Paid Successfully!`
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      toast.error(err?.message ?? "Something went wrong!");
    }
  };

  const handlePayrollExportTimeSheet = async (payrollType) => {
    try {
      if (
        isAuthenticateForPayrolls !== null &&
        payrollType &&
        isExistPayrollPlatform(payrollType) &&
        parseBooleanVal(isAuthenticateForPayrolls[payrollType])
      ) {
        if (getAllChilds().length === 0) {
          dispatch(
            showToast({
              id: TOAST_IDS.EXPORT_SELECTED_TIMESHEET_ID,
              type: TOAST_TYPE.DEFAULT,
              title: "No any timesheet selected!",
            })
          );
          return;
        }
        const payload = getAllChilds().map((x) => ({
          uuid: x.p_uuid,
          // id: x.id,
          timesheets: [...x.children],
        }));
        exportTimesheetToPayrollRequest({
          type: payrollType,
          employees: payload,
          start_date: formatDateIntoDMY(startDate),
          end_date: formatDateIntoDMY(endDate),
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (workerTimeSheetExportList && workerTimeSheetExportList.length > 0) {
      const filter = checkboxes.map((s) => {
        const foundWorker = workerTimeSheetExportList.find(
          (x) => x.uuid === s.uuid
        );
        return foundWorker ? { ...foundWorker, children: s.children || [] } : s;
      });
      setCheckboxes([...filter]);
    }
  }, [workerTimeSheetExportList]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`duration-300 flex flex-col ${
          sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
        }`}
      >
        <Header
          //title='Export Timesheet'
          cssClass="z-[10]"
          breadcrumb={
            <Breadcrumb
              pages={ROSTERING_BREADCRUMB(
                ROSTERING_BREADCRUMB_PAGES.EXPORTTIMESHEET
              )}
            />
          }
        />

        <main className='flex-1 relative sm:px-6 lg:px-8'>
          <div className='py-4'>
            <div className='flex items-end justify-between w-full'>
              <div className='flex items-end space-x-2 w-full'>
                <div className='max-w-max'>
                  <DateRangeSelect
                    isDisabled={workerTimeSheetExportListLoader}
                    dateHandler={handleDateChange}
                  />
                </div>
                <CustomButton
                  isDisabled={isUserGracePeriodOver}
                  clickHandler={() => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    setShowCSVModal(true);
                  }}
                  variant='tertiary'
                  label={"Export To CSV"}
                  cssClass={classNames(
                    " flex items-center  rounded-lg background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                    !isUserGracePeriodOver && "cursor-pointer"
                  )}
                  loaderCssClass={"text-black"}
                  Icon={<TiExportOutline className='h-5 w-5' />}
                />
              </div>
            </div>
            {workerTimeSheetExportListLoader ||
            isAuthenticateForPayrolls === null ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='flex w-full mt-8 gap-x-8'>
                <div className='border border-gray-300 pt-2 pb-4 px-4 w-[30%] rounded-md'>
                  {workerTimeSheetExportList.length > 0 && (
                    <div
                      onClick={() => handleSelectAll(!selectAll)}
                      className='text-sm text-end w-full underline font-medium text-blue-500 cursor-pointer'
                    >
                      {checkboxes.length > 0 &&
                      workerTimeSheetExportList.filter(filterNonPayrollRecords)
                        .length === checkboxes.length
                        ? "Deselect "
                        : "Select "}
                      All Workers
                    </div>
                  )}
                  <div className='mt-4'>
                    {workerTimeSheetExportList
                      .filter((filter) => filter.timeSheet.length > 0)
                      .map((item, index) => (
                        <div
                          className={`flex items-center hover:bg-blue-100 hover:rounded-md ${
                            index === workerTimeSheetExportList.length - 1
                              ? "border-b-0"
                              : "border-b"
                          } border-gray-200 py-4 px-2`}
                          key={index}
                        >
                          <div className='text-gray-200'>
                            <CheckBox
                              handleChange={(e) =>
                                handleCheckboxChange(e, item.uuid, item)
                              }
                              checked={
                                checkboxes.find((x) => x.uuid === item.uuid)
                                  ? true
                                  : false
                              }
                              cssClass='border-gray-500 rounded-sm'
                              isDisabled={!filterNonPayrollRecords(item)}
                            />
                          </div>
                          <div className='pl-4'>
                            <h5 className='flex items-center text-gray-700 text-xl font-bold mb-1 capitalize gap-x-2'>
                              {`${item.first_name} ${item.last_name}`}
                              <PayrollTimesheetStatusPopover
                                isAllSuccess={
                                  checkWorkerAllTimesheetsSuccess(
                                    item?.timeSheet
                                  ).length === item?.timeSheet.length
                                }
                                isAnyFailed={
                                  checkWorkerAnyTimesheetsFailedOrError(
                                    item?.timeSheet
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                item={item}
                                isPayrollIdExists={filterNonPayrollRecords(
                                  item
                                )}
                                payrollType={item.payroll_platform}
                                isConsoleLog={
                                  `${item.first_name} ${item.last_name}` ===
                                  "Chastity Prince"
                                }
                              />
                            </h5>
                            <div>
                              <span className='text-gray-500 text-base font-normal border-r border-gray-500 pr-4'>
                                {item.timeSheet.length} Items
                              </span>
                              <span className='text-gray-500 text-base font-normal border-gray-500 px-4'>
                                {item.ttl_timesheet_length &&
                                  item.ttl_timesheet_length.includes(":") &&
                                  `${
                                    item.ttl_timesheet_length.split(":")[0]
                                  }hr ${
                                    item.ttl_timesheet_length.split(":")[1]
                                  }mins`}
                              </span>
                              {/* <span className='text-gray-500 text-base font-normal border-r pl-4'>
                              ${item.amount}
                            </span> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    {workerTimeSheetExportList.length === 0 && (
                      <div className='bg-white'>
                        <div className='text-center'>
                          <h2 className='block text-xl font-semibold text-gray-900'>
                            No Data Found!
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <section className='w-[70%]'>
                  <SelectionSummary
                    selectedWorkers={checkboxes}
                    selectedChilds={checkboxes.filter(
                      (x) => x?.children && x.children.length > 0
                    )}
                  />

                  <section className='mt-8'>
                    <ExportedSelectedTimesheet
                      isAuthenticateForPayrolls={isAuthenticateForPayrolls}
                      handlePayrollExportTimeSheet={
                        handlePayrollExportTimeSheet
                      }
                      paidStatusChangeStatusLoader={isLoading}
                      handleChangeStatusPaid={() => {
                        if (getAllChilds().length === 0) {
                          dispatch(
                            showToast({
                              id: TOAST_IDS.MARK_SELECTED_TIMESHEET_PAID_ID,
                              type: TOAST_TYPE.DEFAULT,
                              title: "No any timesheet selected!",
                            })
                          );
                          return;
                        }
                        setIsConfirm(true);
                      }}
                      singleTimesheetDetailsRequest={
                        singleTimesheetDetailsRequest
                      }
                      singleTimesheetDetailsLoader={
                        singleTimesheetDetailsLoader
                      }
                      singleTimesheetDetails={singleTimesheetDetails}
                      handleChildItemTimeSheet={handleChildItemTimeSheet}
                      selectedWorkers={checkboxes}
                      exportTimesheetToPayrollLoader={
                        exportTimesheetToPayrollLoader
                      }
                    />
                  </section>
                </section>
              </div>
            )}
          </div>
        </main>
      </div>

      {isShowCsvModal && (
        <ExportCSVModal
          handleClose={() => setShowCSVModal(false)}
          isShow={isShowCsvModal}
          workerTimeSheetExportListRequest={workerTimeSheetExportListRequest}
          workerTimeSheetExportList={workerTimeSheetExportList}
        />
      )}
      {isConfirm && (
        <SweetAlertConfirmationModal
          isOpen={isConfirm}
          title='Timesheet paid'
          content={`Are you sure you want to paid timesheet${
            getAllChilds().length > 1 ? "s" : ""
          }?`}
          dangerMode={true}
          setIsConfirm={(value) => {
            if (value === true) {
              setIsConfirm(false);
              handleChangeStatusPaid();
            } else {
              setIsConfirm(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default ExportTimeSheet;
