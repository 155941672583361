import React, { Fragment, useEffect, useState, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import ApplicantProfileView from "../../Components/Profile/index";
import ApplicantResume from "../../Components/Resume/index";
import ApplicantNotes from "../../Components/Notes/index";
import Tabs from "../../Components/Tabs/Tabs";
import {
  APPLICANT_ASSESSMENT_STATUS,
  APPLICANT_PROFILE_TAB,
  APPLICANT_REQ_STATUS_OPTIONS,
  APPLICANT_STATUS_UUID,
} from "../../../../Constants/constant";
import { apiGetRequest, apiPostRequest } from "../../../../helpers/Requests";
import { baseURLImage, getNameProfile } from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import InterviewModal from "../../Components/Modal/InterviewModal";
import { toast } from "react-toastify";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  HRM_BREADCRUMB,
  HRM_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import { LoginContext } from "../../../../helpers/LoginContext";
import ApplicantQuestions from "../../Components/Questions/Questions";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Profile = () => {
  const tabs = [
    {
      name: "Profile",
      href: "#",
      current: true,
      value: APPLICANT_PROFILE_TAB.profile,
    },
    {
      name: "Resume",
      href: "#",
      current: false,
      value: APPLICANT_PROFILE_TAB.resume,
    },
    {
      name: "Notes",
      href: "#",
      current: false,
      value: APPLICANT_PROFILE_TAB.notes,
    },
    {
      name: "Interview Notes",
      href: "#",
      current: false,
      value: APPLICANT_PROFILE_TAB.questions,
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const [applicant, setApplicant] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedTab, setSelectedTab] = useState(APPLICANT_PROFILE_TAB.profile);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(0);
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    const { state } = location;
    if (!state || state === null) {
      navigate("/hrm/all-applicants");
    } else if (!state.hasOwnProperty("applicantId")) {
      navigate("/hrm/all-applicants");
    } else if (state.applicantId.length > 0) {
      getApplicantData(state.applicantId);
    }
  }, []);

  useEffect(() => getTab(), [selectedTab]);

  useEffect(() => {
    const res = APPLICANT_REQ_STATUS_OPTIONS.find(
      (x) => x.value === applicant.recruitment_stage_uuid
    );
    setSelected(res);
  }, [applicant]);

  const getApplicantData = async (id) => {
    setIsLoading(true);
    try {
      const res = await apiGetRequest(`/hrm/applicant/?applicant_uuid=${id}`);
      if (res.status === 200) {
        setApplicant(res.data.applicant);
      }
      if (res.status === 404) {
        navigate("/hrm/all-applicants");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleTab = (tab) => setSelectedTab(tab);

  useEffect(() => {
    if (selected && selected.value !== applicant.recruitment_stage_uuid) {
      handleChangeStatus({}, "");
    }
    setLoaderStatus(loaderStatus + 1);
  }, [selected]);

  const getTab = () => {
    switch (selectedTab) {
      case APPLICANT_PROFILE_TAB.profile:
        return <ApplicantProfileView profile={applicant || {}} />;
      case APPLICANT_PROFILE_TAB.resume:
        return <ApplicantResume file={applicant.resume} />;
      case APPLICANT_PROFILE_TAB.notes:
        return (
          <ApplicantNotes
            isUserGracePeriodOver={isUserGracePeriodOver}
            applicant={applicant}
          />
        );
      case APPLICANT_PROFILE_TAB.questions:
        return (
          <ApplicantQuestions
            isUserGracePeriodOver={isUserGracePeriodOver}
            applicant={applicant}
          />
        );
      default:
        return <ApplicantProfileView />;
    }
  };

  const handleChangeStatus = async (data, interViewData) => {
    if (interViewData) setSelected(interViewData);
    if (
      Object.keys(data).length === 0 &&
      selected.value === APPLICANT_STATUS_UUID.Interview
    ) {
      return;
    }
    Object.assign(data, {
      applicant_uuid: applicant.uuid,
      stage_uuid: interViewData
        ? APPLICANT_STATUS_UUID.Interview
        : selected.value,
    });
    setIsLoading(true);
    try {
      const res = await apiPostRequest("/hrm/recruitment-status", data);

      if (res.status === 200) {
        setIsLoading(false);
        Object.assign(applicant, {
          recruitment_stage_uuid: selected.value,
          recruitment_stage_name: selected.label,
        });
        toast.success(res.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <>
      <div className='min-full '>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          } flex flex-col fullScreenHeight duration-300`}
        >
          <main className='flex-1 bg-white'>
            <div className='items-center flex mb-5 border-b border-gray-200 px-4 py-4 h-[80px] sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 sticky top-0 bg-white z-[9]'>
              <div className='flex-1 min-w-0'>
                <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                  Applicant Profile
                </h1>

                <div className='mt-2'>
                  <Breadcrumb
                    pages={HRM_BREADCRUMB(
                      HRM_BREADCRUMB_PAGES.SINGLE_APPLICANT
                    )}
                  />
                </div>
              </div>

              <div className='mt-4 flex sm:mt-0 sm:ml-4'>
                {roleAccess(
                  [],
                  PERMISSION_KEYS.HRM,
                  PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.ASSESSMENT
                ) &&
                  !isLoading &&
                  applicant &&
                  (!applicant.status ||
                    applicant.status ===
                      APPLICANT_ASSESSMENT_STATUS.Pending) && (
                    <Link
                      to='/hrm/applicant-assessment'
                      state={{
                        applicantId: applicant.uuid,
                        name: applicant.name,
                      }}
                      className='cursor-pointer mr-2 flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
                    >
                      Assessment
                    </Link>
                  )}
                {!isLoading && selected && (
                  <Listbox
                    disabled={isUserGracePeriodOver}
                    value={selected}
                    onChange={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      if (e.value === APPLICANT_STATUS_UUID.Interview) {
                        setShowInterviewModal(true);
                      } else {
                        setSelected(e);
                      }
                    }}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className='sr-only'>
                          Change published status
                        </Listbox.Label>
                        <div>
                          <div className='inline-flex shadow-sm rounded-md divide-x divide-blue-600'>
                            <div className='relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-600'>
                              <div
                                style={{ width: "170px", maxWidth: "170px" }}
                                className='relative inline-flex items-center bg-gradient py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white'
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                                <p className='ml-2.5 text-sm font-medium'>
                                  {selected.label}
                                </p>
                              </div>
                              <Listbox.Button className='relative inline-flex items-center bg-gradient p-2 rounded-l-none rounded-r-md text-sm font-medium text-white focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500'>
                                <span className='sr-only'>
                                  Change published status
                                </span>
                                <ChevronDownIcon
                                  className='h-5 w-5 text-white'
                                  aria-hidden='true'
                                />
                              </Listbox.Button>
                            </div>
                          </div>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options
                              style={{ right: "32px" }}
                              className='origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none'
                            >
                              {APPLICANT_REQ_STATUS_OPTIONS.map(
                                (option, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-blue-500"
                                          : "text-gray-900",
                                        "cursor-default select-none relative p-4 text-sm"
                                      )
                                    }
                                    value={option}
                                  >
                                    <div className='flex flex-col'>
                                      <div className='flex justify-between'>
                                        <p
                                          className={`${
                                            selected.value === option.value
                                              ? "font-semibold"
                                              : "font-normal"
                                          } capitalize`}
                                        >
                                          {option.label}
                                        </p>
                                        {selected.value === option.value ? (
                                          <span
                                            className={`${
                                              selected.value === option.value
                                                ? "text-blue-500"
                                                : "text-white"
                                            } capitalize`}
                                          >
                                            <CheckIcon
                                              className='h-5 w-5'
                                              aria-hidden='true'
                                            />
                                          </span>
                                        ) : null}
                                      </div>
                                      {/* <p
                                      className={classNames(
                                        selected.value === option.value
                                          ? "text-blue-200"
                                          : "text-gray-500",
                                        "mt-2"
                                      )}
                                    >
                                      {option.description}
                                    </p> */}
                                    </div>
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                )}
              </div>
            </div>
            <div className='w-full'>
              {isLoading ? (
                <div className='flex justify-center items-center min-h-screen'>
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <div className='max-w-7xl mx-auto'>
                  {/* Page heading */}
                  <div className='ml-4 rounded-t-lg bg-white overflow-hidden shadow'>
                    <h2 className='sr-only' id='profile-overview-title'>
                      Profile Overview
                    </h2>
                    <div className='bg-white p-6'>
                      <div className='lg:truncate lg:w-1/2  sm:flex sm:items-center sm:justify-between'>
                        <div className='sm:flex sm:space-x-5'>
                          <div className='flex-shrink-0'>
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  applicant.name
                                    ? applicant.name.split(" ")[0]
                                    : "",
                                  applicant.name
                                    ? applicant.name.split(" ")[1]
                                    : ""
                                )
                              }
                              className='mx-auto h-16 w-16 rounded-full'
                              src={
                                applicant.photo
                                  ? baseURLImage(applicant.photo)
                                  : getNameProfile(
                                      applicant.name
                                        ? applicant.name.split(" ")[0]
                                        : "",
                                      applicant.name
                                        ? applicant.name.split(" ")[1]
                                        : ""
                                    )
                              }
                              alt=''
                            />
                          </div>
                          <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                            <p
                              title={applicant.name}
                              className='capitalize text-xl font-bold text-gray-900 sm:text-1xl'
                            >
                              {applicant.name}
                            </p>
                            <p
                              title={applicant.email}
                              className='text-xs font-sa text-gray-600'
                            >
                              {applicant.email}
                            </p>
                            <p
                              title={applicant.job_type}
                              className='text-sm font-medium text-gray-600'
                            >
                              Applied for the position of
                              <strong className='capitalize'>{` ${applicant.job_type}`}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">*/}
                    {/*    {stats.map((stat) => (*/}
                    {/*        <div key={stat.label} className="px-6 py-5 text-sm font-medium text-center">*/}
                    {/*            <span className="text-gray-900">{stat.value}</span> <span className="text-gray-600">{stat.label}</span>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                  </div>
                  <div className='mb-3 ml-4 rounded-b-lg bg-white overflow-hidden shadow'>
                    <div className='px-6'>
                      <Tabs
                        tabs={tabs}
                        selectedTab={selectedTab}
                        handleChange={handleTab}
                      />
                    </div>
                    <div>{getTab()}</div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      {showInterviewModal && (
        <InterviewModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          handleSubmitSchedule={handleChangeStatus}
          applicant={applicant}
          showModal={showInterviewModal}
          title='Schedule Interview'
          closeModalHandler={() => setShowInterviewModal(false)}
        />
      )}
    </>
  );
};

export default Profile;
